﻿import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .directive("clock", ['$interval', '$translate',
        function ($interval, $translate) {
            return {
                templateUrl: 'clock.html',
                restrict: 'E',
                scope: {
                    'mode': '@'
                },
                link: function (scope, element, attrs) {
                    scope.hours = "..";
                    scope.minutes = "..";
                    scope.seconds = "..";

                    scope.showHours = true;
                    scope.showMinutes = true;
                    scope.showSeconds = false;

                    scope.nearTheEnd = false;
                    scope.isCountdownFinished = false;
                    var stopTime,
                        countDownTime; // so that we can cancel the time updates

                    // Clock
                    scope.mode = "clock";
                    scope.changeClockMode = function (mode) {
                        scope.mode = mode;
                    }

                    scope.$watch("mode", function () {
                        scope.nearTheEnd = false;
                        scope.isCountdownFinished = false;
                        switch (scope.mode) {
                            case "countDown15":
                                startCountDown(15 * 60); // 15 min * 60 sec
                                break;
                            default:
                                startClock();
                                break;
                        }
                    });

                    var currentSeconds = 0;
                    function startCountDown(seconds) {
                        currentSeconds = seconds;
                        updateCountDown();
                        scope.showHours = false;
                        scope.showMinutes = true;
                        scope.showSeconds = true;
                        $interval.cancel(stopTime);
                        countDownTime = $interval(countDown, 1000);
                    }

                    function countDown() {
                        if (currentSeconds <= 30) {
                            scope.nearTheEnd = true;
                        }
                        if (currentSeconds < 0) {
                            scope.isCountdownFinished = true;
                            $interval.cancel(countDownTime);
                            
                            toaster.error(`<strong>15 ${$translate.instant("NAV_CLOCK_COUNTDOWN_FINISHED_TEXT")}</strong><br>
                                            ${$translate.instant("NAV_CLOCK_COUNTDOWN_FINISHED")}`, 0);
                            return;
                        }
                        updateCountDown();
                        currentSeconds -= 1;
                    }

                    function updateCountDown() {
                        var minutes = Math.floor(currentSeconds / 60);
                        var seconds = currentSeconds - minutes * 60;
                        scope.minutes = addLeadingZero(minutes);
                        scope.seconds = addLeadingZero(seconds);
                    }

                    function startClock() {
                        scope.showHours = true;
                        scope.showMinutes = true;
                        scope.showSeconds = false;
                        $interval.cancel(countDownTime);
                        stopTime = $interval(updateTime, 1000);
                    }


                    function addLeadingZero(val) {
                        if (val < 10) return "0" + val;
                        return val;
                    }


                    function updateTime() {
                        var now = moment();
                        scope.hours = addLeadingZero(now.hours());
                        scope.minutes = addLeadingZero(now.minutes());
                        scope.seconds = addLeadingZero(now.seconds());
                    }




                    // listen on DOM destroy (removal) event, and cancel the next UI update
                    // to prevent updating time after the DOM element was removed.
                    element.on('$destroy', function () {
                        $interval.cancel(countDownTime);
                        $interval.cancel(stopTime);
                    });

                }
            }
        }]);
