﻿import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import toaster from "@common/components/toast"

var DigiLean = angular.module('DigiLean');
DigiLean.directive('yearlyBoardDesigner', ['$filter', '$translate', '$uibModal', 'modalService',
    function ($filter, $translate, $uibModal, modalService) {
        return {
            templateUrl: 'yearlyboardDesigner.html',
            restrict: 'E',
            scope: {
                'model': '=',
            },
            link: function ($scope, elem, attrs) {
                $scope.board = null;
                $scope.isPublic = false;
                $scope.new = "New";
                $scope.months = timeService.getMonths();
                var settings = null;

                $scope.yearlyBoardOptions = {
                    showTeamMembers: true,
                    showHeader: true,
                    taskDetailLevel: "normal",
                    viewModeSelection: "year"
                };

                $scope.$watch("model", function (model) {
                    if ($scope.model == null) return;
                    var board = model.board;
                    board.rows = model.rows;
                    angular.forEach(board.rows, function (row) {
                        row.style = {
                            "background": row.categoryStyle.backgroundColor,
                            "color": row.categoryStyle.foregroundColor
                        }
                        if (row.weekdayName) {
                            row.isWeekday = true;
                        } else {
                            row.isWeekday = false;
                        }
                    });
                    
                    $scope.board = board;
                    $scope.boardId = board.id;
                    $scope.isPublic = board.isPublic;
                    if ($scope.board.settings) {
                        settings = JSON.parse($scope.board.settings);
                        if(settings.boardOptions){
                            $scope.yearlyBoardOptions = settings.boardOptions; 
                        }
                    }
                    loadUsers();
                });
                $translate('COMMON_NEW').then(function (translated) {
                    $scope.new = translated;
                });

                $scope.updateBoard = function () {
                    $scope.board.isPublic = $scope.isPublic;
                    boardService.update($scope.board.id, $scope.board).then(function () {
                        $translate('BOARD_UPDATED').then(function (msg) {
                            toaster.success(msg);
                        });
                    });
                }

                $scope.rowSortableOptions = {
                    clone: false,
                    accept: function (sourceItemHandleScope, destSortableScope) {
                        return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
                    },
                    orderChanged: function (event) {
                        var sortOrders = [];
                        angular.forEach($scope.board.rows, function (row, key) {
                            console.log("row sortOrder: " + key + ", name: " + row.name);
                            row.sortOrder = key;
                            var sortOrder = { id: row.id, sortOrder: key }
                            sortOrders.push(sortOrder);
                        });
                        boardService.changeSortOrder($scope.boardId, sortOrders).then(function () {
                            console.log("Saved");
                        });
                    }
                };

                function loadUsers() {
                    boardService.getUserListForBoard($scope.board.id).then(function (data) {
                        $scope.users = data;
                    });
                }
                var categoryEditOptions = {
                    hideWeekDayOption : true
                };

                function updateSettings(){
                    $scope.board.settings = settings;
                    $scope.board.settings.boardOptions = $scope.yearlyBoardOptions;
                    boardService.updateSettings($scope.board.id, $scope.board.settings).then(function () {

                    });
                }
                $scope.updateBoardOptionsDetailLevel = function (detaillevel) {
                    $scope.yearlyBoardOptions = {
                        showTeamMembers: $scope.yearlyBoardOptions.showTeamMembers,
                        showHeader: $scope.yearlyBoardOptions.showHeader,
                        taskDetailLevel: detaillevel,
                        viewModeSelection: $scope.yearlyBoardOptions.viewModeSelection
                    };
                    updateSettings();
                    //settings: JSON.stringify({
                    //    IsWeeklyBoard: template.isWeeklyBoard
                   // })
                    //$scope.updateBoard();
                    
                }

                $scope.updateBoardOptionsView = function (view) {
                    $scope.yearlyBoardOptions = {
                        showTeamMembers: $scope.yearlyBoardOptions.showTeamMembers,
                        showHeader: $scope.yearlyBoardOptions.showHeader,
                        taskDetailLevel: $scope.yearlyBoardOptions.taskDetailLevel,
                        viewModeSelection: view
                    };
                    updateSettings();
                }

                $scope.editCategory = function (category) {                 
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: $scope.animationsEnabled,
                        templateUrl: 'boardCategoryDetails.html',
                        controller: 'boardCategoryDetailsController',
                        resolve: {
                            boardCategory: function () {
                                return category;
                            },
                            options: function() {
                                return categoryEditOptions
                            }
                        }
                    });

                    modalInstance.result.then(function (result) {
                        if (result === "deleted") {
                            removeCategory(category);
                        }
                    }, function () {

                    });
                }

                $scope.editMembers = function () {
                    var assetId = $scope.board.assetId;
                    var boardId = $scope.board.id;
                    var modalInstance = $uibModal.open({ backdrop: 'static',
                        animation: $scope.animationsEnabled,
                        templateUrl: 'assetparticipantsform.html',
                        controller: 'assetParticipantsController',
                        windowClass: 'assetparticipants-modal-window',
                        resolve: {
                            assetId: function () {
                                return assetId;
                            },
                            boardId: function () {
                                return boardId;
                            }
                        }
                    });

                    modalInstance.result.then(function (asset) {
                        loadUsers();
                    }, function () {

                    });
                }



                $scope.addRow = function () {
                    var style = "";
                    if ($scope.board.rows.length > 0) {
                        // Copy style from last row
                        var lastRow = $scope.board.rows[$scope.board.rows.length - 1];
                        style = lastRow.style;
                    }
                    var row = {
                        boardId: $scope.boardId,
                        name: $scope.new,
                        isRowCategory: true,
                        sortOrder: $scope.board.rows.length,
                        style: style
                    }
                    row.categoryStyle = JSON.stringify({
                        BackgroundColor: style.background,
                        ForegroundColor: style.color
                    });
                    boardService.createCategory($scope.board.id, row).then(function (category) {
                        category.style = style;
                        $scope.board.rows.push(category);
                        $scope.editCategory(category);
                    });

                }


                $scope.hasValue = function (value) {
                    if (value) return true;
                    return false;
                }

                $scope.deleteCategory = function (category) {
                    $translate(['COMMON_CANCEL', 'BOARD_DELETE_CATEGORY', 'COMMON_DELETE', 'BOARD_DELETE_CATEGORY_DESCRIPTION']).then(function (translations) {
                        var modalOptions = {
                            closeButtonText: translations.COMMON_CANCEL,
                            actionButtonText: translations.BOARD_DELETE_CATEGORY,
                            headerText: translations.COMMON_DELETE + ' ' + category.name + '?',
                            bodyText: translations.BOARD_DELETE_CATEGORY_DESCRIPTION
                        };

                        modalService.showModal({}, modalOptions).then(function (result) {
                            boardService.deleteCategory(category.id).then(function (data) {
                                removeCategory(category);
                            });
                        });
                    });
                }

                var removeCategory = function (category) {
                    var row = $filter('filter')($scope.board.rows, { id: category.id }, true);
                    if (row.length > 0) {
                        var rowindex = $scope.board.rows.indexOf(category);
                        $scope.board.rows.splice(rowindex, 1);
                    }
                }
            }
        }
    }]);