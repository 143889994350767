import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .controller("a3TaskListModalController", ['$scope', '$uibModalInstance', '$uibModal', '$filter', '$translate', 'boardTaskService', 'a3Service', 'taskDrawingService', 'authService', 'a3Id',
        function ($scope, $uibModalInstance, $uibModal, $filter, $translate, boardTaskService, a3Service, taskDrawingService, authService, a3Id) {

            $scope.tasks = [];
            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("A3Admin");
            // if page is refreshed we need to refresh
            $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("A3Admin");
            });
            $scope.a3Id = a3Id;

            boardTaskService().getTasksByA3(a3Id).then(function (tasks) {
                $scope.tasks = tasks; 
                if($scope.tasks && $scope.tasks.length > 0) {
                    setDateStyle($scope.tasks);
                }
            });

            a3Service().getUserlistForA3(a3Id).then(function (users) {
                $scope.users = users;
            });

            $scope.subscribe('TaskDeleted', function (id) {
                var task = $filter('filter')($scope.tasks, { id: id });
                if (task.length > 0) {
                    $scope.$apply(function () {
                        var index = $scope.tasks.indexOf(task[0]);
                        if (index > -1) {
                            $scope.tasks.splice(index, 1);
                        }
                    });
                }
            });
            $scope.subscribe('TaskStatusUpdated', function (status) {
                var task = $filter('filter')($scope.tasks, { id: status.taskId });
                if (task.length > 0) {
                    $scope.$apply(function () {
                        task[0].status = status.status;
                    });
                }
            });
            $scope.subscribe('TaskAssigned', function (taskAssigned) {
                var task = $filter('filter')($scope.tasks, { id: taskAssigned.taskId });
                if (task.length > 0) {
                    $scope.$apply(function () {
                        task[0].responsibleUserId = taskAssigned.userId;
                        task[0].responsibleUser = taskAssigned.userName;
                        task[0].responsibleDisplayName = taskAssigned.userDisplayName;
                    });
                }
            });

            $scope.updateResponsible = function (user, item) {
                item.responsibleDisplayName = user.displayName;
                var taskAssigned =
                    {
                        taskId: item.id,
                        a3Id: $scope.a3id,
                        userId: user.id,
                        userName: user.fullName,
                        userDisplayName: user.displayName,
                        previousUserId: item.responsibleUserId,
                        previousUserName: item.responsibleUser
                    };
                var options = {
                    taskId: item.id,
                    user: taskAssigned
                };
                boardTaskService().assignUser(options).then(function () {
                
                });
            };

            $scope.changeActionDate = function (task, $event) {
                const date = $event.originalEvent.detail
                task.boardDate = date

                if (task.boardDate) { // Valid date format
                    task.error = false
                    const actionDate = {
                        taskId: task.id,
                        actionDate: task.boardDate
                    }
                    a3Service().updateItemActionDate(actionDate).then(function () {
                        $translate('A3_ACTION_ITEM_SAVED').then(function (msg) {
                            toaster.success(msg)
                        })
                        
                        $scope.publish("SendTaskDateUpdated", actionDate)
                    })
                } else {
                    $translate('COMMON_NOT_VALID_DATE').then(function (trans) {
                        item.error = trans
                    })
                }
            }

            $scope.changeStatus = function (item) {
                item.status = taskDrawingService().getNextStatus(item.status).status;
                var status = {
                    taskId: item.id,
                    status: item.status
                };
                boardTaskService().updateStatus(item.id, status).then(function () {
                    
                });
            };

            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            }

            function addTask(task, board){
                if(task.boardDate){
                    task.boardDate = moment(task.boardDate).toDate();
                }
                task.board = board; 
                if(task.status != 'OK' ){
                    task.dateStyleClass = getDateStyle(task.boardDate);
                } 
                $scope.tasks.unshift(task);
            }

            $scope.add = function () {

                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newtasktoprojecttasklist.html', 
                    controller: 'newtasktoprojecttasklistController',
                    resolve: {
                        projectId: function () {
                            return $scope.projectId;
                        }
                    }         
                });
                modalInstance.result.then(function (task) {
                    var board = $filter('filter')(projectBoards, { id: task.boardid  });
                    if (board.length > 0) {
                        addTask(task, board[0]);
                    } else {
                        if(task.status != 'OK' ){
                            task.dateStyleClass = getDateStyle(task.boardDate);
                        } 
                        $scope.tasks.unshift(task);
                    }
                }, function () {
        
                });
    
            }


            function setDateStyle(items){
                items.forEach(function (item) {
                    if(item.status != 'OK' ){
                        item.dateStyleClass = getDateStyle(item.boardDate);
                    }
                });
            }

            function getDateStyle(date) {
                if (!date) return "";
                // Overdue check

                var overdue = "";
                var duration = moment.duration(moment().diff(moment(date)));
                var days = duration.asDays();
                if (days > 1) {
                    overdue = "overdue";
                }
                return overdue;
            }
        }]);