﻿import * as profileImageService from "@common/services/user/profileImageService"
import toaster from "@common/components/toast"

var DigiLean = angular.module('DigiLean')
// TODO: used for old A3s
DigiLean
    .directive("a3ActionList", ['$filter', '$translate', 'a3Service', 'taskDrawingService', 'boardTaskService',
        function ($filter, $translate, a3Service, taskDrawingService, boardTaskService) {
            return {
                templateUrl: 'a3ActionList.html',
                restrict: 'E',
                scope: {
                    'a3id': '=',
                    'stepid': '=',
                    'readonly': '<'
                },
                link: function (scope, element, attrs) {
                    scope.users = [];
                    scope.actionList = [];
                    scope.items = [];
                    scope.readonly = scope.readonly;

                    scope.add = function () {
                        a3Service().createActionItem(scope.a3id, scope.stepid).then(function (actionItem) {
                            scope.setActive(actionItem);
                        });
                    }
                    scope.setActive = function (task) {
                        task.active = true;
                        setTimeout(function () {
                            $(".project-name").focus();
                        }, 50);
                    }

                    scope.deleteItem = function (item) {
                        a3Service().deleteActionListItem(item.id).then(function () {
                            var index = scope.items.indexOf(item);
                            if (index > -1) {
                                scope.items.splice(index, 1);
                            }
                        });
                    };

                    scope.changeActionDate = function (item, event) {
                        const orgEvt = event.originalEvent
                        const boardDate = orgEvt.detail
                        item.boardDate = boardDate
                        if (item.boardDate) { // Valid date format
                            item.error = false;
                            var actionDate = {
                                taskId: item.id,
                                actionDate: item.boardDate
                            };
                            a3Service().updateItemActionDate(actionDate).then(function () {
                                $translate('A3_ACTION_ITEM_SAVED').then(function (msg) {
                                    toaster.success(msg);
                                });
                                // publish event on change
                                scope.publish("SendTaskDateUpdated", actionDate);
                            });
                        } else {
                            $translate('COMMON_NOT_VALID_DATE').then(function (trans) {
                                item.error = trans;
                            });

                        }
                    }

                    scope.updateResponsible = function (user, task) {              
                        var userDetails =   {
                                            userId: user.userId,
                                            userName: user.fullName,
                                            userDisplayName: user.displayName,
                                            previousUserId: task.responsibleUserId,
                                            previousUserName: task.responsibleUser
                                            };
                        
                        var options = {
                            taskId: task.id,
                            user: userDetails
                        }
                        boardTaskService().assignUser(options);
                        task.user = user;
                    }

                    scope.titleChanged = function (task) {
                        task.active = false;
                        var taskTitle = {
                            taskId: task.id,
                            title: task.title
                        }
                        boardTaskService().updateTitle(task.id, taskTitle).then(function (result) {

                        });
                    }
                    scope.changeStatus = function (item) {
                        if (scope.readonly) return;
                        item.status = taskDrawingService().getNextStatus(item.status).status;
                        var status = {
                            taskId: item.id,
                            status: item.status
                        };
                        boardTaskService().updateStatus(item.id, status).then(function (taskIds) {

                        });

                    }

                    // Responsible
                    function setResponsible(task) {
                        if (task.responsibleUserId && scope.users) {
                            var user = $filter('filter')(scope.users, { userId: task.responsibleUserId }, false);
                            if (user.length > 0) {
                                task.user = user[0];
                            }
                        }
                    }

                    scope.$watch("a3id", async function () {
                        if (!scope.a3id)
                            return;
                        const users = await a3Service().getUserlistForA3(scope.a3id)
                        scope.users = profileImageService.getProfileImagesForUsers(users)
                        
                        // Get actionlist
                        scope.items.forEach(function (item) {
                            //item.user = $filter('filter')(scope.users, { id: item.responsibleUserId })[0];
                            setResponsible(item);
                            item.boardDate = moment(item.boardDate).toDate();
                        });
                    });

                    scope.$watch("stepid", function () {
                        if (!scope.stepid) return;
                        scope.items = [];
                        a3Service().getActionList(scope.stepid).then(function (items) {
                            scope.items = items;
                            // Connect responsible.
                            scope.items.forEach(function (item) {
                                //item.user = $filter('filter')(scope.users, { id: item.responsibleUserId }, true)[0];
                                setResponsible(item);
                                item.boardDate = moment(item.boardDate).toDate();
                            });
                        });

                    });

                    scope.autoGrow = function (element) {
                        element.style.height = "5px";
                        element.style.height = (element.scrollHeight) + "px";
                    }

                    setTimeout(function () {
                        // Applied globally on all textareas with the "autoExpand" class
                        $(document)
                            .one('focus.autoExpand', 'textarea.autoExpand', function () {
                                var savedValue = this.value;
                                this.value = '';
                                this.baseScrollHeight = this.scrollHeight;
                                this.value = savedValue;
                            })
                            .on('input.autoExpand', 'textarea.autoExpand', function () {
                                var minRows = this.getAttribute('data-min-rows') | 0, rows;
                                this.rows = minRows;
                                rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / 17);
                                this.rows = minRows + rows;
                            });
                    }, 1500);

                    // Subscribe
                    scope.subscribe('NewTask', function (task) {
                        // Handle new event
                        if (task.a3StepId === scope.stepid) {
                            if ($filter('filter')(scope.items, { id: task.id }).length === 0) {
                                task.boardDate = moment(task.boardDate).toDate();
                                scope.items.unshift(task);
                            }
                        }


                    });
                    scope.subscribe('TaskDeleted', function (id) {

                        var task = $filter('filter')(scope.items, { id: id });
                        if (task.length > 0) {
                            scope.$apply(function () {
                                var index = scope.items.indexOf(task[0]);
                                if (index > -1) {
                                    scope.items.splice(index, 1);
                                }
                            });
                        }
                    });
                    scope.subscribe('TaskAssigned', function (status) {

                        var task = $filter('filter')(scope.items, { id: status.taskId });
                        if (task.length > 0) {
                            scope.$apply(function () {

                                task[0].responsibleUserId = status.userId;
                                task[0].responsibleUserName = status.userName;
                                task[0].responsibleUserDisplayName = status.userDisplayName;
                                setResponsible(task[0]);
                            });
                        }
                    });
                    scope.subscribe('TaskStatusUpdated', function (status) {
                        var task = $filter('filter')(scope.items, { id: status.taskId });
                        if (task.length > 0) {
                            scope.$apply(function () {
                                task[0].status = status.status;
                            });
                        }
                    });
                    scope.subscribe('TaskDateUpdated', function (status) {
                        var task = $filter('filter')(scope.items, { id: status.taskId });
                        if (task.length > 0) {
                            scope.$apply(function () {
                                task[0].boardDate = moment(status.actionDate).toDate();
                            });
                        }
                    });
                    scope.subscribe('TaskDescriptionUpdated', function (descriptionUpdate) {
                        var task = $filter('filter')(scope.items, { id: descriptionUpdate.taskId });
                        if (task.length > 0) {
                            scope.$apply(function () {
                                task[0].title = descriptionUpdate.description;
                            });
                        }
                    });

                }
            }
        }]);
