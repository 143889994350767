import { AdminUser, AssetUser, AuthenticationMode, ChangePasswordModel, Company, CountryInfo, CreateCustomer, Customer, CustomerAccount, CustomerLicenseSummary, 
    CustomerListModel, CustomerRolesStats, CustomerSetting, CustomerSettingKeys, CustomerStatusInfo, DeployCustomer, DigiMail, Message, PreferenceKeyValue, TableParams, 
    TimePeriod, User, UserAsset, UserCheckupResult, UserInfo, UserShortInfo, UsersTableValues, ValuesQueryOptions, BusinessUnit} from "@api"
import backend from "@common/services/backendHttp"
import { cachedGetRequest } from "@common/services/cacheService"

const resourceUrl = "api/customer"
const resourceUrlSettings = "api/customersettings"
const resourceUrlMessages = "api/messages"

export const getDefaultCurrency = () => "EUR"

export const getDefaultFiscalmonth = () => 1

export const getCountryList = () => {
    const url = resourceUrl + "/getCountryList"
    return backend.get<CountryInfo[]>(url)
}

export const getCompanySettingServer = (setting: CustomerSettingKeys) => {
    const url = `${resourceUrlSettings}/getsetting/${setting}`
    return backend.get<CustomerSetting>(url)
}

export const updateCompanySettingServer = (key: CustomerSettingKeys, value: string) => {
    const keyValue: PreferenceKeyValue = {
        key,
        value
    }
    const url = `${resourceUrlSettings}/updatesetting/${key}`
    return backend.put<CustomerSetting>(url, keyValue)
}

export const updateLogo = (logoId: string) => {
    const url = resourceUrl + "/updateLogo/" + logoId
    return backend.put<Company>(url, null)
}

export const getLicenseChange = (id: number) => {
    const url = resourceUrl + "/GetLicenseChange/" + id
    return backend.get<CustomerLicenseSummary>(url)
}

export const getLicensesChanges = (timePeriod: TimePeriod) => {
    const url = resourceUrl + "/GetLicensesChanges"
    return backend.post<CustomerLicenseSummary[]>(url, timePeriod)
}

export const increaceMobileLicenses = (increasedNumber: number) => {
    const  url = resourceUrl + "/increaceMobileLicenses/" + increasedNumber
    return backend.put(url, null)
}

export const increaceUserLicenses = (increasedNumber: number) => {
    const url = resourceUrl + "/increaceUserLicenses/" + increasedNumber
    return backend.put(url, null)
}

export const getTableValues = (tableParams: TableParams) => {
    const url = resourceUrl + "/getTableValues/"
    return backend.post<UsersTableValues>(url, tableParams)
}

export const sendMail = (mail: DigiMail) => {
    const url = resourceUrl + "/sendmail"
    return backend.post<number>(url, mail)
}

export const sendMessage = (message: Message) => {
    const url = resourceUrlMessages
    return backend.post(url, message)
}

export const sendBoardMessage = (message: Message) => {
    const url = resourceUrlMessages + "/sendBoardMessage"
    return backend.post(url, message)
}

export const updateMessage = (message: Message) => {
    const url = resourceUrlMessages + "/updateMessage"
    return backend.post(url, message)
}

export const deleteMessage = (id: number) => {
    const url = resourceUrlMessages + "/deleteMessage/" + id
    return backend.delete(url)
}

// TODO: does not exist in backend
// sendPushNotification: function (push) {
//     var url = webApiUrl + "/notifications/push";
//     return $http.post(url, push).then(function (result) {
//         return result.data;
//     });
// },

export const getCurrent = () => {
    const url = resourceUrl + "/getCurrent"
    return cachedGetRequest<Company>(url, 60).then(current => {
        return current
    }) 
}

export const getAccount = () => {
    const url = resourceUrl + "/getAccount"
    return backend.get<CustomerAccount>(url)
}

export const getRoleStats = () => {
    const url = resourceUrl + "/getRoleStats"
    return backend.get<CustomerRolesStats[]>(url)
}

export const createCustomer = (customer: CreateCustomer) => {
    return backend.post<Customer>(resourceUrl, customer)
}

export const updateCustomer = (customerData: CreateCustomer) => {
    const url = resourceUrl + "/" + customerData.customer?.id
    return backend.put<Customer>(url, customerData)
}

export const deployCustomer = (deployCustomer: DeployCustomer) => {
    const url = resourceUrl + "/deployCustomer"
    return backend.post<Customer>(url, deployCustomer)
}

export const createSupportUser = (customerId: number, adminUser: AdminUser) => {
    const url = resourceUrl + "/CreateSupportUser/" + customerId
    return backend.post<UserInfo>(url, adminUser)
}


export const getAllUsers = () => {
    const url = resourceUrl + "/getAllUsers"
    return cachedGetRequest<UserShortInfo[]>(url, 60).then(users => {
        return users
    }) // backend.get<UserShortInfo[]>(url)
}


export const getAllUsersWithAppInstallations = () => {
    const url = resourceUrl + "/getAllUsersWithAppInstallations/"
    return backend.get<UserInfo[]>(url)
}

export const getApiKeys = () => {
    const url = resourceUrl + "/getApiKeys"
    return backend.get<UserInfo[]>(url)
}

export const getMessagesWithOptions = (options: ValuesQueryOptions) => {
    const url = resourceUrlMessages + "/getMessagesWithOptions/"
    return backend.post<Message[]>(url, options)
}

export const getMessages = () => {
    return backend.get<Message[]>(resourceUrlMessages)
}

export const addReply = (messageId: number, reply: Message) => {
    const url = resourceUrlMessages + "/" + messageId + "/reply"
    return backend.post<Message>(url, reply)
}

export const getReplies = (messageId: number) => {
    const url = resourceUrlMessages + "/" + messageId + "/getreplies"
    return backend.get<Message[]>(url)
}

export const getAdminMessages = () => {
    const url = resourceUrlMessages + "/getAdminMessages"
    return backend.get<Message[]>(url)
}

export const getMessagesByAsset = (assetId: number, timePeriod: TimePeriod) => {
    const url = resourceUrlMessages + "/getMessagesByAsset/" + assetId
    return backend.post<Message[]>(url, timePeriod)
}

export const getUsersForAsset = (assetId: number) => {
    const url = resourceUrl + "/getUsersForAsset/" + assetId
    return backend.get<AssetUser[]>(url)
}

export const getUsersWithOutAsset = () => {
    const url = resourceUrl + "/GetUsersWithOutAsset"
    return backend.get<AssetUser[]>(url)
}

export const giveAccessToUsersForAsset = (assetId: number, users: UserAsset[]) => {
    const url = resourceUrl + "/GiveAccessToUsersForAsset/" + assetId
    return backend.post(url, users)
}

export const giveAccessToUsersForBoard = (boardId: number, users: UserAsset[]) => {
    const url = resourceUrl + "/GiveAccessToUsersForBoard/" + boardId
    return backend.post(url, users)
}

export const getCustomersForAdmin = () => {
    const url = resourceUrl + "/getcustomersforadmin"
    return backend.get<CreateCustomer[]>(url)
}

export const getArcivedCustomersForAdmin = () => {
    const url = resourceUrl + "/GetArcivedCustomersForAdmin"
    return backend.get<CreateCustomer[]>(url)
}

export const getCustomerForAdmin = (customerId: number) => {
    const url = resourceUrl + "/GetOneCustomerForAdmin/" + customerId
    return backend.get<CreateCustomer>(url)
}

export const getCustomersSimpleForAdmin = () => {
    const url = resourceUrl + "/getcustomerssimpleforadmin"
    return backend.get<CustomerListModel[]>(url)
}

export const updateMaxBusinessUnitLicenses = (businessUnit: BusinessUnit) => {
    const url = resourceUrl + "/UpdateMaxBusinessUnitLicenses"
    return backend.post(url, businessUnit)
}

export const getAvailableUserLicensesForBusinessUnit = (businessUnitId: number) => {
    const url = resourceUrl + "/GetAvailableUserLicensesForBusinessUnit/" + businessUnitId 
    return backend.get<number>(url)
}
export const getActiveUserLicenses = (businessUnitId?: number) => {
    const url = businessUnitId
    ? resourceUrl + "/getActiveUserLicenses/" + businessUnitId
    : resourceUrl + "/getActiveUserLicenses";
    return backend.get<number>(url)
}

export const getActiveMobileLicenses = () => {
    const url = resourceUrl + "/getActiveMobileLicenses"
    return backend.get<number>(url)
}

// Active Directory, password or mixed mode
export const getAuthenticationMode = () => {
    const url = resourceUrl + "/getAuthenticationMode"
    return backend.get<AuthenticationMode>(url)
}

export const grantRolePermission = (role: string) => {
    const url = "api/user/GrantRolePermission"
    return backend.post(url, { role })
}

export const removeRolePermission = (role: string) => {
    const url = "api/user/RemoveRolePermission"
    return backend.post(url, { role })
}

export const createPasswordToken = (userName: string) => {
    const url = "api/user/createpasswordtoken"
    return backend.post(url, { userName: userName })
}

export const changePassword = (data: ChangePasswordModel) => {
    const url = "api/user/changepassword"
    return backend.post(url, data)
}

export const checkExistingUser = (userNameOrEmail: string) => {
    const url = "api/user/CheckExistingUser/" + userNameOrEmail
    return backend.post<UserCheckupResult>(url, {userNameOrEmail})
}

export const getCustomerStatus = () => {
    const url = resourceUrl + "/GetCustomerStatus"
    return backend.get<CustomerStatusInfo[]>(url)
}
