import { hasDefaultAccess } from "@common/components/dashboard/designer/common/userAccessService"

angular.module("DigiLean")
    .directive('threeCTextCell', ['debounce',
        function (debounce) {
            return {
                templateUrl: "threeCTextCell.html",
                restrict: "E",
                scope: {
                    'content': '=',
                    'changeHandler': '&',
                    'userAccess': '<?',
                },
                link: function (scope, elem, attrs) {
                    scope.$watch('content', function (model) {

                    });

                    scope.contentChanged = function() {
                        changesDebounced();
                    }
                
                    var changesDebounced = debounce(commitChanges, 3000, false);
                    function commitChanges() {
                        scope.changeHandler({content: scope.content});
                    }

                    scope.canEdit = true;

                    scope.$watch('userAccess', function (userAccess) {
                        scope.canEdit = hasDefaultAccess(userAccess);
                    });

                }
            }
        }]);