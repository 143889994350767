import type { PreferenceKeyValue, User, UserFullProfile, UserPreference, UserProfileImageInfo } from "@api"
import config from "@common/config"
import { DigiLeanAuthUser, RoleArray } from "@common/model/types"
import backend, { BackendHttpError } from "@common/services/backendHttp"
import { setAuthUser, authUser, setFullUserProfile, setUserProfile } from "@common/stores/userStore"
import { User as OidcUser} from "oidc-client-ts"
import oidc from "@common/services/auth/digileanOidc"

const profileImageContainerUrl = `${config.fileStoreUrl}/profile-images`
let resourceUrl = "api/user"

authUser.subscribe(user => {
    if (user && user.loggedIn) {
        loadUserProfile()
        loadUserFullProfile()
    } else {
        setUserProfile({})
        setFullUserProfile({})
    }
})

export async function getAllUsers() {
    const all = await backend.get<User[]>(resourceUrl)
    return all
}

export async function loadUserFullProfile() {
    try {
        const fullProfile = await backend.get<UserFullProfile>(`${resourceUrl}/getfullprofile`)
        if (fullProfile.settings && typeof fullProfile.settings.settings === "string")
            fullProfile.settings = JSON.parse(fullProfile.settings.settings);
        
        setFullUserProfile(fullProfile)
        return fullProfile
    } catch(err: any) {
        handleGetUserProfileError(err)
    }
}

export async function loadUserProfile() {
    try {
        const profile = await backend.get<User>(`${resourceUrl}/getuserprofile`)
        setUserProfile(profile)
        return profile
    } catch (err: any) {
        handleGetUserProfileError(err)
    }
}

export async function getUsersProfileImage() {
    const userProfileImage = await backend.get<UserProfileImageInfo[]>("profile/GetUsersWithProfileImage")
    return userProfileImage
}

export function getProfileImageFilestore(imageSize: string, fileName: string) {
    return `${profileImageContainerUrl}/${imageSize}-${fileName}`
}

export async function getUserPreference(key: string) {
    const url = `${resourceUrl}/preference/${key}`
    const setting = await backend.get<UserPreference>(url)
    if (setting.value && typeof setting.value === "string")
        return JSON.parse(setting.value)
    return []
}

export async function deleteUserPreference(key: string) {
    const url = `${resourceUrl}/preference/${key}`
    return await backend.delete<UserPreference>(url)
}

export async function updateUserPreference(key: string, value: PreferenceKeyValue) {
    const url = `${resourceUrl}/preference`
    let valString = ""
    if (value  && typeof value !== "string")
        valString = JSON.stringify(value)

    const preference = {
        key: key,
        value: valString
    }
    const setting = await backend.put<UserPreference>(url, preference)
    if (setting.value && typeof setting.value === "string")
        return JSON.parse(setting.value)
    return []
}

export function setAuthUserFromOidc (user: OidcUser) {
    const authUser = convertOidcDigiLeanUser(user)
    setAuthUser(authUser)
}

function handleGetUserProfileError(err: Error) {
    if (err instanceof BackendHttpError) {
        if (err.status === 404) {
            console.log("No longer valid/paying user, throw out!")
            oidc.signOut()
        }
    } else
        console.error(err)
}

function convertOidcDigiLeanUser (user: OidcUser) {
    let authUser: DigiLeanAuthUser = {
        loggedIn: true,
        token: user.access_token,
        idToken: user.id_token,
        roles: new RoleArray()
    }
    
    authUser.customerId = parseInt(user.profile.customer_id as string)
    authUser.userName = user.profile.preferred_username
    authUser.userId = user.profile.sub
    authUser.idp = user.profile.idp as string
    authUser.language = user.profile.locale
    authUser.tokenExpire = user.expires_at
    
    authUser.roles = new RoleArray(user.profile.role as string | string[])
    
    return authUser
}