import * as userPref from "@common/services/user/userPreferenceService"
import * as assetService from "@common/services/assetService"
import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .controller("a3Controller", 
                ['$scope','$filter','authService','$uibModal','$translate','projectService','a3templateService','a3Service','deviationService','suggestionService',
        function ($scope,  $filter,  authService,  $uibModal,  $translate, projectService,  a3templateService,  a3Service,  deviationService,  suggestionService) {

            $scope.allA3s = []

            $scope.newa3 = []
            $scope.newa3Counter = 0

            $scope.hasImprovementTasks = false

            $scope.hasDeviation = false
            $scope.isLoading = true
            $scope.assets = {}
            $scope.projects = []
            $scope.templates = {}
            var userid = authService.getCurrentUser().userId
            $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("A3Admin")
            // if page is refreshed we need to refresh
            $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                $scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("A3Admin")
            })
            
            var memberAssets = []

            $scope.filterPreferences = null
            $scope.assetFilterActive = false
            $scope.templateFilterActive = false
            $scope.myA3sFilterActive = false
            $scope.participantFilterActive = false
            $scope.improvementFilterActive = false
            $scope.deviationFilterActive = false

            $scope.newPieChart = {
                data: [0, 0, 0, 0],
                options: {
                    radius: 18,
                    fill: ["#f3f3f4"],
                }
            }
            $scope.planPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#f3f3f4", "#f3f3f4", "#f3f3f4"],
                }
            }
            $scope.doPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#5cb85c", "#f3f3f4", "#f3f3f4"],
                }
            }
            $scope.checkPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#f3f3f4"],
                }
            }
            $scope.actPieChart = {
                data: [1, 1, 1, 1],
                options: {
                    radius: 18,
                    fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#5cb85c"]
                }
            }

            // TODO use a3Service.getStatus() instead
            $scope.statusOptions = [
                {
                    statusDB: "New",
                    translated: $translate.instant('COMMON_NEW'),
                    cssClass: "new",
                    filteredAndInResultList: false,
                    pie: $scope.newPieChart
                }, {
                    statusDB: "Plan",
                    translated: $translate.instant('A3_PLAN'),
                    cssClass: "plan",
                    filteredAndInResultList: false,
                    pie: $scope.planPieChart
                }, {
                    statusDB: "Do",
                    translated: $translate.instant('COMMON_DO'),
                    cssClass: "do",
                    filteredAndInResultList: false,
                    pie: $scope.doPieChart
                }, {
                    statusDB: "Check",
                    translated: $translate.instant('COMMON_CHECK'),
                    cssClass: "check",
                    filteredAndInResultList: false,
                    pie: $scope.checkPieChart
                }, {
                    statusDB: "Act",
                    translated: $translate.instant('COMMON_ACT'),
                    cssClass: "act",
                    filteredAndInResultList: false,
                    pie: $scope.actPieChart
                }, {
                    statusDB: "Closed",
                    translated: $translate.instant('DEVIATION_CLOSED'),
                    filteredAndInResultList: false
                }
            ]

            $scope.subscribe("A3sDeleted", function (ids) {
                // remove a3 from list
                var a3ToRemove = $filter('filter')($scope.allA3s, valueInList('id', ids))
                if (a3ToRemove.length > 0) {
                    angular.forEach(a3ToRemove, function (a3) {
                        var index = $scope.allA3s.indexOf(a3)
                        $scope.allA3s.splice(index, 1)
                    })
                    $scope.resultList = $scope.allA3s
                    $scope.$apply()
                }
            })

            $scope.subscribe("A3Created", function (a3) {
                // Check if user has A3 asset before adding
                var exist = $filter('filter')($scope.resultList, { id: a3.id })
                if (exist.length === 0) {
                    addFavoriteNavigationMetadata(a3)
                    $scope.allA3s.unshift(a3)
                }
            })

            $scope.$watch('query', function () {
                if ($scope.query) {
                    $scope.searchQuery = angular.copy($scope.query)
                }
            })


            assetService.getAllAssets().then(function (assets) {
                $scope.assets = assets
                // 
                assetService.getAllUserAssets().then(function (myAssets) {
                    memberAssets = myAssets
                    resetAssetFilter()
                    filterByUserPreference()
                })
            })

            a3templateService().getList().then(function (templates) {
                $scope.templates = templates
                resetTemplateFilter()
                filterByUserPreference()
            })

            function loadProjects() {
                projectService().getProjectListWithA3().then(function (items) {
                    $scope.projects = items
                    resetProjectFilter()
                    filterByUserPreference()
                })
            }
            loadProjects()
            // Quick access
            $scope.quickAccess = []


            let initialLoads = []
            initialLoads.push(userPref.getA3Favorites().then(function (a3Favorites) {
                if (a3Favorites && Array.isArray(a3Favorites)) {
                    $scope.quickAccess = a3Favorites.map(a3Fav => {
                        a3Fav.isStar = true
                        a3Fav.hasAccess = false
                        return a3Fav
                    })
                }
            }))
            initialLoads.push(a3Service().getA3s().then(function (a3s) {
                $scope.allA3s = a3s.reverse()

                userPref.getA3Filter().then(function (filterpreferences) {
                    if (filterpreferences) {
                        $scope.filterPreferences = filterpreferences
                        filterByUserPreference()
                    } 
                    applyFilter()
                })
            }))
            Promise.all(initialLoads).then(() => {
                setFavorites()
                $scope.isLoading = false
            })

            function setFavorites() {
                for (var i = 0; i < $scope.allA3s.length; i++) {
                    let currentA3 = $scope.allA3s[i]

                    currentA3.isSelected = false
                    
                    var isFavorite = $scope.quickAccess.find(q => q.id == currentA3.id)
                    if (isFavorite) {
                        currentA3.isStar = true
                        isFavorite.title = currentA3.title
                        isFavorite.goal = currentA3.goal
                        isFavorite.hasAccess = true
                    } else {
                        currentA3.isStar = false
                    }
                    addFavoriteNavigationMetadata(currentA3)

                    // Set improvement flag to true if there exist A3s generated from an improvement suggestion
                    if (currentA3.improvementSuggestionId) {
                        $scope.hasImprovementTasks = true
                    }

                    // Set deviation flag to true if there exist A3s generated from an deviation
                    if (currentA3.deviationId) {
                        $scope.hasDeviation = true
                    }
                }

                // Remove quick access board no longer available
                const quickAccessBoardsToRemove = $scope.quickAccess.filter(q => q.hasAccess == false)
                console.log("quickAccessBoardsToRemove", quickAccessBoardsToRemove)
                if (quickAccessBoardsToRemove.length > 0) {
                    quickAccessBoardsToRemove.forEach(x => {
                        var index = $scope.quickAccess.indexOf(x)
                        $scope.quickAccess.splice(index, 1)
                    })
                    saveQuickAccess()
                }
            }

            $scope.addToQuickAccess = async function (a3) {
                a3.isStar = true
                $scope.quickAccess = await userPref.addA3ToFavorites(a3.id, a3.title)
                setFavorites()
            }

            $scope.removeFromQuickAccess = async function (a3) {
                a3.isStar = false
                $scope.quickAccess = await userPref.removeA3FromFavorites(a3.id)
                setFavorites()
            }

            function saveQuickAccess() {
                userPref.updateFavoriteA3s($scope.quickAccess).then(function () {
                    $scope.publish("FavoriteA3sChanged", $scope.quickAccess)
                })
            }

            
            function filterByUserPreference() {
                if ($scope.filterPreferences) {
                    if ($scope.filterPreferences.template) {
                        setTemplateFilterPreferences($scope.filterPreferences.template);
                    }
                    if ($scope.filterPreferences.asset) {
                        setAssetFilterPreferences($scope.filterPreferences.asset);
                    }
                    if ($scope.filterPreferences.project) {
                        setProjectFilterPreferences($scope.filterPreferences.project);
                    }
                    if ($scope.filterPreferences.status) {
                        setStatusFilterPreferences($scope.filterPreferences.status);
                    }
                    if ($scope.filterPreferences.myA3s) {
                        $scope.myA3sFilterActive = true;
                    }
                    if ($scope.filterPreferences.participant) {
                        $scope.participantFilterActive = true;
                    }
                    if ($scope.filterPreferences.improvement) {
                        $scope.improvementFilterActive = true;
                    }
                    if ($scope.filterPreferences.deviation) {
                        $scope.deviationFilterActive = true;
                    }
                    applyFilter();
                }
            }
            function setTemplateFilterPreferences(preferences) {
                angular.forEach(preferences, function (a3TemplateId) {
                    for (var a = 0; a < $scope.templates.length; a++) {
                        if ($scope.templates[a].id === a3TemplateId) {
                            $scope.templates[a].filteredAndInResultList = true;
                            break;
                        }
                    }
                });
            }
            function setAssetFilterPreferences(preferences) {
                angular.forEach(preferences, function (filteredAssetId) {
                    for (var a = 0; a < $scope.assets.length; a++) {
                        if ($scope.assets[a].id === filteredAssetId) {
                            $scope.assets[a].filteredAndInResultList = true;
                            break;
                        }
                    }
                });
            }
            function setProjectFilterPreferences(preferences) {
                angular.forEach(preferences, function (projectId) {
                    for (var t = 0; t < $scope.projects.length; t++) {
                        if ($scope.projects[t].id === projectId) {
                            $scope.projects[t].filteredAndInResultList = true;
                            break;
                        }
                    }
                });
            }
            function setStatusFilterPreferences(preferences) {
                angular.forEach(preferences, function (filteredStatus) {
                    for (var a = 0; a < $scope.statusOptions.length; a++) {
                        if ($scope.statusOptions[a].statusDB === filteredStatus) {
                            $scope.statusOptions[a].filteredAndInResultList = true;
                            break;
                        }
                    }
                });
            }
            function resetProjectFilter() {
                angular.forEach($scope.projects, function (project) {
                    project.filteredAndInResultList = false;
                });
            }

            function resetAssetFilter() {
                angular.forEach($scope.assets, function (asset) {
                    asset.filteredAndInResultList = false;
                });
            }

            function resetStatusFilter() {
                angular.forEach($scope.statusOptions, function (item) {
                    item.filteredAndInResultList = false;
                });
            }

            function resetTemplateFilter() {
                angular.forEach($scope.templates, function (template) {
                    template.filteredAndInResultList = false;
                });
            }

            function applyFilter() {
                $scope.resultList = $scope.allA3s;
                var selectedAssets = $filter('filter')($scope.assets, { filteredAndInResultList: true });
                if (selectedAssets.length > 0) {
                    var selectedAssetIds = [];
                    for (let a = 0; a < selectedAssets.length; a++) {
                        selectedAssetIds.push(selectedAssets[a].id);
                    }
                    $scope.resultList = $filter('filter')($scope.allA3s, valueInList('assetId', selectedAssetIds));
                }
                var selectedProjectAssets = $filter('filter')($scope.projects, { filteredAndInResultList: true });
                if (selectedProjectAssets.length > 0) {
                    var selectedProjectAssetIds = [];
                    for (let a = 0; a < selectedProjectAssets.length; a++) {
                        selectedProjectAssetIds.push(selectedProjectAssets[a].id);
                    }
                    $scope.resultList = $filter('filter')($scope.resultList, valueInList('assetId', selectedProjectAssetIds));
                }
                var selectedTemplates = $filter('filter')($scope.templates, { filteredAndInResultList: true });
                if (selectedTemplates.length > 0) {
                    var selectedTemplateIds = [];
                    for (var b = 0; b < selectedTemplates.length; b++) {
                        selectedTemplateIds.push(selectedTemplates[b].id);
                    }
                    $scope.resultList = $filter('filter')($scope.resultList, valueInList('a3TemplateId', selectedTemplateIds));
                }
                var activeStatusFilter = statusFilterActive();
                if (activeStatusFilter.length > 0) {
                    $scope.resultList = $filter('filter')($scope.resultList, valueInList('status', activeStatusFilter));
                }
                if ($scope.improvementFilterActive) {
                    $scope.resultList = $filter('filter')($scope.resultList, hasValueFilter('improvementSuggestionId'));
                }
                if ($scope.deviationFilterActive) {
                    $scope.resultList = $filter('filter')($scope.resultList, hasValueFilter('deviationId'));
                }
                if ($scope.myA3sFilterActive) {
                    $scope.resultList = $filter('filter')($scope.resultList, { ownerUserId: userid });
                }
                if ($scope.participantFilterActive) {
                    $scope.resultList = filterMember($scope.resultList);
                }
            }

            function filterMember(list) {
                if (list) {
                    if (memberAssets && memberAssets.length > 0) {
                        var memberlist = [];
                        angular.forEach(list, function (filteredA3) {
                            var shouldFilter = $filter('filter')(memberAssets, { id: filteredA3.assetId });
                            if (shouldFilter.length > 0 && (shouldFilter[0].assetRole === "Member" || shouldFilter[0].assetRole === "OWNER")) { //TODO: should owner be "Owner"?
                                memberlist.push(filteredA3);
                            }
                        });
                        return memberlist;
                    } else {
                        return list; //could not filter a3 list
                    }
                }
            }

            function statusFilterActive() {
                var filteredStatus = [];
                angular.forEach($scope.statusOptions, function (status) {
                    if (status.filteredAndInResultList) {
                        filteredStatus.push(status.statusDB);
                    }
                });
                return filteredStatus;
            }

            function updateFilterPreferences(){
                if (!$scope.filterPreferences) { // Clear filter
                    userPref.deleteA3Filter()
                } else { // update
                    userPref.updateA3Filter(filterPreferences)
                }
            }

            $scope.toggleMyA3sFilter = function () {
                $scope.myA3sFilterActive = !$scope.myA3sFilterActive;
                if ($scope.myA3sFilterActive) {
                    if ($scope.filterPreferences) {
                        $scope.filterPreferences.myA3s = true;
                    } else {
                        $scope.filterPreferences = {
                            myA3s: true
                        };
                    }
                } else { // remove from filter
                    $scope.filterPreferences.myA3s = null;
                }
                applyFilter();
                updateFilterPreferences();
            };

            $scope.toggleParticipantFilter = function () {
                $scope.participantFilterActive = !$scope.participantFilterActive;
                if ($scope.participantFilterActive) {
                    if ($scope.filterPreferences) {
                        $scope.filterPreferences.participant = true;
                    } else {
                        $scope.filterPreferences = {
                            participant: true
                        };
                    }
                } else { // remove from filter
                    $scope.filterPreferences.participant = null;
                }
                applyFilter();
                updateFilterPreferences();
            };

            $scope.toggleImprovementFilter = function () {
                $scope.improvementFilterActive = !$scope.improvementFilterActive;
                if ($scope.improvementFilterActive) {
                    if ($scope.filterPreferences) {
                        $scope.filterPreferences.improvement = true;
                    } else {
                        $scope.filterPreferences = {
                            improvement: true
                        };
                    }
                } else { // remove from filter
                    $scope.filterPreferences.improvement = null;
                }
                applyFilter();
                updateFilterPreferences();
            };

            $scope.toggleDeviationFilter = function () {
                $scope.deviationFilterActive = !$scope.deviationFilterActive;
                if ($scope.deviationFilterActive) {
                    if ($scope.filterPreferences) {
                        $scope.filterPreferences.deviation = true;
                    } else {
                        $scope.filterPreferences = {
                            deviation: true
                        };
                    }
                } else { // remove from filter
                    $scope.filterPreferences.deviation = null;
                }
                applyFilter();
                updateFilterPreferences();
            };

            $scope.filterAsset = function (assetId) {
                var assetlist = $filter('filter')($scope.assets, { id: assetId }, true);
                var asset = assetlist[0];
                if (asset) {
                    asset.filteredAndInResultList = !asset.filteredAndInResultList;
                    if (asset.filteredAndInResultList) {
                        if ($scope.filterPreferences && $scope.filterPreferences.asset) {
                            $scope.filterPreferences.asset.push(asset.id);
                        } else {
                            if ($scope.filterPreferences) {
                                $scope.filterPreferences.asset = [asset.id];
                            } else {
                                $scope.filterPreferences = {
                                    asset: [asset.id]
                                };
                            }
                            $scope.filterPreferences.asset = [asset.id];
                        }
                    } else { // remove from filter
                        if ($scope.filterPreferences.asset) {
                            var index = $scope.filterPreferences.asset.indexOf(asset.id);
                            $scope.filterPreferences.asset.splice(index, 1);
                        }
                    }
                }
                applyFilter();
                updateFilterPreferences();
            };

            $scope.filterProject = function (project) {
                if (project) {
                    project.filteredAndInResultList = !project.filteredAndInResultList;
                    if (project.filteredAndInResultList) {
                        if ($scope.filterPreferences && $scope.filterPreferences.project) {
                            $scope.filterPreferences.project.push(project.id);
                        } else {
                            if ($scope.filterPreferences) {
                                $scope.filterPreferences.project = [project.id];
                            } else {
                                $scope.filterPreferences = {
                                    project: [project.id]
                                };
                            }
                            $scope.filterPreferences.project = [project.id];
                        }
                    } else { // remove from filter
                        if ($scope.filterPreferences.project) {
                            var index = $scope.filterPreferences.project.indexOf(project.id);
                            $scope.filterPreferences.project.splice(index, 1);
                        }
                    }
                }
                applyFilter();
                updateFilterPreferences();
            }

            $scope.filterTemplate = function (id) {
                var templatelist = $filter('filter')($scope.templates, { id: id }, true);
                var template = templatelist[0];
                if (template) {
                    template.filteredAndInResultList = !template.filteredAndInResultList;
                    if (template.filteredAndInResultList) {
                        if ($scope.filterPreferences && $scope.filterPreferences.template) {
                            $scope.filterPreferences.template.push(template.id);
                        } else {
                            if ($scope.filterPreferences) {
                                $scope.filterPreferences.template = [template.id];
                            } else {
                                $scope.filterPreferences = {
                                    template: [template.id]
                                };
                            }
                            $scope.filterPreferences.template = [template.id];
                        }
                    } else { // remove from filter
                        if ($scope.filterPreferences.template) {
                            var index = $scope.filterPreferences.template.indexOf(template.id);
                            $scope.filterPreferences.template.splice(index, 1);
                        }
                    }
                }
                applyFilter();
                updateFilterPreferences();
            };

            $scope.showAllA3s = function () {
                clearFilterFlags();
                resetAssetFilter();
                resetTemplateFilter();
                resetStatusFilter();
                resetProjectFilter();
                $scope.resultList = $scope.allA3s;
                resetFilterPreferences();
            };

            function resetFilterPreferences() {
                $scope.filterPreferences = null;
                updateFilterPreferences();
            }

            function clearFilterFlags() {
                $scope.assetFilterActive = false;
                $scope.templateFilterActive = false;
                $scope.statusFilterActive = false;
                $scope.myA3sFilterActive = false;
                $scope.participantFilterActive = false;
                $scope.improvementFilterActive = false;
                $scope.deviationFilterActive = false;
            }

            $scope.showStatus = function (statusObj) {
                if (statusObj) {
                    statusObj.filteredAndInResultList = !statusObj.filteredAndInResultList;
                    if (statusObj.filteredAndInResultList) {
                        if ($scope.filterPreferences && $scope.filterPreferences.status) {
                            $scope.filterPreferences.status.push(statusObj.statusDB);
                        } else {
                            if ($scope.filterPreferences) {
                                $scope.filterPreferences.status = [statusObj.statusDB];
                            } else {
                                $scope.filterPreferences = {
                                    status: [statusObj.statusDB]
                                };
                            }
                            $scope.filterPreferences.status = [statusObj.statusDB];
                        }
                    } else { // remove from filter
                        if ($scope.filterPreferences.status) {
                            var index = $scope.filterPreferences.status.indexOf(statusObj.statusDB);
                            $scope.filterPreferences.status.splice(index, 1);
                        }
                    }
                }
                applyFilter();
                updateFilterPreferences();
            };

          

            $scope.showImprovementTasks = function () {
                $scope.resultList = $filter('filter')($scope.resultList, hasValueFilter('improvementSuggestionId'));
            };

            $scope.showDeviation = function () {
                $scope.resultList = $filter('filter')($scope.resultList, hasValueFilter('deviationId'));
            };

            $scope.selectMyA3s = function () {
                $scope.resultList = $filter('filter')($scope.resultList, { ownerUserId: userid });
            };

            function hasValueFilter(property) {
                return function (item) {
                    if (item[property]) return true;
                    return false;
                }
            }

            function valueInList(property, list) {
                return function (item) {
                    for (var i = 0; i < list.length; i++) {
                        if (item[property] === list[i]) return true;
                    }
                    return false;
                }
            }

            $scope.isDeleteActivated = false;
            $scope.activateDelete = function () {
                $scope.isDeleteActivated = true;
            };
            $scope.deActivateDelete = function () {
                $scope.isDeleteActivated = false;
            };

            $scope.isImprovementSuggestion = function (A3) {
                if (A3.improvementSuggestionId) return true;
                else false;
            };

            $scope.isDeviation = function (A3) {
                if (A3.deviationId) return true;
                else false;
            };

            $scope.openImprovementSuggestion = function (A3) {
                var improvementsuggestionid = A3.improvementSuggestionId;
                suggestionService().get(improvementsuggestionid).then(function (suggestion) {
                    $uibModal.open({ backdrop: 'static',
                        templateUrl: 'fullImprovementInfoForm.html',
                        controller: 'fullImprovementModalController',
                        windowClass: 'fullSuggestion-modal-window',
                        resolve: {
                            suggestion: function () {
                                return suggestion;
                            },
                            activeTab: function () {
                                return "Info";
                            }
                        }
                    });
                }, function () {
                    $translate('IMPROVEMENT_IMPROVEMENT_DELETED').then(function (msg) {
                        toaster.error(msg);
                    });
                });
            };


            $scope.openDeviation = function (A3) {
                var deviationid = A3.deviationId;
                deviationService().get(deviationid).then(function (deviation) {
                    $uibModal.open({ backdrop: 'static',
                        templateUrl: 'fullDeviation.html',
                        controller: 'fullDeviationController',
                        windowClass: 'full-screen',
                        resolve: {
                            deviation: function () {
                                return deviation;
                            },
                            activeTab: function () {
                                return "Info";
                            }
                        }
                    });
                }, function () {
                    $translate('DEVIATION_DEVIATION_DELETED').then(function (msg) {
                        toaster.error(msg);
                    });
                });
            };
            $scope.deleteSelected = function () {
                var A3ToBeDeleted = [];
                for (var i = 0; i < $scope.allA3s.length; i++) {
                    if ($scope.allA3s[i].isSelected === true) {
                        A3ToBeDeleted.push($scope.allA3s[i].id);
                    }
                }
                if (A3ToBeDeleted.length > 0) {
                    a3Service().deleteA3s(A3ToBeDeleted).then(function () {
                        $scope.publish("SendA3sDeleted", A3ToBeDeleted);
                    });
                }
            };



            function addFavoriteNavigationMetadata(a3) {
                a3.module = "a3";
                a3.uiRef = "a3wizard({id:" + a3.id + "})";
                a3.name = a3.title;
            }

            // create new A3
            $scope.addA3 = function () {

                $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newA3Form.html',
                    controller: 'newA3ModalController',
                    resolve: {
                        a3: function () {
                            return {
                                id: 0,
                                title: "",
                                assetId: "",
                                categoryId: "",
                                goal: ""
                            }
                        }

                    }
                });
            };

        }]);
