import { DlSubject } from "@common/stores/reactive"

export interface LanguageSelected {
    code: string
    ready: boolean
}

interface LangStore {
    code: string
    store: object
}

//const baseLangUrl = "language"

let loadingPromise: Promise<object> | null

const getLang = async (code: string) => {
    if (!code) return
    // rest.get<object>(`${baseLangUrl}/${code}`)
    const res = await fetch(`lang/${code}.json`)
    const json = await res.json()
    return json
}

const loadAllLabelsForLanguage = (code: string) => {
    let selected = selectedLangStore.getValue()

    // language promise is already loading or loaded
    if (loadingPromise && selected.code == code)
        return loadingPromise

    selectedLangStore.next({code, ready: false})
    loadingPromise = getLang(code)
    return loadingPromise.then(allLabels => {
        const labelsCount = Object.keys(allLabels).length
        //console.log(`Got labels for '${code}' count=`, labelsCount)

        if (allLabels && labelsCount > 10) {
            langLabelsStore = allLabels
            selectedLangStore.next({code, ready: true})
            return langLabelsStore
        }
        else {
            let err = `got no language labels for language: ${code}`
            console.error(err)
            throw new Error(err)
        }
    }).catch(err => {
        selectedLangStore.next({code, ready: false})
        console.error("could not load lang:", code)
        throw err
    })
}

const selectedLangStore = new DlSubject<LanguageSelected>({code: "en", ready: false})
export const setAndLoadLang = (code: string) => {
    console.log("setAndLoadLang, code=", code)
    if (!code)
        code = "en" //default for those missing
    return loadAllLabelsForLanguage(code)
}
export const selectedLang = selectedLangStore.asObservable()

// store
let langLabelsStore = {}

// only use in startup config.js
export const getCurrentLang = (): LangStore => {
    let selected = selectedLangStore.getValue()
    return {
        code: selected.code,
        store: langLabelsStore
    }
}
function getTranslation(label: string) {
    let labelLang = langLabelsStore[label]
    if (!labelLang) {
        return label
    }
    return labelLang
}
export const translateLabel = async (label: string) => {
    let selected = selectedLangStore.getValue()

    try {    
        if (loadingPromise) {
            await loadingPromise
            //console.log(`translate label: already in promise: lang is ${selected.code}, label is ${label}`)
            return getTranslation(label)
        }
    
        await loadAllLabelsForLanguage(selected.code)
        //console.log(`translate label: initiate loading first: lang is ${selected.code}, label is ${label}`)
        return getTranslation(label)
    }
    catch (e) {
        return label
    }
}

/**
 * translate label instant
 * @example
 * translateLabelInstant("COMMON_USER_SAVED")
 * @param label try to translate, return self if no translation available
 * @returns 
 */
export const translateLabelInstant = (label: string) => {
    return getTranslation(label)
}