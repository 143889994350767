import { getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import toaster from "@common/components/toast"

var DigiLean = angular.module('DigiLean');  
DigiLean.controller('consequenceAdminController', ['$scope', '$translate', 'deviationService','modalService','languageAdminService',
function ($scope, $translate, deviationService, modalService,languageAdminService) {
    $scope.savedSuccessfully = false;
    $scope.message = "";

    $translate('DEVIATION_NEW_DEVIATION_CONSEQUENCE').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
    });


    $scope.clearActive = function () {
        $translate('DEVIATION_NEW_DEVIATION_CONSEQUENCE').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
        });
        $scope.message = "";
        $scope.selectedCustomLanguageLabel = {};
        $scope.registration = {
            isNew: true,
            id: 0,
            title: "",
            description: "",
            customLanguageLabels: []
        };
    }
    $scope.clearActive();

    $scope.currency = customerService.getDefaultCurrency()
    getCompanySetting("currency").then(function (setting) {
        if (setting) {
            $scope.currency = setting
        }
    })

    
    var defaultConfig = {languageCode: '',
                    languageLabel: $translate.instant('ADMIN_DEFAULT_CONFIG'),
                    nativeLabel: $translate.instant('ADMIN_DEFAULT_CONFIG')
                    };
    $scope.selectedLanguage = defaultConfig;
    $scope.languages = languageAdminService().getSupportedLanguages()
    $scope.languages.unshift($scope.selectedLanguage);
    $scope.selectedCustomLanguageLabel;

    $scope.changeLanguage = function (language) {
        if(!language) return;
        $scope.message = "";
        if(!$scope.registration.title){
            $scope.selectedLanguage = defaultConfig; //reset back top default setting
            $scope.message = $translate.instant('ADMIN_DATA_FIELD_MISSING') + ": " + $translate.instant('DEVIATION_CONSEQUENCE');
            return;
        }
        $scope.selectedLanguage = language;
        $scope.selectedCustomLanguageLabel = $scope.registration.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        if(!$scope.selectedCustomLanguageLabel) $scope.selectedCustomLanguageLabel = {};
    }

    $scope.updateTranslationLabel = function (translabel) {
        var existing = $scope.registration.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        
        if(!existing){
            var customLanguageLabel = {
                id:0,
                languageCode: $scope.selectedLanguage.languageCode,
                label: translabel.label,
                description: translabel.description
            }
            $scope.registration.customLanguageLabels.push(customLanguageLabel);
        } else {
            existing.label = translabel.label;
            existing.description = translabel.description;
        }
    }


    $scope.delete = function (item) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CONSEQUENCE_CONFIRMATION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.COMMON_DELETE,
                headerText: translations.COMMON_DELETE + ' ' + item.title + '?',
                bodyText: translations.ADMIN_MESSAGE_DELETE_CONSEQUENCE_CONFIRMATION 
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                deviationService().deleteConsequence(item.id).then(function (data) {  
                    load();
                    $scope.clearActive();
                    $translate('ADMIN_MESSAGE_CONSEQUENCE_DELETED').then(function (msg) {
                            toaster.info(msg); 
                        }, function (translationId) {
                            $scope.headline = translationId;
                    });
                },function (err) {
                });
            });
        });
    }

    $scope.consequences = [];
    var load = function () {
        deviationService().getTranslationsWithConsequences().then(function (consequences) {
            $scope.consequences = consequences;
        });
    }
    load();
    $scope.edit = function (item) {
        $translate('COMMON_EDIT').then(function (title) {
            $scope.actionTitle = title;
        }, function (translationId) {
            $scope.headline = translationId;
        });
        $scope.registration = {
            isNew: false,
            id: item.id,
            title: item.title,
            unit: item.unit,
            description: item.description,
            customLanguageLabels: item.customLanguageLabels
        };
        $scope.selectedCustomLanguageLabel = $scope.registration.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
        if(!$scope.selectedCustomLanguageLabel) $scope.selectedCustomLanguageLabel = {};
    }

    $scope.save = function () {
        $scope.message = "";
        if ($scope.registration.isNew) {
            if(!$scope.registration.title){
                $scope.selectedLanguage = defaultConfig; //reset back top default setting
                $scope.message = $translate.instant('ADMIN_DATA_FIELD_MISSING') + ": " + $translate.instant('DEVIATION_CONSEQUENCE');
                return;
            }
            deviationService().createConsequence($scope.registration).then(function () {
                $translate('ADMIN_MESSAGE_CONSEQUENCE_CREATED').then(function (msg) {
                    toaster.success(msg);
                }, function (translationId) {
                    $scope.headline = translationId;
                });
                load();
                $scope.clearActive();
            });
        } else {
            deviationService().updateConsequence($scope.registration).then(function () {
                $translate('ADMIN_MESSAGE_CONSEQUENCE_UPDATED').then(function (msg) {
                    toaster.success(msg);
                }, function (translationId) {
                    $scope.headline = translationId;
                });
                load();
            });
        }
    }

}]);