import * as assetService from "@common/services/assetService"
import * as boardService from "@common/services/boardService"
import toaster from "@common/components/toast"

var DigiLean =  angular.module('DigiLean');  DigiLean.controller('projectPortfolioAdminController', ['$scope', '$stateParams', '$translate', '$filter', '$uibModal', '$state','projectService', 'modalService',
function ($scope, $stateParams, $translate, $filter, $uibModal, $state,projectService, modalService) {
    $scope.boards = [];
    $scope.activeboards = [];

    $scope.restore = function (board) {
        $translate(['COMMON_CANCEL', 'ADMIN_RESTORE', 'ADMIN_RESTORE_BOARD_DESCRIPTION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.ADMIN_RESTORE,
                headerText: translations.ADMIN_RESTORE + ' ' + board.name + '?',
                bodyText: translations.ADMIN_RESTORE_BOARD_DESCRIPTION
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                boardService.restore(board.id).then(function (data) {
                    loadBoards();
                    getActiveBoards();
                    $translate('ADMIN_MESSAGE_BOARD_RESTORED').then(function (msg) {
                        toaster.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                });
            });
        });
    }

    $scope.delete = function (board) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD_PERMANENTLY', 'ADMIN_DELETED_BOARD_PERMANENTLY_DESCRIPTION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.ADMIN_DELETED_BOARD_PERMANENTLY,
                headerText: translations.COMMON_DELETE + ' ' + board.name + '?',
                bodyText: translations.ADMIN_DELETED_BOARD_PERMANENTLY_DESCRIPTION
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                boardService.forceDelete(board.id).then(function (data) {
                    loadBoards();
                    $translate('ADMIN_MESSAGE_BOARD_DELETED').then(function (msg) {
                        toaster.info(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                });
            });

        });
    }

    var loadBoards = function () {
        projectService().getProjectPortfolioForAdmin().then(function (boards) {
            $scope.activeboards = boards;
        });
    }

    function initialize() {
        assetService.getList().then(function (assets) {
            $scope.assets = assets;
            loadBoards();
        });

    }

    initialize();






    $scope.subscribe("BoardCreated", function (board) {
        $translate('COMMON_BOARD_CREATED_SUCCESSFULLY').then(function (msg) {
            toaster.success(msg);
        }, function (translationId) {
            $scope.headline = translationId;
        });
        loadBoards();
    });

    $scope.subscribe("BoardDeleted", function (id) {
        loadBoards();
        
    });
    $scope.edit = function (board) {
        var options = {
            targetType : "Board",
            targetId : board.id,
            includeProjects:false
        }
        var modalInstance = $uibModal.open({ backdrop: 'static',
            animation: true,
            templateUrl: 'boardSettings.html',
            controller: 'boardSettingsController',
            resolve: {
                options: function () {
                    return options;
                }
            }
        });
        modalInstance.result.then(function (asset) {
            loadBoards();
        }, function () {

        });
        
    }

    $scope.open = function (board) {
        $state.go('board', { id: board.id }, { reload: false });
    }

    $scope.deleteBoard = function (board) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD', 'ADMIN_DELETED_BOARD_DESCRIPTION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.ADMIN_DELETED_BOARD,
                headerText: translations.COMMON_DELETE + ' ' + board.name + '?',
                bodyText: translations.ADMIN_DELETED_BOARD_DESCRIPTION
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                boardService.deleteBoard(board.id).then(function () {
                    $translate('ADMIN_MESSAGE_BOARD_DELETED').then(function (msg) {
                        toaster.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                    $scope.publish("SendBoardDeleted", board.id);
                });
            });

        });
    }

}]);