import * as dashboardService from "@common/services/dashboardService"
import * as boardService from "@common/services/boardService"
import toaster from "@common/components/toast"

const DigiLean =  angular.module('DigiLean')
DigiLean.controller('flexiboardTemplateAdmin', ['$scope','$translate', 'modalService',
    function ($scope, $translate, modalService) {
    $scope.templates = [];

    $scope.restore = function (board) {
        $translate(['COMMON_CANCEL', 'ADMIN_RESTORE', 'ADMIN_RESTORE_BOARD_DESCRIPTION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.ADMIN_RESTORE,
                headerText: translations.ADMIN_RESTORE + ' ' + board.name + '?',
                bodyText: translations.ADMIN_RESTORE_BOARD_DESCRIPTION
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                boardService.restore(board.id).then(function (data) {
                    loadBoards();
                    getActiveBoards();
                    $translate('ADMIN_MESSAGE_BOARD_RESTORED').then(function (msg) {
                        toaster.success(msg);
                    }, function (translationId) {
                        $scope.headline = translationId;
                    });
                });
            });
        });
    }

    var loadTemplates = function () {
        boardService.getTemplates().then(function (templates) {
            $scope.templates = templates;
        });
    }


    loadTemplates();


    $scope.subscribe("BoardTemplateCreated", function (item) {
        loadTemplates();
    });

    $scope.subscribe("BoardTemplateDeleted", function (id) {
        loadTemplates();   
    });

    $scope.deleteTemplate = function (template) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD_TEMPLATE_DESCRIPTION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.COMMON_DELETE,
                headerText: translations.COMMON_DELETE + ' ' + template.name + '?',
                bodyText: translations.ADMIN_DELETED_BOARD_TEMPLATE_DESCRIPTION
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                if (template.boardType == "Dashboard") {
                    dashboardService.deleteTemplate(template.id).then(function () {
                        $translate('ADMIN_MESSAGE_TEMPLATE_DELETED').then(function (msg) {
                            toaster.success(msg);
                        });
                        loadTemplates();
                        //$scope.publish("SendBoardDeleted", board.id);
                    });
                } else {
                    boardService.deleteTemplate(template.id).then(function () {
                        $translate('ADMIN_MESSAGE_TEMPLATE_DELETED').then(function (msg) {
                            toaster.success(msg);
                        });
                        loadTemplates();
                        //$scope.publish("SendBoardDeleted", board.id);
                    });
                }
          
            });

        });
    };

}]);