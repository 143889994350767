import * as assetService from "@common/services/assetService"
import * as dashboardService from "@common/services/dashboardService"
import * as boardService from "@common/services/boardService"
import toaster from "@common/components/toast"
import { getAllUnitsCached } from "@common/services/businessUnitService"
import { NgTableParams } from "@common/thirdparty/ng-table/ng-table"
import { getUserProfile } from "@common/stores/userStore"

var DigiLean =  angular.module('DigiLean');
DigiLean.controller('flexiboardAdminController', ['$scope', '$translate', '$uibModal', '$state', '$filter','modalService','folderService','authService','debounce',
function ($scope, $translate, $uibModal, $state, $filter, modalService, folderService, authService, debounce) {
    $scope.boards = [];
    $scope.activeboards = [];
    $scope.selectedBoardType = 'Dashboard';

    $scope.isBusinessunitAdmin = authService.hasRole("BusinessunitAdmin");
    $scope.isEnterpriseAccount = false;
    var businessUnitId = 0;
    var businessUnitFilter = null;
    $scope.isFilterBusinessUnitActive = false;
    $scope.businessUnits = []
    $scope.filter = []
    
    getAllUnitsCached().then(units => {
        if (units.length > 0) {
            $scope.businessUnits = units
        }    
        setupAllColumns()
        $scope.buildColumns()
    })

    function checkBoardType(boards){
        if(boards){
            for(var b=0; b< boards.length; b++){
                if(boards[b].boardType === null){
                    var settings = JSON.parse(boards[b].settings);
                    if(settings && settings.IsWeeklyBoard){
                        boards[b].boardType = "Weekly";        
                    } else {
                        boards[b].boardType = "Kanban";        
                    }      
                }
            }        
        }
    }

    function initialize() {
        assetService.getAllAssets(true).then(function (assets) {
            $scope.assets = assets;
            //loadBoards();
            $scope.getValues();
        });
        if($scope.isBusinessunitAdmin){
            $scope.isEnterpriseAccount = true; // BusinessunitAdmin is always enterprise
            const currentUser = getUserProfile()
            businessUnitId = currentUser.businessUnitId || 0; // Set a default value of 0 if businessUnitId is undefined      
        }
    }
    initialize();

    $scope.getValues = function () {
        $scope.buildColumns()
        $scope.tableParams = new NgTableParams({
            count: 25,
        },{
            getData: $scope.getDashboardTableValues
        })

    }
    $scope.sorting = ""
    $scope.getTableValues = function (params) {

        var sorting = [];
        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
        for (var propt in params._params.sorting) {
            sorting.push({
                property: propt,
                direction: params._params.sorting[propt]
            })
        }
        $scope.sorting = sorting
        addBusinessUnitToFilter() //add this filter here to make sure no timing issues occur
        // We will build params based on the built-in NgTableParams + our sorting array
        setFilter();
        var dbParams = {
            page: params._params.page,
            count: params._params.count,
            sorting: $scope.sorting,
            filters: $scope.filter
        }
        return boardService.getTableValues(dbParams).then(function (data) {
            $scope.totalMatchingRecords = data.total;
            if($scope.totalMatchingRecords > $scope.totalValues){ //total values does not include support user and can therefore have one value less than totalMatchingRecords
                $scope.totalValues = $scope.totalMatchingRecords
            }
            params.total(data.total) // recal. page nav controls
            return data.values

        })
    }

    $scope.toggleBoardType = function (type) {
        $scope.selectedBoardType = type;
        $scope.filter = [];
        reloadBoards();  
    }

    function reloadBoards(){
        if ($scope.selectedBoardType == "Dashboard") {
            $scope.tableParams = new NgTableParams({
                count: 25,
            },{
                getData: $scope.getDashboardTableValues
            })
        } else {
            $scope.tableParams = new NgTableParams({
                count: 25,
            },{
                getData: $scope.getTableValues
            })     
        }
    }

    $scope.getDashboardTableValues = function (params) {

        var sorting = [];
        // Build sortExpression for database to be able to deserialize, becuase NgTableParams.sorting is a dynamic object.
        for (var propt in params._params.sorting) {
            sorting.push({
                property: propt,
                direction: params._params.sorting[propt]
            })
        }
        $scope.sorting = sorting
        addBusinessUnitToFilter() //add this filter here to make sure no timing issues occur
        setFilter();
        // We will build params based on the built-in NgTableParams + our sorting array
        var dbParams = {
            page: params._params.page,
            count: params._params.count,
            sorting: $scope.sorting,
            filters: $scope.filter
        }
        return boardService.getDashboardTableValues(dbParams).then(function (data) {
            $scope.totalMatchingRecords = data.total;
            if($scope.totalMatchingRecords > $scope.totalValues){ //total values does not include support user and can therefore have one value less than totalMatchingRecords
                $scope.totalValues = $scope.totalMatchingRecords
            }
            params.total(data.total) // recal. page nav controls
            return data.values

        })
    }

    
    function addBusinessUnitToFilter(){
        $scope.isBusinessunitAdmin = authService.hasRole("BusinessunitAdmin");
        if(!$scope.isBusinessunitAdmin) return;
        const currentUser = getUserProfile()
        var businessUnitId = currentUser.businessUnitId?.toString() || "0"; //setting id equal to zero should never happen, 
        // but if it does then no users will be retrieved since the businessunitadmin does not have a valid businessunit associated with it
        var businessUnitFilter = {
            sourceColumn: "businessUnitId",
            operator: "InList",
            items: [businessUnitId]
        }
        $scope.filter.push(businessUnitFilter)
    }



    $scope.subscribe("DashboardCreated", function (board) {
        if(board){
            reloadBoards();  
        }
    });


    $scope.subscribe("BoardCreated", function (board) {
        if(board){
            reloadBoards();  
        }
    });

    $scope.subscribe("BoardDeleted", function (id) {
        reloadBoards();        
    });
    $scope.subscribe("DashboardDeleted", function (dashboard) {
        reloadBoards();         
    });
    $scope.edit = function (board) {
        var options = {
            targetType : "Board",
            targetId : board.id
        }

        if(board.boardType === "Dashboard"){
            options.targetType = "Dashboard";
        } else if(board.boardType === 'Performance'){
            options.includeCustomGroup = false;
            options.includeProjects = false;
        }
        var modalInstance = $uibModal.open({ backdrop: 'static',
            animation: true,
            templateUrl: 'boardSettings.html',
            controller: 'boardSettingsController',
            resolve: {
                options: function () {
                    return options;
                }
            }
        });
        modalInstance.result.then(function (asset) {
            reloadBoards();  
        }, function () {

        });
        
    }

    $scope.updateBusinessUnitFilter = function (updatedSourceColumn, filters) {
        businessUnitFilter = null;
        $scope.isFilterBusinessUnitActive = false;
        angular.forEach(filters, function (filter) {
            if (filter) {
                $scope.isFilterBusinessUnitActive = true;
                businessUnitFilter = filter;
            }
        });
        filterDebounce();
        reloadBoards();  
    };

    $scope.resetFilter = function () {
        $scope.filter = [];
        $scope.isSearchWordActive = false;
        $scope.q = "";
        $scope.selectedBoardType = 'Dashboard';
        $scope.isFilterBusinessUnitActive = false;
        reloadBoards(); 
    }

    $scope.searchWord = function () {
        $scope.isSearchWordActive = $scope.q ? true : false;
        filterDebounce();
        reloadBoards();
    }

    const filterDebounce = debounce(setFilter, 500, false)
        function setFilter() {

            let filter = []

            if ($scope.isSearchWordActive && $scope.q) {
                
                let itemFilter = {
                    sourceColumn: "Name",
                    operator: "MultiContains",
                    items: [$scope.q]
                }
                filter.push(itemFilter)
            }

            if($scope.selectedBoardType){
                var boardTypeFilter = {
                    sourceColumn: "BoardType",
                    operator: "InList",
                    items: [$scope.selectedBoardType]
                }
                filter.push(boardTypeFilter)
            }
            if($scope.isFilterBusinessUnitActive){
                filter.push(businessUnitFilter)
            }
            $scope.filter = filter
        }
    $scope.openBoardDesigner = function (board) {
        switch(board.boardType) {
            case "Dashboard":
                $state.go('flexiboard', { id: board.id, mode:"admin"}, { reload: false });
                break;
            case "SmartActionList":
                $state.go('board', { id: board.id, mode: "admin" }, { reload: false });
                break;
            case "Performance":
                $state.go('board', { id: board.id, mode: "admin" }, { reload: false });
                break;
            default:
                $state.go('boarddesigner', { id: board.id }, { reload: false });
        }
    }


    $scope.deleteBoard = function (board) {
        $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_DELETED_BOARD', 'ADMIN_DELETED_BOARD_DESCRIPTION']).then(function (translations) {
            var modalOptions = {
                closeButtonText: translations.COMMON_CANCEL,
                actionButtonText: translations.ADMIN_DELETED_BOARD,
                headerText: translations.COMMON_DELETE + ' ' + board.name + '?',
                bodyText: translations.ADMIN_DELETED_BOARD_DESCRIPTION
            };

            modalService.showModal({}, modalOptions).then(function (result) {
                if(board.boardType === "Dashboard"){
                    dashboardService.deleteDashboard(board.id).then(function (data) {
                        toaster.info($translate.instant("ADMIN_MESSAGE_BOARD_DELETED"));
                    });
                } else {
                    boardService.deleteBoard(board.id).then(function () {
                        toaster.info($translate.instant("ADMIN_MESSAGE_BOARD_DELETED"));
                        $scope.publish("SendBoardDeleted", board.id);
                    });
                }
                
            });

        });
    }

    function setupAllColumns(){
        $scope.allColumns = [
            {
                title: "COMMON_TYPE",
                cssClass: "",
                field: "boardType",
                visible: true,
                sortable: "boardType",
                getValue: renderValue,
                dataType: "string"
            }, 
            {
                title: "COMMON_NAME",
                cssClass: "pull-left",
                field: 'name',
                visible: true,
                sortable: 'name',
                getValue: renderValue,
                dataType: "text"
            },
            {
                title: "ADMIN_ASSETTYPE_BUSINESS_UNIT",
                cssClass: "",
                field: "businessUnitId",
                visible: $scope.businessUnits.length > 0,
                sortable: "businessUnitId",
                getValue: renderValue,
                dataType: "string"
            },
            {
                title: "",
                cssClass: "",
                field: "owner",
                visible: true,
                sortable: "owner",
                getValue: renderValue,
                dataType: "string"
            },
            {
                title: "",
                cssClass: "",
                field: "assetName",
                visible: true,
                sortable: "assetName",
                getValue: renderValue,
                dataType: "string"
            },
            {
                title: "COMMON_PUBLIC",
                cssClass: "folder-icon fal fa-globe",
                field: "isPublic",
                visible: true,
                sortable: "isPublic",
                getValue: renderValue,
                dataType: "bool"
            },
            {
                title: "COMMON_ID",
                cssClass: "",
                field: 'id',
                visible: true,
                sortable: 'id',
                getValue: renderValue,
                dataType: "number"
            }
        ]
    }

    $scope.buildColumns = function () {
        $scope.columns = $filter('filter')($scope.allColumns, { visible: true });
    }

    function renderValue($scope, row) {
        const item = this 
        const field = item.field
        const value = row[field]
        if (this.dataType === "date") {
            return $filter('date')(value, "mediumDate")
        }
        if (this.dataType === "timestamp") {
            return $filter('date')(value, "dd.MM.yyyy HH:mm:ss")
        }
        if (this.dataType === "bool") {
            if (value === "true") {
                return "<i class='far fa-check-square'></i>"
            } else {
                return ""
            }
        }
        if (this.dataType === "type") {
            if (value === "Dashboard") {
                return "<a ui-sref='flexiboard({id:board.id})'><i class='fal fa-desktop'></i> {{ "+ item.name + "}}</a><br><small style='margin-left:18px'>{{board.description}}</small>" 
            } else if (value === "Performance") {
                return "<i class='far fa-chart-bar'></i>"
            } else if (value === "SmartActionList") {
                return "<i class='far fa-list-alt'></i>"
            }
        }
        return value
    }

}]);