import { saveArrayBufferAsPdf } from "@common/services/filesService"
import { userProfile } from "@common/stores/userStore"
import toaster from "@common/components/toast"
import backend, { BackendHttpError } from "@common/services/backendHttp"
import { translateLabel, translateLabelInstant } from "./language/DigiLeanLang"
import { FileInfo, TableParams, TimePeriod } from "@api"

const resourceUrl  = "pdf"

let lang = "en"
userProfile.subscribe(up => {
    if (up.preferredLanguage)
        lang = up.preferredLanguage
})

export async function printA3 (fileName: string, a3VersionId: number, language: string, timezoneOffset: number) {
    const url = resourceUrl + "/printA3/" + a3VersionId + "?lang=" + language + "&timezoneOffset=" + timezoneOffset
    await downloadPdf(url, fileName)
}

export async function saveA3(a3VersionId: number, language: string, timezoneOffset: number, format: string) {
    const pdfLabel = translateLabelInstant("COMMON_GENERATING_PDF")
    const pdfCompletedlabel = translateLabelInstant("COMMON_GENERATING_PDF_DONE")
    const toast = toaster.loading(`${pdfLabel} A3`)
    const url = resourceUrl + "/savea3/" + a3VersionId + "?lang=" + language + "&timezoneOffset=" + timezoneOffset + "&format=" + format
    try {
        const res = await backend.get<FileInfo>(url)
        toaster.success(`A3 ${pdfCompletedlabel}`)
        return res
    }
    catch(error: any) {
        let errorLabel = translateLabelInstant("ADMIN_DATA_JOB_SHOWERRORS")
        if (error instanceof BackendHttpError && error.message)
            errorLabel = translateLabelInstant(error.message)
        
        toaster.error(`${pdfLabel} A3: ${errorLabel}`)
        throw new Error("Exception occured")
    }
    finally {
        toast.destroyNow()
    }
}

export function previewA3(a3VersionId: number, language: string) {
    const url = resourceUrl + "/previewA3/" + a3VersionId + "?lang=" + language
    return backend.post<null>(url, {})
}

export async function printLearningPage(pageId: number, pageTitle: string, isExternal: boolean) {
    if (!pageTitle)
        pageTitle = "page"
    const fileName = pageTitle + ".pdf"
    

    const url = "learningpage/" + pageId+ "?lang=" + lang + "&isExternal=" + isExternal
    await downloadPdf(url, fileName)
}

export async function printDeviationReport(deviationId: number) {
    const fileName = "deviation-report.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/deviationreport/" + deviationId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset

    await downloadPdf(url, fileName)
}

                 
export async function printDeviationProjectReport(projectId: number, timePeriod: TimePeriod) {
    const fileName = "deviation-report.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/DeviationProjectReport/" + projectId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset
    await downloadPdf(url, fileName, timePeriod)
}

export async function printProjectHoursReport(projectId: number, timePeriod: TimePeriod) {
    const fileName = "invoice-report.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/InvoiceProjectReport/" + projectId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset
    await downloadPdf(url, fileName, timePeriod)
}

export async function createImprovementListReport(params: TableParams) {
    const fileName = "DigiLEAN - Improvements.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/ImprovementListReport?lang=" + lang + "&timezoneOffsetInMinutes=" + offset
    await downloadPdf(url, fileName, params)
}

export async function createDeviationListReport(params: TableParams) {
    const fileName = "DigiLEAN - Deviations.pdf"
    
    const date = new Date()
    const offset = date.getTimezoneOffset()

    const url = resourceUrl + "/DeviationListReport?lang=" + lang + "&timezoneOffsetInMinutes=" + offset
    await downloadPdf(url, fileName, params)
}

export async function createSmartActionListReport(boardId: number) {
    var fileName = "DigiLEAN - Smart Actions.pdf";
    var date = new Date();
    var offset = date.getTimezoneOffset()
    let url = resourceUrl + "/CreateSmartActionListReport/" + boardId + "?lang=" + lang + "&timezoneOffsetInMinutes=" + offset;
    await downloadPdf(url, fileName)
}


async function downloadPdf(url: string, fileName: string, params: any = {}) {
    const pdfLabel = await translateLabel("COMMON_GENERATING_PDF")
    const toast = toaster.loading(`${pdfLabel} '${fileName}'`)
    try {
        
        const data = await backend.post<ArrayBuffer>(url, params)
        saveArrayBufferAsPdf(data, fileName)
        
        toaster.success(translateLabelInstant("COMMON_GENERATING_PDF_DONE"), 5)
    }
    catch(error: any) {
        let errorLabel = translateLabelInstant("ADMIN_DATA_JOB_SHOWERRORS")
        if (error instanceof BackendHttpError && error.message)
            errorLabel = translateLabelInstant(error.message)
        
        toaster.error(`${pdfLabel}: ${errorLabel}`)
    }
    finally {
        toast.destroyNow()
    }
}