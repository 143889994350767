/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IncidentStatus {
    New = 0,
    InProgress = 10,
    Resolved = 20,
    Rejected = 30,
}
