import { getUserProfile } from "@common/stores/userStore"
import * as pdfService from "@common/services/pdfService"

angular
    .module('DigiLean')
    .controller("a3previewController", ['$scope', '$filter', '$uibModal', '$state', '$stateParams', '$translate', '$window', 'a3Service',
        function ($scope, $filter, $uibModal, $state, $stateParams, $translate, $window, a3Service) {

            $scope.a3Id = parseInt($stateParams.id);
            $scope.a3 = {};
            $scope.a3info = null;
            $scope.versionNumber = parseInt($stateParams.version);
            $scope.canEdit = false;
            $scope.width = 2338;
            $scope.height = 1654;
            $scope.zoom = 0.6;
            $scope.version = {};
            $scope.isAdminMode = false;
            $scope.defaultLayout = null;

            var w = angular.element($window);
            adjustZoom(w[0].innerWidth);
            w.bind('resize', function (event) {
                adjustZoom(event.currentTarget.window.innerWidth);
                scrollToTop();
            });


            function adjustZoom(windowWidth) {

                $scope.zoom = windowWidth / ($scope.width + 700);
            }


            function scrollToTop() {
                setTimeout(function () {
                    var body = document.getElementsByTagName('body')[0];
                    body.scrollTop = 0;
                }, 20);
            }



            $scope.save = function () {
                var a3infoWidget = $scope.widgets.filter(w => w.component == "a3info").length > 0;
                var layout = !a3infoWidget ? [$scope.a3info] : [];

                angular.forEach($scope.widgets, function (widget) {
                    var stepLayout = {
                        header: widget.title,
                        component: widget.component,
                        row: widget.row,
                        col: widget.col,
                        sizeX: widget.sizeX,
                        sizeY: widget.sizeY,
                        a3StepId: widget.a3StepId,
                        a3VersionId: $scope.version.id,
                    }
                    layout.push(stepLayout);
                });
                a3Service().saveLayout($scope.version.id, layout).then(function () {
                    $scope.print(true)
                });
            }

            a3Service().canEdit($scope.a3Id).then(function (canEdit) {
                $scope.hasEditRight = canEdit;
                $scope.canEdit = canEdit && $scope.isAdminMode;
                $scope.gridsterOptions = {
                    margins: [25, 25],
                    columns: 24,
                    resizable: {
                        enabled: $scope.canEdit,
                        handles: ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'],

                        resize: function (event, element, widget) {
                            var froalaEl = element.find("div.fr-view");
                            froalaEl.height(element.height() - 115);
                        },
                        stop: function (event, element, widget) {
                            $scope.save();
                        }
                    },
                    draggable: {
                        enabled: $scope.canEdit, // whether dragging items is supported
                        handle: 'h4.preview-header, div#a3info.drag-box, div.drag-box', // optional selector for drag handle
                        stop: function (event, $element, widget) {
                            $scope.save();
                        }
                    }
                };

            });

            function handleResizing() {
                $scope.widgets.forEach(w => {
                    var widgetEl = $("#widget" + w.id).find("#a3step");

                    var froalaEl = widgetEl.find("div.fr-view");

                    if (froalaEl.length > 0) {
                        var height = widgetEl[0].clientHeight
                        froalaEl.height(height - 115);
                    }
                });
            }

            $scope.widgets = [];


            function defaultLayout(a3) {

                var stepsWithoutProjectInfo = $scope.version.steps.filter(f => !f.isProjectInfo);
                var layoutPositions = stepsWithoutProjectInfo.length;

                var version = $scope.version;
                angular.forEach($scope.version.steps, function (step, key) {
                    step.selected = true;
                    var stepType = "a3step";
                    var model = step;
                    if (step.isActionList && !step.isProjectInfo) {
                        var actionPlan = {
                            id: step.id,
                            title: step.title,
                            a3: a3,
                            step: step
                        }
                        stepType = "a3step-action-plan";
                        model = actionPlan;
                    }
                    if (step.isProjectInfo) {
                        model = a3.project;
                        stepType = 'cost';
                        var cost = null;
                        if (step.col && step.row)
                            cost = getStepWidget(stepType, step, step.row, step.col, step.sizeX, step.sizeY);
                        else
                            cost = getStepWidget(stepType, step, [findMaxRow() + 10, 0, 24, 3]);
                        $scope.widgets.push(cost);
                        return;
                    }

                    $scope.widgets.push(getStepWidget(stepType, model, $scope.defaultLayout[layoutPositions - 1][key]));

                });
                $scope.a3info = getStepWidget("a3info", a3, [0, 0, 24, 0]);
                $scope.widgets.push($scope.a3info);

            }

            a3Service().getDefaultLayout().then(function (result) {
                $scope.defaultLayout = result;
                a3Service().get($scope.a3Id).then(function (a3) {
                    $scope.a3 = a3;
                    $scope.version = $filter('filter')(a3.versions, { version: $scope.versionNumber })[0];

                    $scope.version.steps.sort((a, b) => {
                        return a.title.localeCompare(b.sortOrder);
                    });

                    a3Service().getVersion($scope.version.id).then(function (version) {
                        if (version.layout.length === 0) {
                            defaultLayout(a3);
                            $scope.save();
                            return;
                        }

                        $scope.a3info = getStepWidget("a3info", a3, [0, 0, 24, 0]);

                        angular.forEach(version.layout, function (widget, key) {
                            // TODO need to resolve the correct step, maybe som database changes too....
                            switch (widget.component) {

                                case "a3step":
                                    widget.model = getStepForId(widget.a3StepId);
                                    break;
                                case "a3step-action-plan":
                                    var step = getStepForId(widget.a3StepId);
                                    var actionPlan = {
                                        title: step.title,
                                        a3: a3,
                                        step: getStepForId(widget.a3StepId)
                                    }
                                    widget.model = actionPlan;
                                    break;
                                case "cost":
                                    widget.model = a3.a3Project ? a3.a3Project : {};
                                    break;

                            }

                            $scope.widgets.push(widget);

                            selectStep(true, widget);

                        });

                        scrollToTop();
                    });
                });
            });

            function getStepForId(stepid) {
                var step = $filter('filter')($scope.version.steps, { id: stepid })[0];
                return step;
            }

            function getStepWidget(component, model, position) {
                model.versionNumber = $scope.versionNumber;
                var widget = {
                    model: model,
                    header: model.title,
                    component: component,
                    row: position[0],
                    col: position[1],
                    sizeX: position[2],
                    sizeY: position[3],
                    a3StepId: component !== "a3info" ? model.id : null
                }

                return widget;
            }

            var selectStep = function (selected, widget) {
                var stepFromCollection = $scope.version.steps.filter(x =>
                    (widget.model && x.id === widget.model.id)
                    || (widget.model && widget.model.step && x.id === widget.model.step.id)
                    || x.isProjectInfo && widget.component === "cost")[0];
                if (stepFromCollection) {
                    stepFromCollection.selected = selected;
                }
            }

            function findMaxRow() {
                var max = 0;
                $scope.widgets.forEach(x => {
                    if (x.row > max)
                        max = x.row;
                });
                return max;
            }

            $scope.remove = function (widget, databound) {
                let index = $scope.widgets.indexOf(widget);
                if (index >= 0) {
                    $scope.widgets.splice(index, 1);
                    $scope.save();
                    if (!databound) {
                        selectStep(false, widget);
                    }
                }
            }

            $scope.onStepSelected = function (step, event) {
                if (step.selected) {
                    if (step.isProjectInfo) {
                        $scope.widgets.push(getStepWidget('cost', $scope.a3.project ? $scope.a3.project : {}, [findMaxRow() + 10, 0, 24, 2]));
                    }
                    else if (step.isActionList) {
                        var actionPlan = {
                            id: step.id,
                            title: step.title,
                            a3: $scope.a3,
                            step: step
                        }
                        $scope.widgets.push(getStepWidget('a3step-action-plan', actionPlan, [findMaxRow() + 4, 0, 12, 4]));
                    }
                    else {
                        $scope.widgets.push(getStepWidget('a3step', step, [findMaxRow() + 4, 0, 12, 4]));
                    }
                    $scope.save();
                }
                else {
                    let widget = $scope.widgets.filter(x =>
                        (x.model && step.id === x.model.id)
                        || (x.model && x.model.step && step.id == x.model.step.id)
                        || step.isProjectInfo && x.component === "cost")[0];
                    $scope.remove(widget, true);
                }

            };

            $scope.print = function (previewOnly = false, format = 'default') {
                var lang = "en"
                const up = getUserProfile()
                if (up.preferredLanguage) {
                    lang = up.preferredLanguage
                }
                // Replace . with - so filename is not corrupt in browser.
                var pdfTitle = $scope.a3.title.replace(/\./g, '-') // Crazy stuff=>https://stackoverflow.com/questions/1967119/why-does-javascript-replace-only-first-instance-when-using-replace
                if (!previewOnly) {
                    //$scope.save(); Don't understand why we need a new save in pdf generation

                    var date = new Date();
                    var offset = date.getTimezoneOffset()
                    
                    pdfService.saveA3($scope.version.id, lang, offset, format).then(function (res) {
                        if (res.fileUrl) {
                            window.open(res.fileUrl, "_blank")
                        }
                    }, () => {})
                }
                else {
                    pdfService.previewA3($scope.version.id, lang);
                }

            }
            $scope.zoomIn = function () {
                $scope.zoom = $scope.zoom + 0.01;
            };
            $scope.zoomOut = function () {
                $scope.zoom = $scope.zoom - 0.01;
            };

            $scope.adminMode = function () {
                $scope.isAdminMode = true;
                $scope.canEdit = true;
                $scope.gridsterOptions.resizable.enabled = true;
                $scope.gridsterOptions.draggable.enabled = true;

                setTimeout(handleResizing, 0);

            };

            $scope.viewMode = function () {
                $scope.isAdminMode = false;
                $scope.canEdit = false;

                $scope.gridsterOptions.resizable.enabled = false;
                $scope.gridsterOptions.draggable.enabled = false;
            };
            $scope.clickGridsterItem = function (widget, e) {

                if (widget.component === 'a3info') {
                    e.preventDefault();
                    e.stopPropagation();

                }
            };


        }]);
