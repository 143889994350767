import { LayoutMode, LayoutOption, LayoutOptionConfig, getLayoutConfig } from "../flexiboardLayoutService"
import angular from "angular"
import "gridstack/dist/gridstack.min.css"
import { GridStack, GridStackOptions, GridStackWidget } from "gridstack"
import { NgComponent, DigiLeanNgScope } from "@common/model/angularModel"
import { DashboardWidget, UserAccessLevel } from "@api"
import { PageService } from "./pageService"
import { debounce } from "lodash"
import "./flexiboardPageContainer.css"
import toaster from "@common/components/toast"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"

// https://stackoverflow.com/questions/37420381/angularjs-compile-a-directive-with-an-object-as-an-attribute-parameter

export interface PageViewGridStackCtrl extends NgComponent {
    isAdminMode: boolean
    assetId?: string
    userAccess?: UserAccessLevel
    layoutFactor: number
    layoutMode: LayoutMode
    isDebugMode: boolean
}

const DigiLean = angular.module('DigiLean')
DigiLean.component('flexiboardPageContainer', 
    {
        template: '<div id="digilean-grid-stack-container"></div>',
        bindings: {
            'isAdminMode': '<',
            'assetId': '<',
            'userAccess': '<',
            'layoutFactor': '<',
            'layoutMode': '<',
            'isDebugMode': '<',
            'dashboardTitle': '<',
            'pageTitle': '<'
        },
        controller: ['$element', '$scope', '$compile', '$uibModal',
            function(this: PageViewGridStackCtrl, $element: JQuery, $scope: DigiLeanNgScope, $compile, $uibModal) {
                let $ctrl = this
                
                const gridOptions: GridStackOptions = {
                    column: 40, // can't be changed without changing the CSS
                    minRow: 20,
                    maxRow: 20,
                    margin: 0,
                    alwaysShowResizeHandle: false,
                    float: true, // true means that widget will stay excactly where you place them, false will make them "float up"
                    handle: ".widget-drag-handle",
                    resizable: {
                        autoHide: false,
                        handles: "e,s,n,w,se,sw,nw"
                    }
                }

                let pageService: PageService | null = null
                let gridstack: GridStack | null = null
                let gridEl: HTMLDivElement | null = null

                const gridObserver = new MutationObserver((mutationList: MutationRecord[]) => {
                    for (const mutation of mutationList) {
                        if (mutation.type === "attributes" && mutation.attributeName === "gs-current-row") {
                            getAndSetCurrentHeight()
                        }
                    }
                })

                function layoutChanged() {
                    console.log("layoutChanged")
                    if (!$ctrl.layoutMode || !$ctrl.layoutMode.option)
                        return
                    
                    console.log("layoutChanged", $ctrl.layoutMode)
                    const layoutConfig = getLayoutConfig($ctrl.layoutMode)
                    setLayoutConfig(layoutConfig)
                }

                function setLayoutConfig(config: LayoutOptionConfig) {
                    
                    gridOptions.maxRow = config.maxRows

                    // POC default mode minRows depending on adminMode
                    if (config.option == LayoutOption.Default && $ctrl.isAdminMode)
                        gridOptions.minRow = 200 // config.maxRows
                    else
                        gridOptions.minRow = config.minRows
                    
                    initializeGridStackDebounced()
                }

                function setAdminOrViewMode() {
                    if ($ctrl.isAdminMode)
                        setAdminMode()
                    else
                        setViewMode()
                }

                function getAndSetCurrentHeight() {
                    if (!gridEl)
                        return
                    const currentRow = gridEl.getAttribute("gs-current-row")
                    if (currentRow) {
                        // console.log("gs-current-row", currentRow)
                        let gridContainer = getContainer()
                        gridContainer.style.setProperty("--gs-current-row", currentRow)
                        syncGridMarkingLines(currentRow)
                    }
                }

                // create a marker for each 10th row in the grid in admin mode
                function syncGridMarkingLines(currentRow: string) {
                    const rowsTotal = parseInt(currentRow)
                    if (!rowsTotal || rowsTotal < 10)
                        return

                    const numberOfMarkingLines = Math.floor(rowsTotal/10)
                    let gridContainer = getContainer()
                    if (!gridContainer)
                        return

                    const currentMarkingLines = gridContainer.querySelectorAll("div.grid-marking-line")
                    if (currentMarkingLines.length === numberOfMarkingLines)
                        return

                    currentMarkingLines.forEach((el) => {
                        gridContainer.removeChild(el)
                    })
                    for (let i = 1; i <= numberOfMarkingLines; i++) {
                        const markingLine = document.createElement("div")
                        markingLine.classList.add("grid-marking-line")
                        const row = `${i * 10}`
                        markingLine.innerHTML = row
                        markingLine.style.setProperty("--grid-marking-row", row)
                        gridContainer.appendChild(markingLine)
                    }
                }

                function getContainer() {
                    let parentElement = $element[0]
                    return parentElement.querySelector("#digilean-grid-stack-container") as HTMLDivElement
                }
                function setAdminMode() {
                    gridstack?.enable()
                }
                function setViewMode() {
                    gridstack?.disable()
                }
                
                const nativeFullScreenApps = ["webviewer", "pdfdisplayer"]
                $scope.openAppInFullScreen = async function (widget: DashboardWidget) {

                    if (widget.settings) {
                        const settingsType = typeof widget.settings
                        if (settingsType == "string")
                            widget.settings = JSON.parse(widget.settings)
                    }

                    const widgetEl = $element[0].querySelector(`#widget${widget.id}`)

                    // allow native fullscreen for specific apps. Needs widget element
                    if (widgetEl && nativeFullScreenApps.includes(widget.component)) {
                        try {
                            await widgetEl.requestFullscreen({ navigationUI: "show" })
                            return
                        }
                        catch (err) {
                            console.log("error opening fullscreen", err)
                        }
                    }

                    
                    // default back to fullscreen dialog
                    const options = {
                        widgetEl,
                        widget: widget,
                        assetId: $ctrl.assetId,
                        title: $ctrl.dashboardTitle,
                        subTitle: $ctrl.pageTitle,
                        userAccess: $ctrl.userAccess
                    }
                    const modalInstance = $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        windowClass: 'full-screen',
                        templateUrl: 'fullscreenWidget.html',
                        controller: 'fullscreenWidgetController',
                        resolve: {
                            options: function () {
                                return options
                            }
                        }
                    })
    
                    modalInstance.result.then((app) => { }, (err) => { })
                }

                $ctrl.$onInit = function() {
                    copyValuesToScrope()
                    layoutChanged()
                    initializeGridStackDebounced()
                    setAdminOrViewMode()
                }

                const initializeGridStackDebounced = debounce(initializeGridStack, 1000)
                function initializeGridStack() {
                    console.log(`flexiboardPageContainer: initializeGridStack: Start. Layout: minRows=${gridOptions.minRow}, maxRows=${gridOptions.maxRow}`)
                    // first time
                    if (!gridstack) {
                        console.log("flexiboardPageContainer: initializeGridStack: First time, create grid")
                        let gridContainer = getContainer()
                        gridContainer.innerHTML = "" // remove all previous elements
                        gridEl = document.createElement("div") // create brand new element, important when switching layout mode
                        gridEl.setAttribute("id", "digilean-grid-stack")
                        gridContainer.appendChild(gridEl)
                        
                        gridObserver.observe(gridEl, {attributes: true})
                        gridstack = GridStack.init(gridOptions, gridEl)
                        
                        pageService = new PageService($scope, $compile, gridstack)
                        $scope.$emit("grid-stack-loaded", pageService)
                    }
                    else {
                        console.log("flexiboardPageContainer: initializeGridStack: Only adjust layout")
                        // just reset layout rows without reloading widgets
                        gridstack.opts.minRow = gridOptions.minRow
                        gridstack.opts.maxRow = gridOptions.maxRow
                        gridstack.engine.maxRow = gridOptions.maxRow!
                        
                        // do something to make the grid apply the new row settings
                        const dummyWidget: GridStackWidget = { w: 1, h: 1, id: "dummy"}
                        gridstack.willItFit(dummyWidget)
                        const gridItem = gridstack.addWidget(dummyWidget)
                        if (gridItem)
                            gridstack.removeWidget(gridItem)
                    }
                    setAdminOrViewMode()
                    
                }

                function copyValuesToScrope() {
                    $scope.isAdminMode = $ctrl.isAdminMode
                    $scope.layoutFactor = $ctrl.layoutFactor
                    $scope.userAccess = $ctrl.userAccess ?? ""
                    $scope.assetId = $ctrl.assetId ?? 0
                    $scope.isDebugMode = $ctrl.isDebugMode ?? false
                }
                $ctrl.$onChanges = function (changes) {
                    copyValuesToScrope()
                    if (changes.layoutMode) {
                        if (changes.layoutMode.currentValue)
                            layoutChanged()
                    }
                    if (changes.isAdminMode) {
                        // POC default mode minRows depending on adminMode
                        if ($ctrl.layoutMode.option == LayoutOption.Default)
                            layoutChanged()
                        else
                            setAdminOrViewMode()
                    }
                }

                $scope.removeWidget = function(uid: string) {
                    const widget = pageService?.findWidgetUid(uid)
                    if (!widget) {
                        console.warn("Widget was to be removed but not found by id", uid)
                        return
                    }
                    var deleteSmartActionList = false;
                    // Check for extra confirmation regarding linked boards
                    if (widget.component == 'smartactionlist') {
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'widgetDeleteConfirmation.html',
                            controller: 'widgetDeleteConfirmation',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                widget: function () {
                                    return widget;
                                }
                            }
                        });
                        modalInstance.result.then(function (answer) {
                            if (answer == "all") deleteSmartActionList = true
                            pageService?.removeWidgetBackend(widget, deleteSmartActionList)
                        }, function () {
                            return
                        })
                    } else {
                        pageService?.removeWidgetBackend(widget, false)
                    }
                }
    
                $scope.cloneApp = async function(uid: string) {
                    if (uid) {
                        try {
                            await pageService?.cloneWidget(uid)
                        }
                        catch (err: any) {
                            toaster.error(translateLabelInstant(err.message))
                        }
                    }
                }
                $scope.changeWidget = async function(uid: string) {
                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        templateUrl: 'appstore.html',
                        controller: 'appstoreController',
                        windowClass: 'newdeviation-modal-window',
                    });
    
                    const result = await modalInstance.result
                    pageService?.changeWidget(uid, result.component, result.name)
                }

                $ctrl.$onDestroy = function() {
                    pageService?.destroy()
                    gridObserver.disconnect()
                    gridstack?.destroy(true)
                    gridstack = null
                    pageService = null
                }
            }
        ]
    }
)