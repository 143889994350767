import "./jobScheduleDialog"
import * as connectorAdminService from "@common/services/admin/connectorAdminService"
import angular from "angular"
import type { DataSource, Job, JobPart } from "@api"
import toaster from "@common/components/toast"
import dialog from "@common/components/digilean/DigileanDialog"
import { NgComponent } from "@common/model/angularModel"

const DigiLean = angular.module('DigiLean')

interface JobAdminComponent extends NgComponent {
    job?: Job
    selectedDataSource: Partial<DataSource>
}

DigiLean.component('jobAdmin', {
    templateUrl: "jobAdmin.html",
    controller: ['$scope', '$stateParams', '$translate', 'dataSourceService', '$uibModal',
        function(this: JobAdminComponent, $scope, $stateParams, $translate, dataSourceService, $uibModal) {
            let $ctrl = this
            let jobId = parseInt($stateParams.id)
            
            $ctrl.selectedDataSource = { id: 0}
            $ctrl.selectDataSource = function() {
                if ($ctrl.job)
                    $ctrl.job.dataSourceId = $ctrl.selectedDataSource.id
            }
            $ctrl.jobTypes = []
            $ctrl.dataSources = []
    
            $scope.subscribe("JobUpdated", async function (job: Job) {
                const msg = await $translate('ADMIN_MESSAGE_JOB_UPDATED')
                toaster.info(msg)
            })

            const loadData = async () => {
                const job = await connectorAdminService.getJob(jobId)
                $ctrl.job = job
                
                if (job.dataSourceId)
                    $ctrl.selectedDataSource.id = job.dataSourceId
                
                const jobtypes = await connectorAdminService.getJobTypes()
                $ctrl.jobTypes = jobtypes
    
                const dataSources = await dataSourceService().getList()
                $ctrl.dataSources = dataSources
            }
    
            $ctrl.$onInit = function() {
                loadData()
            }

            $ctrl.updateJob = async () => {
                if (!$ctrl.job)
                    return
                const jobUpdated = await connectorAdminService.updateJob($ctrl.job)
                $scope.publish('SendJobUpdated', jobUpdated)
                $ctrl.job = jobUpdated
            }
            $ctrl.formatError = function(error) {
                if (error.required && error.required.length > 0) {
                    const fieldsRequired = error.required.map(field => field.$name)
                    return "these fields are required: " + fieldsRequired.join(',')
                }
                return ""
            }

            $ctrl.openTemplateDialog = async () => {
                if (!$ctrl.job)
                    return
                const modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'jobScheduleDialog.html',
                    controller: 'jobScheduleDialogController',
                    resolve: { cronExpression: () => $ctrl.job!.cronExpression }
                })
                try {
                    const res = await modalInstance.result.catch((er: any) => console.log(er))
                    $ctrl.job.cronExpression = res.cronExpression
                } catch (e) {}
            }
            $ctrl.newJobPart = async () => {
                let jobPart: JobPart = {
                    enabled: true
                }
                
                try {
                    const res = await dialog.openForm({
                        title: "ADMIN_DATA_NEW_JOBPART",
                        okBtnText: "COMMON_SAVE",
                        booleanResult: false
                    }, "<edit-jobpart-dialog></edit-jobpart-dialog>", jobPart)
    
                    jobPart.config = res.config
                    jobPart.name = res.name
                    jobPart.source = res.source
                    jobPart.jobPartType = res.jobPartType
    
                    const job = structuredClone($ctrl.job) as Job
                    if (!job.jobParts || !Array.isArray(job.jobParts))
                        job.jobParts = []
                    job.jobParts.push(jobPart)
                    $ctrl.job = job
                    $ctrl.updateJob()
                }
                catch(err) {
                    if (err != "cancel")
                        console.log(err)
                }
            }
            $ctrl.onJobPartUpdate = (e: CustomEvent) => {
                const jobpartUpdated = e.detail as JobPart
                const job = structuredClone($ctrl.job) as Job
                if (jobpartUpdated.id && job.jobParts) {
                    let jobparts = job.jobParts as JobPart[]
                    const index = jobparts.findIndex(j => j.id === jobpartUpdated.id)
                    if (index > -1) {
                        jobparts[index] = jobpartUpdated
                    }
                    $ctrl.job = job
                    $ctrl.updateJob()
                }
            }
        }
    ]
})
