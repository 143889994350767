var DigiLean = angular.module('DigiLean');
DigiLean.controller('productionBoardRowConfigurationController', ['$scope', '$uibModalInstance', '$uibModal','$filter', 'configuration',
    function ($scope, $uibModalInstance, $uibModal, $filter,configuration) {
        $scope.settings = configuration;

        //Relevant for dataserie configuration
        $scope.formula = 'sum';

        $scope.thirddecision = null;

        $scope.operators = [
            { dbCode: ">=", html: "&gt;=" },
            { dbCode: ">", html: "&gt;" },
            { dbCode: "<=", html: "&lt;=" },
            { dbCode: "<", html: "&lt;" },
        ];
        $scope.operator = $scope.operators[1];

        function init() {
            if ($scope.settings) {
                $scope.name = $scope.settings.name;
                $scope.dataSourceId = $scope.settings.dataSourceId;
                $scope.dataSource = $scope.settings.dataSource;
                $scope.filters = $scope.settings.filters;
                $scope.targetDataSourceId = $scope.settings.targetDataSourceId;
                $scope.targetDataSource = $scope.settings.targetDataSource;
                $scope.targetFilters = $scope.settings.targetFilters;
                $scope.targetValue = $scope.settings.targetValue;
                if($scope.settings.formula){
                    $scope.formula = $scope.settings.formula;
                }
                if($scope.settings.operator){
                    var operator = $filter('filter')($scope.operators, { dbCode: $scope.settings.operator.dbCode }, true);
                    if (operator.length > 0) {
                        $scope.operator = operator[0];
                    }
                }

                if($scope.targetDataSourceId){
                    $scope.thirddecision = "datasourceTarget";
                } else if($scope.targetValue !== null && $scope.targetValue !== undefined) { //$scope.targetValue = 0 is a valid value
                    $scope.thirddecision = "manualTarget";
                }
            }
        }
        init();

        $scope.updateName = function (name){
            $scope.name = name;
        };

        $scope.updateTargetValue = function (targetValue){
            $scope.targetValue = targetValue;
            $scope.targetDataSource = null;
        };

        $scope.operatorChanged = function (operator){
            $scope.operator = operator;
        };

        
        $scope.selectDataSource = function () {
            var hasDataSerie = false;
            if ($scope.dataSource) {
                hasDataSerie = true;
            }
            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'dataSourceSingleSelector.html',
                controller: 'dataSourceSingleSelector',
                windowClass: 'newdeviation-modal-window',
                resolve: {
                    hasDataSerie: function () {
                        return hasDataSerie;
                    },
                    dataSource: function () {
                        return $scope.dataSource;
                    },
                    filters: function () {
                        return $scope.filters;
                    },
                    withTarget: function () {
                        return false;
                    },
                    type: function () {
                        return null;
                    },
                    externalOnly: function () {
                        return true;
                    }
                }
            });

            modalInstance.result.then(function (result) {
                $scope.dataSource = result.dataSource;
                $scope.filters = result.filters;
                if (!$scope.name) {
                    $scope.name = $scope.dataSource.title;
                }
            });
        };

        $scope.selectTargetDataSource = function () {
            var hasDataSerie = false;
            if ($scope.targetDataSource) {
                hasDataSerie = true;
            }
            var modalInstance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                templateUrl: 'dataSourceSingleSelector.html',
                controller: 'dataSourceSingleSelector',
                windowClass: 'newdeviation-modal-window',
                resolve: {
                    hasDataSerie: function () {
                        return hasDataSerie;
                    },
                    dataSource: function () {
                        return $scope.targetDataSource;
                    },
                    filters: function () {
                        return $scope.targetFilters;
                    },
                    withTarget: function () {
                        return false;
                    },
                    type: function () {
                        return null;
                    },
                    externalOnly: function () {
                        return true;
                    }
                }
            });

            modalInstance.result.then(function (result) {
                $scope.targetDataSource = result.dataSource;
                $scope.targetFilters = result.filters;
            });
        };

        $scope.changeFormula = function (newFormula) {
            $scope.formula = newFormula;
        }

        function isInputOk() {
            $scope.nameMissing = false;
            $scope.dataSourceMissing = false;
            $scope.targetMissing = false;
            if (!$scope.name || $scope.name == '') {
                $scope.nameMissing = true;
            }

            if (!$scope.dataSource) {
                $scope.dataSourceMissing = true;
            }
            if (!$scope.targetDataSource && ($scope.targetValue === null || $scope.targetValue === undefined)) {
                $scope.targetMissing = true;
            }
            if ($scope.nameMissing || $scope.dataSourceMissing || $scope.targetMissing) {
                return false;
            } else {
                return true;
            }
        }

        $scope.ok = function () {
            if (isInputOk()) {
                $scope.settings.name = $scope.name;
                $scope.settings.dataSourceId = $scope.dataSource.id;
                $scope.settings.dataSource = $scope.dataSource;
                $scope.settings.filters = $scope.filters;
                if ($scope.targetDataSource) {
                    $scope.settings.targetDataSourceId = $scope.targetDataSource.id;
                } else {
                    $scope.settings.targetDataSourceId = null;
                }

                $scope.settings.targetDataSource = $scope.targetDataSource;
                $scope.settings.targetFilters = $scope.targetFilters;
                if ($scope.settings.targetDataSourceId) {
                    $scope.settings.targetValue = null;
                } else {
                    $scope.settings.targetValue = $scope.targetValue;
                }
                $scope.settings.formula = $scope.formula;
                $scope.settings.operator = $scope.operator;

                $uibModalInstance.close($scope.settings);
            }
        };

        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

    }]);