﻿import * as pdfService from "@common/services/pdfService"
import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .directive("learningPagePdfExport", ['$translate',
        function ($translate) {
            return {
                templateUrl: 'learningPagePdfExport.html',
                restrict: 'E',
                scope: {
                    'pageId': '<',
                    'fileName': '<',
                    'isExternal': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.btnEnabled = true
                    
                    scope.printPage = function () {
                        if (!scope.btnEnabled)
                            return
                        scope.btnEnabled = false
                        pdfService.printLearningPage(scope.pageId, scope.fileName, scope.isExternal).then(success, errorHandler)
                    }

                    function success() {
                        console.log("printed pdf")
                        scope.btnEnabled = true
                    }
                    function errorHandler(e) {
                        const label = $translate.instant('A3_CREATE_PDF')
                        toaster.error(label + " error")
                        scope.btnEnabled = true
                    }
                }
            }
        }]);
