import * as boardService from "@common/services/boardService"
import { hasDefaultAccess } from "@common/components/dashboard/designer/common/userAccessService"


angular
    .module('DigiLean')
    .directive("smartKanban", ['$uibModal', '$filter', '$translate', 'statusColumnProvider', 'responsibleColumnProvider', 'predefinedLabelColumnProvider',
        function ($uibModal, $filter, $translate, statusColumnProvider, responsibleColumnProvider, predefinedLabelColumnProvider) {
            return {
                templateUrl: 'smartKanban.html',
                restrict: 'E',
                scope: {
                    'model': '<?',
                    'users': '<?',
                    'newTask': '<?',
                    'deleteTask': '<?',
                    'userAccess': '<?',
                },
                link: function (scope, elem, attrs) {
                    scope.canEditComponent = true;
                    scope.columns = [];
                    scope.tasks = [];
                    scope.editable = true;
                    scope.columnGroupList = [
                        {
                            title: $translate.instant("COMMON_STATUS"),
                            property: "status",
                            columnProvider: statusColumnProvider,
                            groupModel: null
                        },
                        {
                            title: $translate.instant("COMMON_RESPONSIBLE"),
                            property: "responsible",
                            columnProvider: responsibleColumnProvider,
                            groupModel: null
                        },
                    ];
                    scope.selectedGrouping = scope.columnGroupList[0];

                    scope.groupingChanged = function () {
                        loadModel(scope.model, scope.selectedGrouping);
                    }

                    var hasColumnProvidersBeenSet = false;
                    function setColumnProviders() {
                        if (hasColumnProvidersBeenSet) return;
                        hasColumnProvidersBeenSet = true;
                        // Create grouping based on setup
                        // For now we will support text columns
                        var textColumns = $filter('filter')(scope.model.columns, { attributeType: "text" }, true);
                        textColumns.forEach(function (column) {
                            scope.columnGroupList.push({
                                title: column.name,
                                property: "text",
                                columnProvider: predefinedLabelColumnProvider,
                                groupModel: column
                            });

                        });
                    }

                    scope.$watch('userAccess', function (userAccess) {
                        scope.canEditComponent = hasDefaultAccess(userAccess);
                    });


                    scope.$watch('model', function (model) {
                        if (!model) return;
                        if (!scope.users) return;
                        boardService.canEdit(scope.model.board.id).then(function (canEdit) {
                            scope.editable = canEdit;
                            if(!canEdit) {
                                scope.columnOptions.disabled = true;
                            }
                        });
                        setColumnProviders();
                        loadModel(scope.model, scope.selectedGrouping);
                    });
                    scope.$watch('users', function (users) {
                        if (!users) return;
                        if (!scope.model) return;
                        setColumnProviders();
                        loadModel(scope.model, scope.selectedGrouping);
                    });
                    scope.$watch("newTask", function (task) {
                        if (!task) return;
                        if (!scope.model) return;
                        if (task.boardId == scope.model.board.id) {
                            initTask(task);
                            var hasColumnWithTask = false;
                            scope.columns.forEach(function (column) {
                                if (column.addTask(task)) {
                                    hasColumnWithTask = true;
                                };
                            });
                            if (!hasColumnWithTask) {
                                // Reload model to create new column
                                loadModel(scope.model, scope.selectedGrouping);
                            }
                        }
                    });
                    scope.$watch("deleteTask", function (task) {
                        if (!task) return;
                        if (!scope.model) return;
                        if (task.boardId == scope.model.board.id) {
                            initTask(task); // init task so we can animate
                            // animate removal
                            task.animate("fadeOut", 2000);
                            // use time so animation shows before removing task from list.
                            setTimeout(function () {
                                scope.columns.forEach(function (column) {
                                    column.removeTask(task);
                                });
                            }, 1500);

                        }
                    });

                    scope.columnOptions = {
                        connectWith: ".task-list",
                        receive: function (e, ui) {
                            var target = ui.item.sortable.droptarget[0];
                            var columnIndex = $(target).attr("data-column-index");
                            // var property = $(target).attr("data-property");
                            // var propertyValue = $(target).attr("data-property-value");
                            var task = ui.item.sortable.model;
                            // Find column
                            var column = scope.columns[columnIndex];

                            task.animate("bounce");
                            var changeSettings = {
                                dropColumn: column,
                                taskId: task.id,
                                task: task,
                                property: column.property,
                                propertyValue: column.propertyValue
                            };

                            column.saveChanges(changeSettings)
                        },
                        disabled: !scope.editable
                    };

                    var initTask = function (task) {
                        task.model = {
                            task: task,
                            boardTaskId: task.id
                        }
                        task.animate = function (animation, timeOut) {
                            if (!timeOut) {
                                timeOut = 1000;
                            }
                            task.animateClass = "animated " + animation;
                            setTimeout(function () {
                                task.animateClass = "";
                            }, timeOut);
                        }
                        task.animate("slideInDown", 3000);
                    };

                    var loadModel = function (model, columnGrouping) {
                        scope.columns = [];
                        model.tasks.forEach(function (task) {
                            initTask(task);
                        });

                        scope.columns = columnGrouping.columnProvider().getColumns(model.tasks, scope.users, columnGrouping.groupModel);
                    }

                    scope.createTask = function (column) {
                        var status = "blank";
                        var options = {
                            users: scope.users
                        }
                        // Map column 
                        if (column.property == "status") {
                            status = column.propertyValue;
                        }
                        if (column.property == "responsible") {
                            options.selectedUser = column.user;
                        }
                        var task = {
                            id: 0,
                            boardId: scope.model.board.id,
                            title: "",
                            text: "",
                            status: status,
                            tags: "",
                            user: column.user,
                            sortOrder: scope.columns.length
                        }



                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newBoardTask.html',
                            controller: 'newBoardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                options: function () {
                                    return options;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {

                        });

                    }


                }

            }
        }]);