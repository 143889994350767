﻿import toaster from "@common/components/toast"

var DigiLean = angular.module('DigiLean'); DigiLean.controller('dataAdminController', ['$scope', '$uibModal', '$translate', '$filter', 'modalService', 'dataSourceService',
    function ($scope, $uibModal, $translate, $filter, modalService, dataSourceService) {

        $scope.filters = null;
        $scope.dataSources = {
            list: [],
            filteredList: []
        };

        $scope.$watch('query', function (query) {
            if ($scope.query) {
                $scope.searchQuery= angular.copy($scope.query);
            }
        });

        $scope.subscribe("DataSourceCreated", function (dataSource) {
            getDataSources();
        });

        $scope.delete = function (data) {
            $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_DATA_CONFIRMATION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + data.title + '?',
                    bodyText: translations.ADMIN_MESSAGE_DELETE_DATA_CONFIRMATION
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    dataSourceService().delete(data.id).then(function (data) {
                        getDataSources();
                        $scope.publish("SendDataSourceDeleted", data.id);
                        $translate('ADMIN_MESSAGE_DATA_DELETED').then(function (msg) {
                            toaster.info(msg);
                        }, function (translationId) {
                            $scope.headline = translationId;
                        });
                    });
                });
            });
        }

        $scope.addDataSource = function () {
            $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newDataSource.html',
                controller: 'newDataSourceController',
                resolve: {
                }
            });
        }

        function getManualKpiIdentifierSource() {
            return {
                sourceType: 'MANUAL_KPI_IDENTIFIER',
                title: $translate.instant('ADMIN_MANUAL_KPI_IDENTIFIER'),
                description: $translate.instant('DASHBOARD_KPI_DYNAMIC_DESC'),
                featureKey: 'ManualKpiIdentifier',
                unitsOfTimeOfTime: 'DAY',
                elements: [{sourceColumn: 'assetId', type: 'asset', isMandatory: false, label: $translate.instant('COMMON_ASSET')},] 
            }
        }
        var unitsOfTime =  [
            {value: "DAY", displayName:"COMMON_TIMEPERIOD_DAY"},
            {value: "WEEK", displayName:"COMMON_TIMEPERIOD_WEEK"},
            {value: "MONTH", displayName:"COMMON_TIMEPERIOD_MONTH"},
            {value: "YEAR", displayName:"COMMON_TIMEPERIOD_YEAR"}
        ];

        var getDataSources = function () {
            dataSourceService().getList().then(function (list) {
                list.push(getManualKpiIdentifierSource());
                list.forEach(source => {
                    // find unit of time
                    var unitOfTime = $filter('filter')(unitsOfTime, { value: source.unitOfTime }, true);
                    if (unitOfTime.length > 0) {
                        source.displayUnitOfTime = $translate.instant(unitOfTime[0].displayName);
                    }
                    source.isConfigurable = isConfigurable(source);
                    
                });
                list = list.sort((a, b) => a.title.localeCompare(b.title));
                $scope.dataSources.list = list
                $scope.dataSources.filteredList = list;
                

            });
        }

        function isConfigurable(datasource) {
            return !(datasource && datasource.featureKey && 
                     (datasource.featureKey === 'CompetenceBall' || 
                      datasource.featureKey === 'ManualKpiIdentifier'));
        }

        getDataSources();

    }]);