import angular from "angular"
import { saveCompanySetting, getCompanySetting } from "@common/stores/companySettingsStore"
import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"
import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .directive("organizationSettings", ['$state', 'languageAdminService', 'customerAccountService', 'navigationService', 'authService', 'dataListService', 'modulesService', 'learningPublicService',
        function ($state, languageAdminService, customerAccountService, navigationService, authService, dataListService, modulesService, learningPublicService) {
            return {
                templateUrl: 'organizationSettings.html',
                restrict: 'E',
                scope: {

                },
                link: function (scope, elem, attrs) {
                    scope.isKaizenAdmin = authService.hasRole('KaizenAdmin')

                    scope.selectedLanguage = null;
                    scope.languages = languageAdminService().getSupportedLanguages()
                    
                    scope.months = timeService.getMonths();
                    scope.selectedFiscalmonth = null;
                    scope.fiscalmonth = 1;

                    scope.subscribe("UserAuthenticatedAndReady", function (profile) {
                        getSubscription()
                    })

                    function setLanguage() {
                        const selectedLanguage = scope.languages.find(l => l.languageCode == scope.orgLanguage)
                        if (selectedLanguage) {
                            scope.selectedLanguage = selectedLanguage
                        }
                    }

                    function setFiscalmonth(){
                        const selectedFiscalmonth = scope.months.find(m => m.id == scope.fiscalmonth)
                        if (selectedFiscalmonth) {
                            scope.selectedFiscalmonth = selectedFiscalmonth
                        }
                    }

                    scope.listId = 0
                    scope.orgLanguage = "en"
                    scope.currency = customerService.getDefaultCurrency()

                    getCompanySetting("currency").then(value => {
                        scope.currency = value
                    })
                    getCompanySetting("language").then(value => {
                        scope.orgLanguage = value
                        setLanguage();
                    })
                    getCompanySetting("fiscalmonth").then(value => {
                        if(value)
                            scope.fiscalmonth = parseInt(value)
                        setFiscalmonth()
                    })

                    scope.goToLearningPage = function(module) {
                        if(module.helpLink){
                            var params = module.helpLink;
                            $state.go('learningexternal', params , { reload: false });
                        }
                    }
                    var getLink = function (module) {
                        if (module.learning) {
                            learningPublicService.getHelpPage("en", module.learning.featureKey).then(function (page) {
                                if (page) {
                                    let link = "/#/learning/external";
                                    link += `/${page.learningMaterialId}?pageid=${page.id}`
                                    module.helpLink = {
                                        location: "external",
                                        id: page.learningMaterialId,
                                        pageid: page.id,
                                    }
                                    
                                } else {
                                    module.helpLink = null
                                }
                            })
                        }

                    }
                    scope.allowedModules = []
                    scope.subscription = 'CUSTOM';
                    function getSubscription() {
                        customerAccountService().getSubscription().then(function (subscription) {
                            if (subscription) {
                                modulesService.getAllowedModulesConfig().then(allowedModulesConfig => {
                                    scope.allowedModules = allowedModulesConfig.map(m => {
                                        m.hasModule = navigationService().hasModule(m.name)
                                        getLink(m);
                                        console.log(`${m.name} = ${m.hasModule}`)
                                        return m
                                    })
                                })

                                scope.subscription = subscription;
                            }
                        })
                    }
                    getSubscription();


                    scope.changeLanguage = function (language) {
                        if(!language) return;
                        var languageCode = language.languageCode;
                        scope.orgLanguage = languageCode
                        saveCompanySetting("language", languageCode)
                    }

                    scope.changeCurrency = function (currency) {
                        scope.currency = currency;
                        setLanguage();
                        saveCompanySetting("currency", currency)
                    }

                    scope.changeFiscalMonth = function (fiscalmonth) {
                        if(!fiscalmonth) return;
                        var fiscalmonthNo = fiscalmonth.id;
                        scope.fiscalMonth = fiscalmonthNo
                        saveCompanySetting("fiscalmonth", fiscalmonthNo)
                    }

                    scope.toggleModule = function (module) {
                        //module.hasModule = !module.hasModule;
                        if (module.hasModule) {
                            modulesService.enableModule(module.name).then(function () {
                                module.hasActivated = true;
                                module.hasDeActivated = false;
                            }, (er) => {
                                const msg = er.data.title
                                toaster.error(msg)
                            })
                        } else {
                            modulesService.disableModule(module.name).then(function () {
                                module.hasActivated = false;
                                module.hasDeActivated = true;
                            }, (er) => {
                                const msg = er.data.title
                                toaster.error(msg)
                            })
                        }
                    };

                    scope.redirectToDataList = function () {
                        if (scope.listId == 0) {
                            var options = {
                                includeDeletedItems: false,
                            }
                            dataListService().getFeatureList("CompetenceBall", options).then(function (list) {
                                if (list) {
                                    scope.listId = list.id
                                    $state.go('admindatalist', { id: scope.listId }, { reload: false })
                                }
                            })

                        } else {
                            $state.go('admindatalist', { id: scope.listId }, { reload: false })
                        }
                    }

                    scope.reloadPage = function () {
                        location.reload(false)
                    }
                }
            }
        }])
