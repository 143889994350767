﻿import toaster from "@common/components/toast";
angular
    .module('DigiLean')
    .directive("countdown", ['$interval', '$translate',
        function ($interval, $translate) {
            return {
                templateUrl: 'countdown.html',
                restrict: 'E',
                scope: {
                    'settings': '='
                },
                link: function (scope, element, attrs) {
                    scope.hours = "..";
                    scope.minutes = "..";
                    scope.seconds = "..";

                    scope.countdownMinutes = 15;

                    scope.showHours = false;
                    scope.showMinutes = true;
                    scope.showSeconds = true;

                    scope.nearTheEnd = false;
                    scope.isCountdownFinished = false;
                    scope.isCounting = false;
                    var stopTime,
                        countDownTime; // so that we can cancel the time updates

                    scope.$watch("settings", function () {
                        var test = scope.settings;
                        if(scope.settings && scope.settings.countdownMinutes){
                            scope.countdownMinutes = scope.settings.countdownMinutes;
                        }
                        scope.nearTheEnd = false;
                        scope.isCountdownFinished = false;
                        currentSeconds = scope.countdownMinutes * 60;
                        updateCountDown();
                    });

                    var currentSeconds = 0;
                    scope.startCountDown = function () {
                        scope.isCounting = true;
                        scope.nearTheEnd = false;
                        currentSeconds = scope.countdownMinutes * 60;
                        updateCountDown();
                        scope.showHours = false;
                        scope.showMinutes = true;
                        scope.showSeconds = true;
                        $interval.cancel(stopTime);
                        countDownTime = $interval(countDown, 1000);
                    }

                    function countDown() {
                        if (currentSeconds <= 30) {
                            scope.nearTheEnd = true;
                        }
                        if (currentSeconds < 0) {
                            scope.isCountdownFinished = true;
                            scope.isCounting = false;
                            $interval.cancel(countDownTime);
                            // DisplayAlert
                            var alertText = scope.countdownMinutes + $translate.instant("NAV_CLOCK_COUNTDOWN_FINISHED_TEXT");
                            toaster.error(alertText, $translate.instant("NAV_CLOCK_COUNTDOWN_FINISHED"))
                            return;
                        }
                        updateCountDown();
                        currentSeconds -= 1;
                    }

                    function updateCountDown() {
                        var minutes = Math.floor(currentSeconds / 60);
                        var seconds = currentSeconds - minutes * 60;
                        scope.minutes = addLeadingZero(minutes);
                        scope.seconds = addLeadingZero(seconds);
                    }

                    function startClock() {
                        scope.showHours = true;
                        scope.showMinutes = true;
                        scope.showSeconds = false;
                        $interval.cancel(countDownTime);
                        stopTime = $interval(updateTime, 1000);
                    }


                    function addLeadingZero(val) {
                        if (val < 10) return "0" + val;
                        return val;
                    }


                    function updateTime() {
                        var now = moment();
                        scope.hours = addLeadingZero(now.hours());
                        scope.minutes = addLeadingZero(now.minutes());
                        scope.seconds = addLeadingZero(now.seconds());
                    }

                    element.on('$destroy', function () {
                        $interval.cancel(countDownTime);
                        $interval.cancel(stopTime);
                    });

                }
            }
        }]);
