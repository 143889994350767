import { canEdit } from "@common/services/boardService";

var DigiLean = angular.module('DigiLean');
DigiLean.directive("dailyKpiDate", ['$filter', 'qualityService', 'navigationService', '$uibModal',
    function ($filter, qualityService, navigationService, $uibModal) {
        return {
            templateUrl: 'daily-kpi-date.html',
            restrict: 'E',
            scope: {
                'editable': '=',
                'settings': '<',
                'areaId': '<',
                'projectId': '<',
                'visualizationType': '<',
                'deviationTypeId': '<',
                'dateOfMonth': '<',
                'month': '<',
                'year': '<',
                'value': '<',
                'cssClass': '@?',
                'assetId': '=',
                'userAccess': '<',
            },
            link: function (scope, elem, attrs) {

                scope.associatedWithDeviationType = false;
                scope.label1 = '';
                scope.label2 = '';
                scope.label3 = '';
                scope.label4 = '';

                scope.canEdit = false;
                
                scope.$watch('userAccess', function (userAccess) {
                    scope.canEdit = userAccess.isAdmin || userAccess.assetRole == "Member" || userAccess.assetRole == "Owner";
                });

                function getVisualizationTypeIdentificator(){
                    if(scope.typeVariant){
                        if(scope.visualizationType.toUpperCase() == 'DYNAMIC'){
                            return scope.typeVariant;
                        } else return scope.visualizationType + "-" + scope.typeVariant;
                    } else return scope.visualizationType
                }

                scope.setStatusValue = function (newValue) {
                    scope.value = newValue;
                    var date = new Date(Date.UTC(scope.year, scope.month, scope.dateOfMonth, 12, 0, 0, 0));

                    var visualizationTypeId = getVisualizationTypeIdentificator();
                    var status = {
                        status: scope.value,
                        date: date,
                        areaId: scope.areaId,
                        projectId: scope.projectId,
                        assetId: scope.assetId,
                        visualizationType: visualizationTypeId
                    };
                    qualityService().updateDateStatus(status).then(function (savedStatus) {

                    });

                }

                scope.$watch("settings", function () {
                    if (!scope.settings) return;
                    scope.label1 = '';
                    scope.label2 = '';
                    scope.label3 = '';
                    scope.label4 = '';
                    if (scope.settings.valuelabels) {    
                        scope.hide1 = true;
                        scope.hide2 = true;
                        scope.hide3 = true;
                        scope.hide4 = true;
                        for (var k = 0; k < scope.settings.valuelabels.length; k++) {  
                            if (scope.settings.valuelabels[k].value === 1) {
                                scope.label1 = scope.settings.valuelabels[k].label;
                                scope.hide1 = false;
                            } else if (scope.settings.valuelabels[k].value === 2) {
                                scope.label2 = scope.settings.valuelabels[k].label;
                                scope.hide2 = false;
                            } else if (scope.settings.valuelabels[k].value === 3) {
                                scope.label3 = scope.settings.valuelabels[k].label;
                                scope.hide3 = false;
                            } else if (scope.settings.valuelabels[k].value === 4) {
                                scope.label4 = scope.settings.valuelabels[k].label;
                                scope.hide4 = false;
                            }
                        }
                    }
                    if(scope.settings.dataSource){
                        scope.dataSource = scope.settings.dataSource;
                        scope.filters = null;   
                    }
                    if(scope.settings.filters){
                        scope.filters = scope.settings.filters;
                    } else if (scope.filters){ //filter has been changed from a filter value to no filter
                        scope.filters = null;
                    }
                    if(scope.settings.typeVariant){
                        scope.typeVariant = scope.settings.typeVariant;
                    }
                });

                scope.$watch("deviationTypeId", function () {
                    scope.associatedWithDeviationType = false;
                    if (!scope.deviationTypeId) return;
                    scope.associatedWithDeviationType = true;
                });

                scope.createNewDeviation = function (severity) {
                    var localDate = new Date(scope.year, scope.month, scope.dateOfMonth);
                    var date = moment(localDate).utc().toDate();
                    var settings = {
                        assetId: scope.assetId,
                        severity: severity,
                        deviationTypeId: scope.deviationTypeId,
                        incidentDate: date
                    };
                    $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        templateUrl: 'newDeviation.html',
                        controller: 'newDeviationController',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            settings: function () {
                                return settings;
                            }
                        }
                    });
                };


                scope.getRegistrations = function () {
                    var date = new Date(scope.year, scope.month, scope.dateOfMonth);
                    var startDate = moment(date).utc();
                    var endDate = moment(startDate).add(23, 'hours').add(59, 'minutes').add(59, 'seconds');

                    var timePeriod = {
                        from: startDate.toDate().toJSON(),
                        to: endDate.toDate().toJSON()
                    };

                    if(scope.dataSource){
                        var datasourceoptions = {
                            dataSource: scope.dataSource,
                            filters: scope.filters,
                            timePeriod: timePeriod,
                            canEdit: scope.canEdit
                        }
                        showDatasourceValues(datasourceoptions);
                    } else if (scope.deviationTypeId) {
                        var options = {
                            assetId: scope.assetId,
                            deviationTypeId: scope.deviationTypeId,
                            timePeriod: timePeriod
                        }
                        showDeviations(options);
                    } else {
                        var visualizationTypeId = getVisualizationTypeIdentificator();
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'greencrossStatusForm.html',
                            controller: 'greencrossStatusModalController',
                            resolve: {
                                timePeriod: function () {
                                    return timePeriod;
                                },
                                visualizationType: function () {
                                    return visualizationTypeId;
                                },
                                filters: function () {
                                    return scope.filters;
                                }
                            }
                        });
                    }
                }

                function showDatasourceValues(options) {

                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        templateUrl: 'datavaluesListForm.html',
                        controller: 'datavaluesListModalController',
                        windowClass: 'fullDeviation-modal-window',
                        resolve: {
                            options: function () {
                                return  options;
                            }
                        }
                    });
                }

                function showDeviations(deviationOptions) {

                    var currentfilter = [];
                    if(scope.filters && scope.filters.length > 0){
                        currentfilter = scope.filters;

                    } else if (deviationOptions.assetId) {
                        currentfilter.push({
                            sourceColumn: "AssetId",
                            operator: "InList",
                            items: [deviationOptions.assetId]
                        })
                    }
                    currentfilter.push({
                        sourceColumn: "DeviationTypeId",
                        operator: "InList",
                        items: [deviationOptions.deviationTypeId]
                    });
                    var order = [{
                        sourceColumn: "Status",
                        SortDirection: "ASC"
                    }, {
                        sourceColumn: "Severity",
                        SortDirection: "DESC"
                    }];
                    var options = {
                        filters: currentfilter,
                        sortOrders: order,
                        timePeriodColumn: 'IncidentDate',
                        timePeriod: deviationOptions.timePeriod
                    };

                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        templateUrl: 'deviationListForm.html',
                        controller: 'deviationListModalController',
                        windowClass: 'fullDeviation-modal-window',
                        resolve: {
                            options: function () {
                                return options;
                            }
                        }
                    });
                }

            }

        }
    }]);
