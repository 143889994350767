﻿import { translateLabelInstant } from "@common/services/language/DigiLeanLang";
var DigiLean = angular.module('DigiLean');
DigiLean.controller('datalistController', ['$scope', '$stateParams', 'dataListService','$translate','modalService','languageAdminService',
    function ($scope, $stateParams, dataListService, $translate, modalService, languageAdminService) {
        $scope.listId = parseInt($stateParams.id);
        $scope.list = null;
        $scope.items = []

        var defaultConfig = {languageCode: '',
        languageLabel: $translate.instant('ADMIN_DEFAULT_CONFIG'),
        nativeLabel: $translate.instant('ADMIN_DEFAULT_CONFIG')
        };
        $scope.selectedLanguage = defaultConfig;
        $scope.languages = languageAdminService().getSupportedLanguages()
        $scope.languages.unshift($scope.selectedLanguage);

        $scope.changeLanguage = function (language) {
            if(!language) return;
            $scope.selectedLanguage = language;
            $scope.items.forEach(item => {
                item.customLanguageLabel = item.customLanguageLabels.find(q => q.languageCode == $scope.selectedLanguage.languageCode)
            });
        }

        dataListService().get($scope.listId).then(function (list) {
            $scope.list = list;
            $scope.items = list.items;
        })
        $scope.addItem = function () {
            var item = {
                identifier: $scope.items.length + 1,
                name: "item",
                dataListId: $scope.listId
            }
            dataListService().addItemToList($scope.listId, item).then(function (savedItem) {
                savedItem.isEditMode = true;
                savedItem.customLanguageLabels = [] // add empty array for custom lang on new items
                $scope.items.push(savedItem);
                setTimeout(function () {
                    $(".item-identifier").focus();
                }, 50);
            })
        }

        $scope.updateTranslationLabel = function (item) {
            if(item.customLanguageLabel){
                if(!item.customLanguageLabel.languageCode){ // new translation label
                    item.customLanguageLabel.languageCode = $scope.selectedLanguage.languageCode;
                }
                dataListService().updateTranslationLabel(item.id, item.customLanguageLabel).then(function (updatedLabel) {
                    if (!item.customLanguageLabel.id) { // insert custom lang labels to avoid to reload all
                        item.customLanguageLabels.push(updatedLabel)
                    }
                })
            }             
        }

        $scope.saveItem = function (item) {
            dataListService().updateDataListItem(item.id, item).then(function (item) {
            })        
        }
        $scope.removeItem = function (item) {
            deleteItem(item);
        }

        function updateItemsOrder(e, ui) {
            var items = ui.item.sortable.droptargetModel;
            var itemsSortOrder = {
                dataListId: $scope.listId,
                sortOrders: []
            }

            var index = 0;
            items.forEach(item => {
                itemsSortOrder.sortOrders.push({
                    id: item.id,
                    sortOrder: index,
                });
                index += 1;
            });
            dataListService().updateItemsSortOrder($scope.listId, itemsSortOrder);
        }

        $scope.itemsSortableOptions = {
            connectWith: ".summaryList",
            handle: ".drag-handle",
            placeholder: "summary-item-sortable-placeholder",
            stop: updateItemsOrder
        };

        $scope.setItemViewMode = function (item) {
            item.isEditMode = false;
        }
        $scope.setItemEditMode = function (item) {
            item.isEditMode = true;
        }

        async function deleteItem(item) {
            
            let modalOptions = {
                closeButtonText: translateLabelInstant("COMMON_CANCEL"),
                actionButtonText: translateLabelInstant("COMMON_DELETE"),
                headerText: translateLabelInstant("COMMON_DELETE") + ' ' + item.name + '?',
                bodyText: translateLabelInstant("DATALIST_DELETE_ITEM_CONFIRMATION")
            };
            try {
                const result = await modalService.showModal({}, modalOptions)
                const index = $scope.items.indexOf(item);
                $scope.items.splice(index, 1);
                dataListService().deleteDataListItem(item.id)
            }
            catch{}
        };

    }]);