import { BusinessUnit } from "@api"
import { getAllUnitsCached } from "@common/services/businessUnitService"
import * as customerService from "@common/services/customers/customerService"
import { DigiLeanNgScope } from "@common/model/angularModel"
import toaster from "@common/components/toast"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
import angular from "angular"
import { handleThrownErrorMsg } from "@common/services/error/aspNetErrorHandling"


interface BusinessUnitExtended extends BusinessUnit {
    editLicenses?: boolean | false // non-mapped property used in businessunitAccount.ts
    activeUserLicenses?: number // non-mapped property used in businessunitAccount.ts
}

interface IBusinessunitAccountScope extends DigiLeanNgScope {
    businessUnits: BusinessUnitExtended[]
}

angular
.module('DigiLean')
.directive("businessunitAccount", [
    function () {
        return {
            templateUrl: 'businessunitAccount.html',
            restrict: 'E',
            scope: {
            },
            link: function ($scope: IBusinessunitAccountScope, elem, attrs) {

                $scope.userLicenses = 0

                $scope.businessUnits = []
                $scope.businessUnitsEnabled = false
                getAllUnitsCached().then(units => {
                    $scope.businessUnits.push(...units)
                })

                $scope.toogleEditLicenses = function (unit: BusinessUnitExtended) {
                    unit.editLicenses = !unit.editLicenses
                    if(unit.editLicenses){
                        customerService.getActiveUserLicenses(unit.id).then(function (result) {
                            if (result)
                                unit.activeUserLicenses = result
                        })
                    }
                }
             
                $scope.changeMaxLicenses = function (unit: BusinessUnitExtended) {
                    $scope.messages = []
                    if (unit.editLicenses){
                        customerService.updateMaxBusinessUnitLicenses(unit).then(function (updatedUnit) {
                            unit.editLicenses = false
                            const msg = translateLabelInstant("COMMON_SAVED")
                            toaster.info(msg) 
                        }, handleExceptions)
                    }       
                }

                function handleExceptions(error: Error, fallbackErrorLabel?: string) {
                    console.error(error)
                    const errors = handleThrownErrorMsg(error)
                    if (errors.length > 0)
                        $scope.messages = errors
                    else if (fallbackErrorLabel)
                        $scope.messages = [fallbackErrorLabel]
                    else
                        $scope.messages = ["COMMON_ERROR_MSG"]
                }

            }
        }
    }])