import * as assetService from "@common/services/assetService"
import * as timeService from "@common/services/timeService"
import { getDefaultAppStyle, getDefaultAppTheme, calcDefaultAppFontSize } from "@common/components/dashboard/designer/common/appStyleService"

angular
    .module('DigiLean')
    .directive("kpiLetterBase", ['$uibModal', '$translate','dataService', 'qualityService', 'areaService','projectService','deviationService',
        function ($uibModal, $translate,dataService, qualityService, areaService, projectService,deviationService) {
            return {
                restrict: 'E',
                scope: {
                    'isAdminMode': '<',
                    'settings': '<',
                    'boardAssetId': '<',
                    'settingsChangeHandler': '&',
                    'layoutFactor': '<'
                },
                link: function (scope, elem, attrs) {
                    scope.theme = getDefaultAppTheme()
                    scope.themeChangedEvent = function($event) {
                        scope.theme = $event.detail
                        scope.updateSettings()
                    }
                    
                    scope.appStyle = getDefaultAppStyle()
                    function calcStyles() {
                        scope.appStyle["font-size"] = calcDefaultAppFontSize(scope.layoutFactor)
                    }
                    calcStyles()
                    scope.$watch('layoutFactor', calcStyles)

                    scope.title = ""
                    scope.titleChangedEvent = function($event) {
                        scope.title = $event.detail
                        scope.updateSettings()
                    }

                    scope.identificationName = ""; 
                    scope.typeSelected = "area";

                    scope.areaName = "";
                    scope.areaId = null;
                    scope.areas = null;
                    scope.projects = null;
                    scope.projectId = null;
                    scope.assetId = null;

                    scope.editable = false;
                    scope.automatic = false;
                    scope.deviationTypeId = null;
                    scope.formula = 'sum';

                    scope.subscribe("DateStatusChanged", function (status) {
                        if (scope.dataSource || scope.deviationTypeId) // DateStatus is not relevant for Datasource and incident configurations
                            return
                        
                        var visualizationTypeId = getVisualizationTypeIdentificator();
                        if(status.visualizationType == visualizationTypeId){ 
                            if(scope.areaId === null && scope.projectId === null && scope.assetId === null){
                                updateAggregateStatus(status);
                            }
                            else if(scope.areaId && status.areaId === scope.areaId){
                                updateChangedStatus(status);
                            } else if (scope.projectId && status.projectId === scope.projectId) {
                                updateChangedStatus(status);
                            } else if (scope.assetId && status.assetId === scope.assetId) {
                                updateChangedStatus(status);
                            }
                        }
                    });


                    scope.loadAreas = function(){
                        if(!scope.areas){        
                            areaService().getList(true).then(function (areas) {
                                scope.areas = areas;
                            });
                        }
                    }

                    scope.loadProjects = function(){
                        if(!scope.projects){        
                            projectService().getActiveProjects().then(function (projects) {
                                scope.projects = projects;
                            });
                        }
                    }

                    scope.loadAssets = function(){
                        if(!scope.assets){
                            assetService.getAllAssets().then(function (assets) {
                                scope.assets = assets;
                            });
                        }
                    }


                    scope.subscribe("DeviationCreated", function (deviation) {
                        if(scope.automatic &&  deviation.deviationTypeId == scope.deviationTypeId) {
                            var status = {
                                date: deviation.incidentDate,
                                status: convertSeverityToKPIDate(deviation.severity),
                                assetId: deviation.assetId,
                                projectId: deviation.projectId,
                                areaId: deviation.areaId
                            }
                            if(scope.assetId === null || (scope.filters && scope.filters.length > 0)){
                                
                                updateAggregateStatus(status);
                            }
                            else if(deviation.assetId == scope.assetId){
                                updateChangedStatus(status);
                            } 
                        }
                    });

                    function convertSeverityToKPIDate(severity){
                        if(severity>0){
                            return severity+1;
                        } else return severity;
                    }

                    function updateAggregateStatus(status){
                        var convertedDate = moment(status.date);
                        var year = moment(convertedDate).year();
                        var month = moment(convertedDate).month();
                        var day = moment(convertedDate).date();
                        // resolve if same, update
                        if (year === scope.selectedYear) {
                            if(isRelevant(status)){ //Check filter
                                if(scope.automatic){    
                                    scope.getAutomaticStatusesForYear(year);
                                } else {
                                    scope.getStatusesForYear(year);
                                }
                            }
                        }
                        if (month === scope.selectedMonthNumber) {
                            if(isRelevant(status)){ //Check filter
                                if(scope.statuses[day - 1] && scope.statuses[day - 1] <= status.status){
                                    scope.statuses[day - 1] = status.status;
                                } else if (!scope.statuses[day - 1]){
                                    scope.statuses[day - 1] = status.status;
                                } else {
                                    scope.getStatusesForMonth(month);
                                }
                            }
                            
                        }
                    }

                    function isRelevant(newStatus){
                        var result = true;
                        if(scope.filters){
                            // Check if updated value is part of filter
                            if(newStatus.assetId != null){
                                var assetFilter = scope.filters.find(f => f.sourceColumn == "assetId")
                                if (assetFilter) {
                                    var index = assetFilter.items.indexOf(newStatus.assetId);
                                    if(index == -1){
                                        return false;
                                    }
                                }
                            }

                            // Check if updated value is part of filter
                            if(newStatus.areaId != null){
                                var areaFilter = scope.filters.find(f => f.sourceColumn == "areaId");
                                if (areaFilter) {
                                    var index = areaFilter.items.indexOf(newStatus.areaId);
                                    if(index == -1){
                                        return false;
                                    }
                                }
                            }

                            // Check if updated value is part of filter
                            if(newStatus.projectId != null){
                                var projectFilter = scope.filters.find(f => f.sourceColumn == "projectId");
                                if (projectFilter) {
                                    var index = projectFilter.items.indexOf(newStatus.projectId);
                                    if(index == -1){
                                        return false;
                                    }
                                }
                            }
                        }
                        return result;
                    }

                    function updateChangedStatus(status){
                        var convertedDate = moment(status.date);
                        var year = moment(convertedDate).year();
                        var month = moment(convertedDate).month();
                        var day = moment(convertedDate).date();

                        // resolve if same, update
                        if (year === scope.selectedYear) {
                            if(scope.automatic){    
                                scope.getAutomaticStatusesForYear(year);
                            } else {
                                scope.getStatusesForYear(year);
                            }
                            if (month === scope.selectedMonthNumber) {
                                if(scope.areaId === null && scope.projectId === null && scope.assetId === null){ //aggregate all
                                    if(scope.automatic){    
                                        scope.getAutomaticStatusesForMonth(month);
                                    } else {
                                        scope.getStatusesForMonth(month);
                                    }
                                    
                                } else {
                                    scope.statuses[day - 1] = status.status;
                                }
                            }
                        }
                        
                    }

                    // Dashboard Settings handling
                    scope.$watch('settings', function (settings) {
                        scope.settings = settings;
                        checkSettings();
                    });

                    scope.updateArea = function (area){
                        scope.areaId = area.id;
                        scope.projectId = null;
                        scope.assetId = null;
                        scope.typeSelected = 'area';
                        scope.identificationName = area.name;
                        scope.editable = true;
                        scope.updateSettings();
                        scope.initialize();
                    };

                    scope.updateAsset = function (asset){
                        scope.areaId = null;
                        scope.assetId = asset.id;
                        scope.projectId = null;          
                        scope.typeSelected = 'asset';
                        scope.identificationName = asset.name;
                        scope.editable = true;
                        scope.updateSettings();
                        scope.initialize();
                    };

                    scope.modifyFilter = function (type){
                        scope.typeSelected = type;
                        scope.all();
                    };

                    scope.all = function (){
                        scope.areaId = null;
                        scope.projectId = null;
                        scope.assetId = null;
                        scope.editable = false;
                        scope.identificationName = $translate.instant('COMMON_ALL');
                        scope.updateSettings();
                        scope.initialize();
                    };

                    scope.updateProject = function (project){
                        scope.areaId = null;
                        scope.projectId = project.id;
                        scope.typeSelected = 'project';
                        scope.identificationName = project.name;
                        scope.editable = true;
                        scope.updateSettings();
                        scope.initialize();
                    };

                    function getVisualizationTypeIdentificator(){
                        if(scope.typeVariant){
                            if(scope.visualizationType.toUpperCase() == 'DYNAMIC'){
                                return scope.typeVariant;
                            } else return scope.visualizationType + "-" + scope.typeVariant;
                        } else return scope.visualizationType
                    }
                    scope.getStatusesForYear = function (year) {
                        if(scope.dataSource){
                            getDatasourceStatusesForYear(year);
                        } else {
                            var visualizationTypeId = getVisualizationTypeIdentificator();
                            qualityService().getStatusValuesForYear(visualizationTypeId, scope.areaId, scope.projectId, scope.assetId,year, scope.filters).then(function(statuses) {
                                scope.statusByMonth = statuses;
                            }); 
                        }
                    };

                    scope.getStatusesForMonthEvent = function($event) {
                        const month = $event.originalEvent.detail
                        scope.getStatusesForMonth(month)
                    }
                    scope.getStatusesForMonth = function(month){
                        console.log("getStatusesForMonth", month)

                        scope.selectedMonth = moment().month(month).format('MMM');
                        scope.selectedMonthNumber = month;
                        scope.daysInMonth = moment().month(month).daysInMonth();
                        var sqlMonth = month + 1; // Javasscript month is 0 index based, so we will add 1.
                        if(scope.dataSource){
                            getDatasourceStatusesForMonth(month);
                        } else if(scope.automatic){                           
                            qualityService().getStatusDeviationValuesForMonth(scope.deviationTypeId, scope.assetId, sqlMonth, scope.selectedYear, scope.filters).then(function (statuses) {
                                scope.statuses = statuses;
                            });
                        } else {
                            //input: visualizationType, areaId, projectId, month, year
                            var visualizationTypeId = getVisualizationTypeIdentificator();
                            qualityService().getStatusValuesForMonth(visualizationTypeId, scope.areaId, scope.projectId, scope.assetId, sqlMonth, scope.selectedYear, scope.filters).then(function (statuses) {
                                scope.statuses = statuses;
                            });  
                        }  
                    };

                    scope.getAutomaticStatusesForYear = function (year) {
                        qualityService().getStatusDeviationValuesForYear(scope.deviationTypeId, scope.assetId, scope.selectedYear, scope.filters).then(function (statuses) {
                            scope.statusByMonth = statuses;
                        });
                    }

                    scope.initialize = function(){
                        scope.automatic = false;
                        if(!scope.labelsettings){
                            if(scope.visualizationType == "GREENCROSS"){
                                scope.labelsettings = {
                                    valuelabels: [
                                        {value:0, label:$translate.instant('COMMON_CLEAR'), show:true},
                                        {value:1, label:$translate.instant('DASHBOARD_NO_INJURIES'), show:true},
                                        {value:2, label:$translate.instant('DASHBOARD_FIRST_AID_INJURIES'), show:true},
                                        {value:3, label:$translate.instant('DASHBOARD_RECORDABLE_INJURIES'), show:true},
                                        {value:4, label:$translate.instant('DASHBOARD_SERIOUS_INCIDENTS'), show:true}
                                    ]
                                }
                            } else {
                                scope.labelsettings = {
                                    valuelabels: [
                                        {value:0, label:'', show:true},
                                        {value:1, label:'', show:true},
                                        {value:2, label:'', show:true},
                                        {value:3, label:'', show:true},
                                        {value:4, label:'', show:true}
                                    ]
                                }
                            }
                        }
                        
                        scope.selectedYear = moment().year();
                        scope.currentYear = moment().year();
                        scope.selectedMonth = moment().format('MMM');
                        scope.selectedMonthNumber = moment().month();
                        scope.daysInMonth = moment().daysInMonth();
                        scope.getStatusesForYear(moment().year());
                        scope.getStatusesForMonth(moment().month());
                        
                    };
                    
                    scope.initializeAutomatic = function(){
                        scope.automatic = true;
                        scope.selectedYear = moment().year();
                        scope.currentYear = moment().year();
                        scope.selectedMonth = moment().format('MMM');
                        scope.selectedMonthNumber = moment().month();
                        scope.daysInMonth = moment().daysInMonth();
                        scope.getAutomaticStatusesForYear(moment().year());
                        scope.getStatusesForMonth(moment().month());
                        getDeviationTypeSeverityLabels();
                    };

                    function setLabelSettings(){
                        scope.labelsettings.projectId = scope.projectId;
                        scope.labelsettings.assetId = scope.assetId;
                        scope.labelsettings.areaId = scope.areaId;
                        scope.labelsettings.deviationTypeId = scope.deviationTypeId;
                        scope.labelsettings.typeSelected = scope.typeSelected;
                        scope.labelsettings.identificationName = scope.identificationName;
                        scope.labelsettings.visualizationType = scope.visualizationType;
                        scope.labelsettings.typeVariant = scope.typeVariant;
                    }

                    function getDeviationTypeSeverityLabels(){
                        if(!scope.deviationTypeId) return;

                        deviationService().getDeviationTypeConfiguration(scope.deviationTypeId).then(function (configuration) {
                            if(configuration.severities){
                                var severitylabels = [
                                    {value:0, label:'', show:true},
                                    {value:1, label:'', show:true},
                                    {value:2, label:'', show:true},
                                    {value:3, label:'', show:true},
                                    {value:4, label:'', show:true}
                                ];
                            
                                for(var i=0; i< configuration.severities.length; i++){
                                    if(configuration.severities[i].severity == 0){
                                        severitylabels[1].label = configuration.severities[i].customLanguageLabel.label;
                                    } else if(configuration.severities[i].severity == 1){
                                        severitylabels[2].label = configuration.severities[i].customLanguageLabel.label;
                                    } else if(configuration.severities[i].severity == 2){
                                        severitylabels[3].label = configuration.severities[i].customLanguageLabel.label;
                                    } else if(configuration.severities[i].severity == 3){
                                        severitylabels[4].label = configuration.severities[i].customLanguageLabel.label;
                                    }
                                }    
                            }
                            scope.labelsettings = {
                                valuelabels: severitylabels,
                                dataSource: scope.dataSource,
                                filters: scope.filters,
                                formula: scope.formula,
                                typeVariant: scope.typeVariant
                            };
                        });

                    }

                    scope.selectConfiguration = function () {
                        setLabelSettings();

                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'colorlabelConfiguration.html',
                            controller: 'colorlabelConfigurationController',
                            resolve: {
                                configuration: function () {
                                    return scope.labelsettings;
                                }
                            }
                        });
    
                        modalInstance.result.then(function (configuration) {
                            // scope.labelsettings = configuration;
                            
                            if(configuration.formula){
                                scope.formula = configuration.formula;
                            }
                            scope.labelsettings = {
                                valuelabels: configuration.valuelabels,
                                dataSource: configuration.dataSource,
                                filters: configuration.filters,
                                formula: scope.formula,
                                typeVariant: configuration.typeVariant
                            };
                            scope.projectId = configuration.projectId;
                            scope.assetId = configuration.assetId;
                            scope.areaId = configuration.areaId;
                            scope.typeSelected = configuration.typeSelected;
                            scope.typeVariant = configuration.typeVariant;
                            scope.identificationName = configuration.identificationName;
                            if(scope.typeSelected == 'all'){
                                scope.editable = false;
                            } else {
                                scope.editable = true;
                            }
                            if(configuration.dataSource){
                                scope.dataSource = configuration.dataSource;
                                scope.filters = configuration.filters;
                                scope.editable = false;
                            } else {
                                scope.dataSource = null;
                                scope.filters = configuration.filters;
                                if(scope.filters){
                                    scope.identificationName = "";
                                }
                            }
                            if(configuration.deviationTypeId){
                                scope.deviationTypeId = configuration.deviationTypeId;
                                scope.updateSettings();
                                scope.initializeAutomatic();        
                            } else {
                                scope.deviationTypeId = null;
                                scope.updateSettings();  
                                scope.initialize();
                            }                                                
                        }, function(res){});
                    };

                    
                    scope.setDisplayYear = function($event){
                        const selectedYear = $event.originalEvent.detail
                        console.log("kpibase selected year", selectedYear)
                        scope.selectedYear = selectedYear
                        if(scope.automatic){
                            scope.getAutomaticStatusesForYear(scope.selectedYear);
                            scope.getAutomaticStatusesForMonth(scope.selectedMonthNumber);
                        }    
                        else {
                            scope.getStatusesForYear(scope.selectedYear);
                            scope.getStatusesForMonth(scope.selectedMonthNumber);
                        }
                    };
                    
                    function checkSettings() {
                        scope.typeSelected = "asset";
                        if (scope.settings) {
                            if (scope.settings.areaId) {
                                scope.areaId = scope.settings.areaId;
                                scope.typeSelected = "area";
                                scope.editable = true;
                            } else if (scope.settings.projectId) {
                                scope.projectId = scope.settings.projectId;
                                scope.typeSelected = "project";
                                scope.editable = true;
                            } else if (scope.settings.assetId) {
                                scope.assetId = scope.settings.assetId;
                                scope.typeSelected = "asset";
                                scope.editable = true;
                            } else {
                                scope.editable = false;
                                if(scope.settings.typeSelected){
                                    scope.typeSelected = scope.settings.typeSelected;
                                }
                            }
                            if (scope.settings.identificationName) {
                                scope.identificationName = scope.settings.identificationName;
                            } else if(scope.settings.areaName){
                                scope.identificationName = scope.settings.areaName;
                            }
                            if (scope.settings.theme) {
                                scope.theme = scope.settings.theme;
                            }
                            if (scope.settings.title) {
                                scope.title = scope.settings.title;
                            } else {
                                scope.title = "";
                            }
                            if (scope.settings.labelsettings) {
                                scope.labelsettings = scope.settings.labelsettings;
                                if(scope.settings.labelsettings.dataSource){
                                    scope.editable = false;
                                    scope.identificationName = "";
                                    scope.dataSource = scope.settings.labelsettings.dataSource;
                                    scope.formula = scope.settings.labelsettings.formula;
                                }
                                scope.filters = scope.settings.labelsettings.filters;
                                if(scope.filters){
                                    scope.identificationName = "";
                                }
                            }   
                            if(scope.settings.typeVariant){
                                scope.typeVariant = scope.settings.typeVariant;
                            }
                            if(scope.settings.deviationTypeId){
                                scope.deviationTypeId = scope.settings.deviationTypeId;
                                scope.initializeAutomatic();
                            } else {
                                scope.initialize();
                            }              
                        } else {
                            assetService.getAllAssets().then(function (assets) {
                                scope.assets = assets;
                                if (scope.boardAssetId) {
                                    var intAssetId = parseInt(scope.boardAssetId) ;
                                    var boardAsset = scope.assets.find(a => a.id == intAssetId)
                                    if(boardAsset){
                                        scope.updateAsset(boardAsset);
                                    } else {
                                        scope.initialize();
                                    }
                                }
                            });
                        }
                        
                                           
                    }
                    scope.changeTheme= function(theme){
                        scope.theme = theme;
                        scope.updateSettings();
                    }

                    scope.updateSettings = function(){
                        var componentSettings = {
                            title: scope.title,
                            areaId: scope.areaId,
                            projectId: scope.projectId,
                            assetId: scope.assetId,
                            deviationTypeId: scope.deviationTypeId,
                            identificationName: scope.identificationName,
                            typeSelected: scope.typeSelected,
                            typeVariant: scope.typeVariant,
                            theme: scope.theme,
                            labelsettings: scope.labelsettings
                        };
                        scope.settingsChangeHandler({ settings: componentSettings });
                    };  
                    
                    // Datasource related functionality
                    
                    scope.subscribe("DataValueAdded", function (value) {
                        if (!scope.dataSource) return;
                        if (scope.dataSource.id === value.dataSourceId) {
                            if (dateInSelectedMonth(value.valueDate)) {
                                getDatasourceStatusesForMonth(scope.selectedMonthNumber);
                                getDatasourceStatusesForYear(scope.selectedYear);
                            } else {
                                getDatasourceStatusesForYear(scope.selectedYear);
                            }
                        }
                    });
                    scope.subscribe("DataValueUpdated", function (value) {
                        if (!scope.dataSource) return;
                        if (scope.dataSource.id === value.dataSourceId) {
                            if (dateInSelectedMonth(value.valueDate)) {
                                getDatasourceStatusesForMonth(scope.selectedMonthNumber);
                                getDatasourceStatusesForYear(scope.selectedYear);
                            } else {
                                getDatasourceStatusesForYear(scope.selectedYear);
                            }
                        }
                    });
                    scope.subscribe("DataValueDeleted", function (value) {
                        if (!scope.dataSource) return;
                        if (scope.dataSource.id === value.dataSourceId) {
                            if (dateInSelectedMonth(value.valueDate)) {
                                getDatasourceStatusesForMonth(scope.selectedMonthNumber);
                                getDatasourceStatusesForYear(scope.selectedYear);
                            } else {
                                getDatasourceStatusesForYear(scope.selectedYear);
                            }
                        }
                    });
                    function dateInSelectedMonth(utcDate) {
                        if (!scope.selectedMonthNumber || !scope.selectedYear) {
                            return true;
                        }
                        if (moment(utcDate).month == scope.selectedMonthNumber 
                            && moment(utcDate).year == scope.selectedYear) {
                            return false;
                        } else {
                            return true;
                        }
                    }

          

                    function getDatasourceStatusesForYear(year) {
                        
                        var options = {
                            groupByInterval: "month",
                            timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                            timePeriod: getTimePeriodYear(year), //scope.timePeriod.period,
                            filters: scope.filters,
                            formula: scope.formula
                        };
                        dataService().getTargetValuesForPeriod(scope.dataSource.id, options).then(function (values) {
                            if (values) {
                                scope.statusByMonth = [0,0,0,0,0,0,0,0,0,0,0,0];
                                for(var i=0; i<values.length; i++) {
                                    scope.statusByMonth[values[i].month-1] = calculateStatusForMonth(values[i].value, values[i].month);
                                }
                                
                                
                            }
                        });
                    }
                    function getDatasourceStatusesForMonth(month) {
                        var options = {
                            groupByInterval: "day",
                            timeZone: timeService.getUserTimeZone(), // Which timezone we would like the result to be calculated in.
                            timePeriod: getTimePeriodMonth(month), //scope.timePeriod.period,
                            filters: scope.filters,
                            formula: scope.formula
                        };
                        dataService().getTargetValuesForPeriod(scope.dataSource.id, options).then(function (values) {
                            scope.statuses = [];
                            var daysInPeriod = timeService.getDurationInDays(options.timePeriod.from, options.timePeriod.to);
                            for(var d=0; d<daysInPeriod; d++){
                                scope.statuses.push(calculateStatusForDay(0, d+1)); //default no values found
                            }
                            if (values) {
                                for(var i=0; i<values.length; i++) {
                                    var day = moment(values[i].valueDate).date();
                                    scope.statuses[day-1] = calculateStatusForDay(values[i].value, day);
                                }                 
                            }
                        });
                    }

                    function getTimePeriodYear(year){
                        var currentyear = moment().year();
                        if(year == currentyear){
                            return timeService.getTimePeriod("YTD");
                        } else {
                            var startDate = moment().year(year).startOf("year");
                            var endDate = moment(startDate).endOf("year");
                            var timePeriod = {
                                from: startDate.utc().toDate().toJSON(),
                                to: endDate.utc().toDate().toJSON()
                            };
                            return timePeriod;
                        }
                    }
                    function getTimePeriodMonth(month){
                        var currentyear = moment().year();
                        var currentmonth = moment().month();
                        var startDate = moment().year(scope.selectedYear).month(month).startOf("month");
                        var endDate = moment().year(scope.selectedYear).month(month).endOf("month");
                        if(scope.selectedYear === currentyear && currentmonth === month){
                            endDate = moment().endOf('day');
                        }
                        var timePeriod = {
                            from: startDate.utc().toDate().toJSON(),
                            to: endDate.utc().toDate().toJSON()
                        };
                        return timePeriod;
                        
                    }
                    function calculateStatusForMonth(totalSumMonth, month){
                        if(month){
                            // AVERAGE
                            var dailyAverage = totalSumMonth/scope.daysInMonth;
                            return calculateStatusForDay(dailyAverage, 1);
                        } else return 0; //undefined
                    }

                    function calculateStatusForDay(totalSumDay, day){
                        if(day){
                            var status = 0; //undefined
                            for (let i = 0; i < scope.dataSource.targets.length; i++) {
                                if (scope.dataSource.targets[i].operator.dbCode === '<') {
                                    if(totalSumDay < parseFloat(scope.dataSource.targets[i].targetValue)){
                                        status = convertColorClassToColorCode(scope.dataSource.targets[i].colorStyleClass);
                                        break;
                                    }
                                } else if (scope.dataSource.targets[i].operator.dbCode === '<=') {
                                    if(totalSumDay <= parseFloat(scope.dataSource.targets[i].targetValue)){
                                        status = convertColorClassToColorCode(scope.dataSource.targets[i].colorStyleClass);
                                        break;
                                    }
                                } else if (scope.dataSource.targets[i].operator.dbCode === '>=') {
                                    if(totalSumDay >= parseFloat(scope.dataSource.targets[i].targetValue)){
                                        var newStatus = convertColorClassToColorCode(scope.dataSource.targets[i].colorStyleClass);
                                        if(status > 0 && status < newStatus){
                                            status = newStatus;
                                            break;
                                        }
                                        status = newStatus;
                                    }
                                } else if (scope.dataSource.targets[i].operator.dbCode === '>') {
                                    if(totalSumDay > parseFloat(scope.dataSource.targets[i].targetValue)){
                                        var currentStatus = convertColorClassToColorCode(scope.dataSource.targets[i].colorStyleClass);
                                        if(status > 0 && status < currentStatus){
                                            return currentStatus;
                                        }
                                        status = currentStatus;
                                    }
                                }
                            }
                            return status;
                        }
                    }

                    function convertColorClassToColorCode(colorStyleClass){
                        if(colorStyleClass == 'greencross-green') return 1;
                        if(colorStyleClass == 'greencross-blue') return 2;
                        if(colorStyleClass == 'greencross-yellow') return 3;
                        if(colorStyleClass == 'greencross-red') return 4;
                        return 0; //undefined
                    }
                }
            }
        }]);
