import * as customerService from "@common/services/customers/customerService"
import toaster from "@common/components/toast"
//TODO: not in use, remove?
angular
    .module('DigiLean')
    .directive("pushNotificationSender", ['$translate',
        function ($translate) {
            return {
                templateUrl: 'pushNotificationSender.html',
                restrict: 'E',
                scope: {
                    
                },
                link: function (scope, elem, attrs) {
                    scope.title = "";
                    scope.content = "";
                    scope.users = [];
                    scope.allSelected = true;

                    customerService.getAllUsersWithAppInstallations().then(function(result){

                        scope.users = result.filter(u => u.appInstallations.length > 0);
                        // scope.users = result;
                        scope.users.forEach(u => u.selected = true);
                    });


                    scope.sendPushNotification = function () {
                       

                        var push = {
                            title: scope.title,
                            content: scope.content,
                            users: scope.users.filter(u => u.selected).map (u => u.userId)
                        }

                        toaster.success("nothing here")
                        // customerService.sendPushNotification(push).then(function (result) {
                        //     scope.title = "";
                        //     scope.content = "";

                        //     toaster.success($translate.instant('COMMON_PUSH_NOTIFICATION')+ " "+ $translate.instant('COMMON_SENT_TO')+ " "+ result + " "+$translate.instant('COMMON_USERS').toLowerCase());
                        // });

                    };

                    scope.selectedChanged = function(){
                        scope.allSelected = false;
                    };

                    scope.toggleAll = function(){
                        scope.users.forEach(u => u.selected = scope.allSelected);
                    };
                    

                }
            }
        }]);
