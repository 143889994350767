﻿import toaster from "@common/components/toast";

var DigiLean = angular.module('DigiLean');
DigiLean.controller('dataSourceController', ['$scope', '$stateParams', '$filter', '$translate', 'dataListService', 'dataSourceService', 
    function (                                $scope,   $stateParams,   $filter,   $translate,   dataListService,   dataSourceService) {
        $scope.dataSourceId = parseInt($stateParams.id);

        $scope.registrationSettings = null;
        $scope.liveTrendSettings = null;

        var selectedDataListId = null;
        $scope.selectedDataList = null;
        $scope.datalists = [];
        var tags = [];

        dataSourceService().get($scope.dataSourceId).then(function (dataSource) {
            $scope.dataSource = dataSource;
            $scope.liveTrendSettings = {
                dataSource: dataSource
            }
            $scope.registrationSettings = {
                dataSource: dataSource
            }
            updateConfigurationData($scope.dataSource);
        });

        $scope.propertyChanged = function () {
            dataSourceService().update($scope.dataSource.id, $scope.dataSource).then(function (source) {
                sendDataSourceUpdated();
            });
        }

        $scope.allAttributeTypes = [
            { id: "text", label: "Small text", languageCode: "ADMIN_DATA_DATASOURCE_SMALLTEXT" },
            { id: "description", label: "Larger text", languageCode: "ADMIN_DATA_DATASOURCE_LARGERTEXT", sourceColumn: "description" },
            { id: "number", label: "Number", languageCode: "ADMIN_DATA_DATASOURCE_NUMBER" },
            { id: "bool", label: "Boolean", languageCode: "ADMIN_DATA_DATASOURCE_BOOLEAN" },
            { id: "list", label: "List", languageCode: "ADMIN_DATA_LISTS" },
            { id: "asset", label: "Asset", languageCode: "COMMON_ASSET", sourceColumn: "assetId" },
            { id: "area", label: "Area", languageCode: "COMMON_AREA", sourceColumn: "areaId" },
            { id: "project", label: "Project", languageCode: "COMMON_PROJECT", sourceColumn: "projectId" },
            { id: "user", label: "User", languageCode: "COMMON_USER" },
        ];
        $scope.attributeTypes = [
            { id: "text", label: "Small text", languageCode: "ADMIN_DATA_DATASOURCE_SMALLTEXT" },
            { id: "description", label: "Larger text", languageCode: "ADMIN_DATA_DATASOURCE_LARGERTEXT", sourceColumn: "description" },
            { id: "number", label: "Number", languageCode: "ADMIN_DATA_DATASOURCE_NUMBER" },
            { id: "bool", label: "Boolean", languageCode: "ADMIN_DATA_DATASOURCE_BOOLEAN" },
            { id: "list", label: "List", languageCode: "ADMIN_DATA_LISTS" },
            { id: "asset", label: "Asset", languageCode: "COMMON_ASSET", sourceColumn: "assetId" },
            { id: "area", label: "Area", languageCode: "COMMON_AREA", sourceColumn: "areaId" },
            { id: "project", label: "Project", languageCode: "COMMON_PROJECT", sourceColumn: "projectId" },
            { id: "user", label: "User", languageCode: "COMMON_USER", sourceColumn: "userId" },
        ];

        $scope.dimensions = [
            { id: "dimension", label: "First dimension", isConfigured: false, isFlexible: true },
            { id: "dimension2", label: "Second dimension", isConfigured: false, isFlexible: true },
            { id: "dimension3", label: "Third dimension", isConfigured: false, isFlexible: true },
            { id: "dimension4", label: "Fourth dimension", isConfigured: false, isFlexible: true },
            { id: "description", label: "Description", isConfigured: false, isFlexible: false },
            { id: "areaId", label: "Area", isConfigured: false, isFlexible: false },
            { id: "assetId", label: "Asset", isConfigured: false, isFlexible: false },
            { id: "projectId", label: "Project", isConfigured: false, isFlexible: false },
        ];
        // $scope.selectedDimension = $scope.dimensions[0];


        function updateConfigurationData(datasource) {
            datasource.isConfigurable = true;
            if (datasource.featureKey && datasource.featureKey === 'CompetenceBall') {
                datasource.isConfigurable = false;
            }

            for (var i = 0; i < datasource.elements.length; i++) {
                var sourceColumn = datasource.elements[i].sourceColumn;
                var matching = $filter('filter')($scope.attributeTypes, { sourceColumn: sourceColumn }, true);
                if (matching.length > 0) {
                    var index = $scope.attributeTypes.indexOf(matching[0]);
                    $scope.attributeTypes.splice(index, 1);
                } else if (sourceColumn.startsWith("dimension")) {
                    var dimension = $filter('filter')($scope.dimensions, { id: sourceColumn }, true);
                    if (dimension.length === 1) {
                        $scope.selectedDimension = dimension[0];
                        dimension[0].isConfigured = true;
                    }
                }
            }

            checkAvailableDimensions();
            // Translate labels
            for (var index = 0; index < $scope.attributeTypes.length; index++) {
                $scope.attributeTypes[index].label = $translate.instant($scope.attributeTypes[index].languageCode);
            }

            // Init tags and focus
            setTimeout(function () {
                var isSetupMode = true;

                $("#datasourceTags").tagit({
                    // This will make Tag-it submit a single form value, as a comma-delimited field.
                    singleField: true,
                    singleFieldNode: $('#tagsSelected'),
                    afterTagAdded: function (event, ui) {
                        if (!isSetupMode) {
                            var dataSourceTag = {
                                id: 0,
                                tag: ui.tagLabel,
                                dataSourceId: $scope.dataSource.id
                            }
                            dataSourceService().addTag($scope.dataSource.id, dataSourceTag).then(function (newTagId) {
                                var savedTag = {
                                    id: newTagId,
                                    tag: ui.tagLabel,
                                    dataSourceId: $scope.dataSource.id
                                };
                                tags.push(savedTag);
                            })

                        }
                    },
                    beforeTagRemoved: function (event, ui) {
                        var matching = $filter('filter')(tags, { tag: ui.tagLabel }, true);
                        if (matching.length > 0) {
                            dataSourceService().deleteTag($scope.dataSource.id, matching[0].id);
                        }
                    }
                });
                if (datasource.tags) {
                    tags = datasource.tags;
                    var tagArray;
                    if (typeof stringValue === "string") {
                        tagArray = datasource.tags.split(",");
                        for (var i = 0; i < tagArray.length; i++) {
                            $("#datasourceTags").tagit('createTag', tagArray[i]);
                        }
                    } else {
                        tagArray = datasource.tags;
                        for (var i = 0; i < tagArray.length; i++) {
                            $("#datasourceTags").tagit('createTag', tagArray[i].tag);
                        }
                    }
                    isSetupMode = false;
                }
            }, 50);
            /*
            if ($scope.attributeTypes.length > 0) {
                $scope.selectedAttributeType = $scope.attributeTypes[0];
            }*/
        }

        var sendDataSourceUpdated = function () {
            var dataSourceUpdated = {
                dataSourceId: $scope.dataSource.id
            };
            $scope.publish("SendDataSourceUpdated", dataSourceUpdated);
        }
        $scope.currentStep = {};
        $scope.selectStep = function (step) {
            $scope.currentStep = step;
        }



        $scope.unitsOfTime = [
            { value: "DAY", displayName: "COMMON_TIMEPERIOD_DAY" },
            { value: "WEEK", displayName: "COMMON_TIMEPERIOD_WEEK" },
            { value: "MONTH", displayName: "COMMON_TIMEPERIOD_MONTH" },
            { value: "YEAR", displayName: "COMMON_TIMEPERIOD_YEAR" }
        ];

        dataListService().getLists().then(function (list) {
            if (list) {
                $scope.datalists = list;
            }
        });

        $scope.selectedDatalistItems = [];
        $scope.selectedDataListChanged = function() {
            var listOptions = {includeDeletedItems:false};
            dataListService().getItems($scope.selectedDataList.id, listOptions).then(function(items){
                $scope.selectedDatalistItems = items;
            });
        }

        // $scope.isDimensionSelectionDisabled = false;
        $scope.selectedAttributeTypeChanged = function (type) {
            if (type.sourceColumn && type.sourceColumn !== 'userId') {
                // auto select dimension
                var dimension = $filter('filter')($scope.dimensions, { id: type.sourceColumn }, true);
                if (dimension.length === 1) {
                    $scope.selectedDimension = dimension[0];
                    dimension[0].isConfigured = true;
                }
            } else {
                var available = $filter('filter')($scope.dimensions, { isConfigured: false }, true);
                if (available.length > 0) {
                    $scope.selectedDimension = available[0];
                    available[0].isConfigured = true;
                    if (available.length == 1) {
                        checkAvailableDimensions();
                        //$scope.attributeTypes.splice
                        //All dimension are configured
                        // var availableTypes = $filter('filter')($scope.attributeTypes, { id: !'dimension' }, false);
                        //$scope.attributeTypes = availableTypes;
                    }
                }
            }
        }


        function checkAvailableDimensions() {
            var flexibleDim = $filter('filter')($scope.dimensions, { isFlexible: true }, true);
            var available = $filter('filter')(flexibleDim, { isConfigured: false }, true);
            if (available.length == 0) {
                for (var t = 0; t < $scope.attributeTypes.length; t++) {
                    if ($scope.attributeTypes[t].id == 'text' || $scope.attributeTypes[t].id == 'user' ||
                        $scope.attributeTypes[t].id == 'list' || $scope.attributeTypes[t].id == 'bool' ||
                        $scope.attributeTypes[t].id == 'number') {
                        $scope.attributeTypes.splice(t, 1);
                        t--;
                    }
                }
            }
        }

        $scope.steps = [];
        $translate(['COMMON_INFO', 'COMMON_VALUE', 'ADMIN_DATA_DATASOURCE_ATTRIBUTES', 'ADMIN_DATA_DATASOURCE_TARGETS']).then(function (translations) {
            $scope.steps.push({ id: 'COMMON_INFO', title: translations.COMMON_INFO });
            $scope.steps.push({ id: 'ADMIN_DATA_DATASOURCE_ATTRIBUTES', title: translations.ADMIN_DATA_DATASOURCE_ATTRIBUTES });
            $scope.steps.push({ id: 'ADMIN_DATA_DATASOURCE_TARGETS', title: translations.ADMIN_DATA_DATASOURCE_TARGETS });
            $scope.currentStep = $scope.steps[0];
        });
        $scope.$on('$translateChangeSuccess', function () {
            $scope.steps[0].title = $translate.instant('COMMON_INFO');
            $scope.steps[2].title = $translate.instant('ADMIN_DATA_DATASOURCE_ATTRIBUTES');
            $scope.steps[3].title = $translate.instant('ADMIN_DATA_DATASOURCE_TARGETS');
        });

        $scope.elementId = 0;
        $scope.attributeName = "";
        $scope.isAttributeRequired = false;
        $scope.isElementRegistrationNew = true;
        $scope.deleteElement = function (element) {
            dataSourceService().deleteElement(element.id).then(function () {
                var index = $scope.dataSource.elements.indexOf(element);
                if (index > -1) {
                    $scope.dataSource.elements.splice(index, 1);
                }

                sendDataSourceUpdated();

                $scope.clearElementRegistration();
                updateConfigurationData($scope.dataSource);
            });
        };
        $scope.clearElementRegistration = function () {
            $scope.elementId = 0;
            $scope.isElementRegistrationNew = true;
            $scope.attributeName = "";
            $scope.selectedAttributeType = null;
            $scope.selectedDimension = null;
            $scope.isAttributeRequired = false;
            $scope.selectedDatalistItems = [];
            $scope.attributeTypes = [
                { id: "text", label: "Small text", languageCode: "ADMIN_DATA_DATASOURCE_SMALLTEXT" },
                { id: "description", label: "Larger text", languageCode: "ADMIN_DATA_DATASOURCE_LARGERTEXT", sourceColumn: "description" },
                { id: "number", label: "Number", languageCode: "ADMIN_DATA_DATASOURCE_NUMBER" },
                { id: "bool", label: "Boolean", languageCode: "ADMIN_DATA_DATASOURCE_BOOLEAN" },
                { id: "list", label: "List", languageCode: "ADMIN_DATA_LISTS" },
                { id: "asset", label: "Asset", languageCode: "COMMON_ASSET", sourceColumn: "assetId" },
                { id: "area", label: "Area", languageCode: "COMMON_AREA", sourceColumn: "areaId" },
                { id: "project", label: "Project", languageCode: "COMMON_PROJECT", sourceColumn: "projectId" },
                { id: "user", label: "User", languageCode: "COMMON_USER" },
            ];

            $scope.dimensions = [
                { id: "dimension", label: "First dimension", isConfigured: false, isFlexible: true },
                { id: "dimension2", label: "Second dimension", isConfigured: false, isFlexible: true },
                { id: "dimension3", label: "Third dimension", isConfigured: false, isFlexible: true },
                { id: "dimension4", label: "Fourth dimension", isConfigured: false, isFlexible: true },
                { id: "description", label: "Description", isConfigured: false, isFlexible: false },
                { id: "areaId", label: "Area", isConfigured: false, isFlexible: false },
                { id: "assetId", label: "Asset", isConfigured: false, isFlexible: false },
                { id: "projectId", label: "Project", isConfigured: false, isFlexible: false },
            ];
        }
        $scope.editElement = function (element) {
            $scope.elementId = element.id;
            $scope.isElementRegistrationNew = false;
            $scope.attributeName = element.label;
            var type = $filter('filter')($scope.allAttributeTypes, { id: element.type }, true);
            if (type.length === 1) {
                $scope.selectedAttributeType = type[0];
            }
            var dimension = $filter('filter')($scope.dimensions, { id: element.sourceColumn }, true);
            if (dimension.length === 1) {
                $scope.selectedDimension = dimension[0];
            }
            $scope.isAttributeRequired = element.isMandatory;
            if (element.dataListId) {
                var list = $filter('filter')($scope.datalists, { id: element.dataListId }, true);
                if (list.length === 1) {
                    $scope.selectedDataList = list[0];
                    $scope.selectedDataListChanged();
                }
            }

        }

        $scope.saveElement = function () {

            // Resolve which field to map to in database, IMPORTANT! needs to be correct lowerCased to map with data-Values


            if ($scope.selectedAttributeType.id == 'list') {
                selectedDataListId = $scope.selectedDataList.id;
            } else {
                selectedDataListId = null;
            }
            var attribute = {
                id: $scope.elementId,
                label: $scope.attributeName,
                type: $scope.selectedAttributeType.id,
                isMandatory: $scope.isAttributeRequired,
                sourceColumn: $scope.selectedDimension.id,
                dataListId: selectedDataListId
            }

            if ($scope.isElementRegistrationNew) {
                dataSourceService().addElement($scope.dataSource.id, attribute).then(function (element) {
                    $scope.dataSource.elements.push(element);
                    $scope.clearElementRegistration();
                    updateConfigurationData($scope.dataSource);
                    sendDataSourceUpdated();
                }, err => {
                    toaster.error(err.data.title)
                })
            } else {
                dataSourceService().updateElement($scope.elementId, attribute).then(function (element) {
                    var changedElement = $filter('filter')($scope.dataSource.elements, { id: element.id }, false);
                    var index = $scope.dataSource.elements.indexOf(changedElement[0]);
                    $scope.dataSource.elements[index] = element;
                    $scope.clearElementRegistration();
                    updateConfigurationData($scope.dataSource);
                    sendDataSourceUpdated();
                }, err => {
                    toaster.error(err.data.title)
                })
            }

        };
    }]);