import * as customerService from "@common/services/customers/customerService"
import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .controller("selectedProjectController", ['$scope', '$state', '$stateParams', '$filter', '$translate', '$uibModal', 'contactlistService', 'projectService', 'modalService',
        function                              ($scope,   $state,   $stateParams,   $filter,   $translate,   $uibModal,   contactlistService,   projectService,   modalService) {

            $scope.project = null;
            
            $scope.owner = {};
            $scope.contact = null;
            $scope.contacts = [];
            $scope.qcontact = "";

            $scope.customers = null;
            $scope.customer = null;
            $scope.q = null;

            $scope.selectedStatusOption = {};

            $scope.projectId = parseInt($stateParams.id);
            $scope.canEdit = false;
            $scope.canEditMilestones = true;
            $scope.titlehaserror = false;
            $scope.participants = [];
            $scope.users = [];

            $scope.activityLog = [];

            $scope.imageId = null;
            $scope.editImage = false;
            $scope.imageUrl = null;

            get();
            
            $scope.setImageViewMode = function () {
                $scope.editImage = false;
            };
            $scope.setImageEditMode = function () {
                $scope.editImage = true;
            };

            $scope.canEdit = true;

            $scope.goToProjectHub = function () {
                $state.go('projectDetails', { id: $scope.projectId }, { reload: false });
            }

            $scope.createNewCustomer = function(customername){
                var customer = {
                    projectId: $scope.projectId,
                    name: customername
                };

                projectService().createNewCustomer(customer).then(function (newcustomer) {
                    $scope.updateCustomer(newcustomer);
                });
            };

            $scope.updateCustomer = function(customer){
                $scope.customer = customer;
                projectService().updateCustomer($scope.projectId, customer.id).then(function (registeredcustomer) {
                });   
            };

            $scope.newCustomer = function (newname) {
                $translate(['COMMON_CANCEL', 'COMMON_SUBMIT', 'ADMIN_MESSAGE_NEW_CUSTOMER']).then(function (translations) {
                    var modalOptions = {
                        closeButtonText: translations.COMMON_CANCEL,
                        actionButtonText: translations.COMMON_SUBMIT,
                        headerText: translations.COMMON_SUBMIT + ' ' + newname + '?',
                        bodyText: translations.ADMIN_MESSAGE_NEW_CUSTOMER
                    };
        
                    modalService.showModal({}, modalOptions).then(function (result) {
                        var registration = {
                            id: 0,
                            name: newname
                        };
                        projectService().createNewCustomer(registration).then(function (customer) {
                            $translate('ADMIN_MESSAGE_CUSTOMER_CREATED').then(function (msg) {
                                toaster.success(msg);
                                projectService().getProjectCustomers().then(function (customers) {
                                    $scope.customers = customers;
                                    var matching = $filter('filter')($scope.customers, { id: customer.id }, true);
                                    if (matching.length > 0) {
                                        $scope.customer = matching[0];
                                        $scope.q = null;
                                    }
                                });
                            }, function (translationId) {
                                $scope.headline = translationId;
                            });
                        });
                    });
        
                }); 
            };

            $scope.updateContact = function(contact){
                if($scope.contact){
                    $scope.contact.isSelected = false;
                }
                $scope.contact = contact;
                $scope.contact.isSelected = true;
                contactlistService().updateProjectContactPerson(contact.id,$scope.projectId).then(function (registeredcontact) {
                });   
            };

            
            // File upload
            $scope.uploadedFiles = []; // For uploading new attachments, will be connected to dropzone.
            $scope.imageUploaded = function (file) {
                $scope.imageId = file.id;
                projectService().saveImage($scope.projectId, file.id).then(function(result) {
                    $scope.imageUrl = result.imagePreviewUrl;
                    $scope.editImage = false;
                });
            };

            $scope.delete = function () {
                $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_PROJECT_CONFIRMATION']).then(function (translations) {
                    var modalOptions = {
                        closeButtonText: translations.COMMON_CANCEL,
                        actionButtonText: translations.COMMON_DELETE,
                        headerText: translations.COMMON_DELETE + ' ' + $scope.project.name + '?',
                        bodyText: translations.ADMIN_MESSAGE_DELETE_PROJECT_CONFIRMATION 
                    };
    
                    modalService.showModal({}, modalOptions).then(function (result) {    
                        projectService().deleteProject($scope.projectId).then(function (deletedProject) {
                            $state.go('myspace');
                        });
                    });
                });
                
            };

            $scope.updateEstimatedStartDate = function($event) {
                const date = $event.originalEvent.detail
                $scope.project.estimatedStartDate = date
                projectService().updateEstimatedStartDate($scope.projectId, date).then(function (date) {
                })
            }

            $scope.updateStartDate = function($event) {
                const date = $event.originalEvent.detail
                $scope.project.startDate = date
                projectService().updateStartDate($scope.projectId, date).then(function (date) {
                });
            };

            $scope.updateEstimatedEndDate = function($event) {
                const date = $event.originalEvent.detail
                $scope.project.estimatedEndDate = date
                projectService().updateEstimatedEndDate($scope.projectId, date).then(function (date) {
                });
            };

            $scope.updateEndDate = function($event) {
                const date = $event.originalEvent.detail
                $scope.project.endDate = date
                projectService().updateEndDate($scope.projectId, date).then(function (date) {
                });
            };

            $scope.updateProjectName = function(name) {
                projectService().updateProjectName($scope.projectId, name).then(function (date) {
                });
            };

            $scope.updateProjectDescription = function(description) {
                projectService().updateProjectDescription($scope.projectId, description).then(function (date) {
                });
            };
            

            function updateParticipants(users) {
                $scope.project.hasCustomizedGroup = true;
                // Set up users to select from
                $scope.users = $scope.allUsers.slice();
                $scope.participants = users;
                // Remove current participant so we can refresh the list
                for (var i = 0; i < users.length; i++) {
                    var matchingUser = $filter('filter')($scope.users, { userId: users[i].userId }, true);
                    if (matchingUser.length > 0) {
                        var index = $scope.users.indexOf(matchingUser[0]);
                        $scope.users.splice(index, 1);
                    }
                }
            }

            function get() {
                projectService().getProjectInfo($scope.projectId).then(function (project) {
                    $scope.project = project;
                    if ($scope.project.estimatedStartDate) {
                        $scope.project.estimatedStartDate = moment($scope.project.estimatedStartDate).toDate();
                    }
                    if ($scope.project.startDate) {
                        $scope.project.startDate = moment($scope.project.startDate).toDate();
                    }
                    if ($scope.project.estimatedEndDate) {
                        $scope.project.estimatedEndDate = moment($scope.project.estimatedEndDate).toDate();
                    }
                    if ($scope.project.endDate) {
                        $scope.project.endDate = moment($scope.project.endDate).toDate();
                    }
                    $scope.imageId = $scope.project.imageId;
                    $scope.imageUrl = $scope.project.imagePreviewUrl;
                    if (!$scope.imageUrl) {
                        $scope.editImage = true;
                    }

                    $scope.statusOptions = projectService().getStatus();
                    $scope.selectedStatusOption = $filter('filter')($scope.statusOptions, { dbCode: $scope.project.status }, true)[0];
                    
                    getAllUsers($scope.project.ownerUserId);
                    setProjectCustomer();

                    contactlistService().getList().then(function (items) {
                        $scope.contacts = items;
                        setProjectContact();
                    });
                });
            }

            function setProjectCustomer(){
                if ($scope.project && $scope.project.projectCustomer && $scope.customers) {
                    var matching = $filter('filter')($scope.customers, { id: $scope.project.projectCustomerId }, true);
                    if (matching.length > 0) {
                        $scope.selectedCustomer = matching[0];
                        $scope.customer = matching[0];
                        $scope.customer.isSelected = true;                      
                    }    
                } 
            } 

            function setProjectContact(){
                contactlistService().getProjectContactPerson($scope.projectId).then(function (contact) {
                    if(contact){
                        var matching = $filter('filter')($scope.contacts, { id: contact.id }, true);
                        if (matching.length > 0) {
                            $scope.contact = matching[0];
                            $scope.contact.isSelected = true;           
                        } 
                    }
                });
            } 

            $scope.clearContact = function () {
                contactlistService().deleteProjectContactPerson($scope.projectId).then(function () {
                    if($scope.contact){
                        $scope.contact.isSelected = false;
                    }
                    $scope.contact = null;
                });
            };    

            $scope.newContact = function (name) {
                var suggestedName = "";
                var suggestedCompany = "";
                if(name){
                    suggestedName = name;
                }
                if($scope.customer){
                    suggestedCompany = $scope.customer.name;
                }
                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newContactPersonForm.html',
                    controller: 'newContactPersonModalController',
                    resolve: {
                        contact: function () {
                            return {
                                id: 0,
                                firstName: suggestedName,
                                lastName: "",
                                title: "",
                                email: "",
                                phoneNumber: "",
                                companyName: suggestedCompany
                            };
                        }
    
                    }
                });
    
                // When user has created new contact
                modalInstance.result.then(function (newcontact) {
                    contactlistService().getList().then(function (items) {
                        $scope.contacts = items;
                        var matching = $filter('filter')($scope.contacts, { id: newcontact.id }, true);
                        if (matching.length > 0) {
                            $scope.updateContact(matching[0]);           
                        };
                    });
                }, function () {
                });
            };

            projectService().getProjectCustomers().then(function (customers) {
                $scope.customers = customers;  
                setProjectCustomer();         
            });

            $scope.statusChanged = function (status) {
                projectService().updateProjectStatus($scope.projectId, status.dbCode).then(function (result) {
     
                });
            }

            $scope.updateProjectNumber = function (number) {
                projectService().updateProjectNumber($scope.projectId, number).then(function (result) {
     
                });
            }
            
            function getAllUsers(ownerId) {
                customerService.getAllUsers().then(function (users) {
                    $scope.users = users;
                    if (ownerId) {
                        var matchingUser = $filter('filter')($scope.users, { userId: ownerId }, true);
                        if (matchingUser.length > 0) {
                            $scope.owner = matchingUser[0];
                        }
                    }
                });
            }

            $scope.ownerChanged = function (owner) {
                if (owner) {

                projectService().updateOwner($scope.projectId, owner).then(function (registeredowner) {
                });
            }
        }
        }]);
