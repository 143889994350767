import { getCurrentLang } from "@common/services/language/DigiLeanLang"

const DigiLean = angular.module('DigiLean')
DigiLean.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', '$httpProvider', '$translateProvider', '$compileProvider', 'tmhDynamicLocaleProvider',
    function ($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider, $translateProvider, $compileProvider, tmhDynamicLocaleProvider) {
        // default route
        $urlRouterProvider.otherwise("/personaldashboard");

        $locationProvider.html5Mode(false).hashPrefix('');

        // Setup language and translations
        tmhDynamicLocaleProvider.localeLocationPattern('i18n/{{locale}}.js');
        
        // language async loader
        $translateProvider.useLoader('DigiLeanAngularLoader')
        // sync load lang should be already loaded
        const currLang = getCurrentLang()
        
        $translateProvider.translations(currLang.code, currLang.store)

        // Routing
        $stateProvider 
            .state('strategymap', {
                url: "/strategymap",
                templateUrl: 'strategyviewer.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('strategyTaskList', {
                url: "/strategyTaskList",
                templateUrl: 'strategyTaskUrl.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('productionboard', {
                url: "/productionboard",
                templateUrl: 'production.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('systemdashboard', {
                url: "/systemdashboard",
                templateUrl: 'systemdashboard.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('dashboard', {
                url: "/dashboard/:id?mode&interval",
                templateUrl: 'dashboardDesigner.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('dashboarddesigner', {
                url: "/dashboarddesigner",
                templateUrl: 'dashboardDesigner.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('personaldashboard', {
                url: "/personaldashboard",
                templateUrl: 'myprofile.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('settings', {
                url: "/settings?tab",
                templateUrl: 'profileSettings.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('teamspace', {
                url: "/teamspace",
                templateUrl: 'teamdashboard.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('myspace', {
                url: "/myspace",
                templateUrl: 'myprofile.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('inbox', {
                url: "/inbox?tab&notificationid",
                templateUrl: 'inbox.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('hours', {
                url: "/hours",
                templateUrl: 'hoursHub.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('myhours', {
                url: "/myhours",
                templateUrl: 'myhours.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('hoursinvoicereport', {
                url: "/hours/invoicereport",
                templateUrl: 'invoiceReportPage.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('newFlexiboard', {
                url: "/flexiboard/newFlexiboard",
                templateUrl: 'newFlexiboard.html',
                params: {
                    assetid: "0",
                    strategygoalid: "0",
                    source: "",
                },
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            /*deprecated*/
            .state('newboardsetting', {
                url: "/newboard/:id/:destination",
                params: {
                    id: "0",
                    destination: "board",
                    assetid: "0"
                },
                templateUrl: 'newBoardSetting.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('flexiboards', {
                url: "/flexiboards",
                templateUrl: 'flexiboards.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('flexiboard', {
                url: "/flexiboard/:id?mode&interval",
                templateUrl: 'flexiboard.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('board', {
                url: "/board/:id?mode",
                templateUrl: 'boardresolver.html'
            })
            .state('tasksearch', {
                url: "/tasksearch",
                templateUrl: 'taskSearch.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('personal', {
                url: "/personal/:id",
                params: {
                    id: ""
                },
                templateUrl: 'personalboard.html'
            })
            .state('myprofile', {
                url: "/myprofile",
                templateUrl: 'myprofile.html'
            })
            .state('boarddesigner', {
                url: "/boarddesigner/:id",
                templateUrl: 'boardDesignerResolver.html',
                data: { destination: ':destination' }
            })
            .state('A3', {
                url: "/A3",
                templateUrl: 'a3.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('a3templates', {
                url: "/a3templates",
                templateUrl: 'a3templates.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('a3templateDesigner', {
                url: "/a3templates/layout/:id",
                templateUrl: 'a3templateLayout.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('a3reports', {
                url: "/a3reports",
                templateUrl: 'a3reports.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('benefitsrealization', { 
                url: "/benefitsrealization",
                templateUrl: 'benefitsRealization.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('timebenefitsrealization', { 
                url: "/timebenefitsrealization",
                templateUrl: 'timeBenefitsRealization.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('a3designer', {
                url: "/a3designer/:id",
                templateUrl: 'a3designer.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('a3wizard', {
                url: "/A3/:id",
                templateUrl: 'a3wizard.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })

            .state('a3PreviewDesigner', {
                url: "/A3/preview/:id/version/:version",
                params: {
                    id: "0",
                    version: "0"
                },
                templateUrl: 'a3preview.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('deviationstatus', {
                url: "/deviationstatus",
                templateUrl: 'deviationStatus.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('deviationevaluation', {
                url: "/deviationevaluation",
                templateUrl: 'deviationEvaluation.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('deviationprojectstatus', {
                url: "/projectStatus/deviationprojectstatus",
                templateUrl: 'deviationProjectStatus.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('deviationongoing', {
                url: "/deviationongoing/:id",
                params: {
                    id: ""
                },
                templateUrl: 'deviationsOngoing.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('deviationsearch', {
                url: "/deviationsearch",
                templateUrl: 'deviationSearch.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('newSearchList', {
                url: "/newSearchList",
                templateUrl: 'newSearchList.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('improvement', {
                url: "/improvement",
                templateUrl: 'improvement.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('improvementstatus', {
                url: "/improvementstatus",
                templateUrl: 'improvementstatus.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('improvementongoing', {
                url: "/improvementongoing/:id",
                params: {
                    id: ""
                },
                templateUrl: 'ongoingimprovements.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            
            .state('improvementsearch', {
                url: "/improvementsearch",
                templateUrl: 'improvementsearch.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('project', {
                url: "/project",
                templateUrl: 'project.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('projectgrid', {
                url: "/project/projectGrid",
                templateUrl: 'projectGrid.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('projectportfolio', {
                url: "/project/projectportfolio",
                templateUrl: 'projectPortfolio.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('customerstatus', {
                url: "/customerstatus",
                templateUrl: 'customerstatus.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('projectDetails', {
                url: "/projectDetails/:id",
                templateUrl: 'projectDetails.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('projectboard', {
                url: "/project/board",
                templateUrl: 'projectboard.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('goalDetails', {
                url: "/goalDetails/:id",
                params: {
                    id: "0",
                    assetid: "0"
                },
                templateUrl: 'goalDetails.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('selectedPlan', {
                url: "/selectedplan",
                templateUrl: 'selectedPlan.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('selectedProject', {
                url: "/selectedproject/:id",
                templateUrl: 'selectedProject.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('teamboardplan', {
                url: "/teamboardplan/:id",
                templateUrl: 'teamboardplan.html'
            })
            .state('datalist', {
                url: "/admin/data/lists",
                templateUrl: "datalists.html"
            })
            .state('area', {
                url: "/admin/area",
                templateUrl: 'area.html'
            })
            .state('contactlist', {
                url: "/admin/contactlist",
                templateUrl: 'contactlist.html'
            })
            .state('assets', {
                url: "/admin/assets",
                templateUrl: 'assets.html'
            })
            .state('customerAdmin', {
                url: "/admin/data/lists",
                templateUrl: 'customerAdmin.html'
            })
            .state('customers', { //DigiLEAN customers
                url: "/admin/customers",
                templateUrl: 'customers.html'
            })
            .state('payingcustomers', { //DigiLEAN paying customers
                url: "/admin/payingcustomers?tab",
                params: {
                    // pageid: {
                    //     value: "",
                    //     dynamic: true
                    // }
                    tab: "billinglist",
                },
                templateUrl: 'payingCustomers.html'
            })
            .state('trials', { //DigiLEAN trials
                url: "/admin/trials",
                templateUrl: 'trials.html'
            })
            .state('adminorganization', {
                url: "/admin/organization",
                templateUrl: 'organizationAdmin.html'
            })
            .state('adminflexiboards', {
                url: "/admin/boards",
                templateUrl: 'flexiboardContainerAdmin.html'
            })
            .state('admindeviations', {
                url: "/admin/deviations",
                templateUrl: 'deviationAdmin.html'
            })
            .state('deviationType', {
                url: "/admin/deviationtype/:id",
                params: {
                    id: ""
                },
                templateUrl: 'deviationTypeDetails.html'
            })
            .state('adminlearning', {
                url: "/admin/learning",
                templateUrl: 'learningAdmin.html'
            })
            .state('learningdesigner', {
                url: "/admin/learningdesigner/:id?:pageid",
                reloadOnSearch: false,
                params: {
                    id: "",
                    pageid: {
                        value: "",
                        dynamic: true
                    }
                },
                templateUrl: 'learningDesigner.html'
            })
            .state('learningmaterialstats', {
                url: "/admin/learningmaterialstats/:id",
                reloadOnSearch: false,
                params: {
                    id: "",
                },
                templateUrl: 'learningMaterialStats.html'
            })
            .state('adminprojects', {
                url: "/admin/projects",
                templateUrl: 'projectAdmin.html'
            })
            .state('admina3', {
                url: "/admin/a3",
                templateUrl: 'a3Admin.html'
            })
            .state('adminimprovement', {
                url: "/admin/improvement",
                templateUrl: 'improvementAdmin.html'
            })
            .state('admindata', {
                url: "/admin/data?tab",
                templateUrl: 'dataAdmin.html'
            })
            .state('admindatasource', {
                url: "/admin/data/:id",
                templateUrl: 'dataSource.html'
            })
            .state('adminmanualkpiidentifier', {
                url: "/admin/data/manualKpiIdentifier",
                templateUrl: 'manualKpiIdentifierAdmin.html'
            })
            .state('dataVisualizationTypeController', {
                url: "/admin/data/manualKpiIdentifier/:type",
                templateUrl: 'dataVisualizationType.html'
            })
            
            .state('admindatalist', {
                url: "/admin/datalist/:id",
                templateUrl: 'datalist.html'
            })
            
            
            .state('users', {
                url: "/users",
                templateUrl: 'users.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('strategy', {
                url: "/strategy",
                templateUrl: 'strategy.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('strategyKPI', {
                url: "/strategy/strategyKPI",
                templateUrl: 'strategyBowlerChart.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })/* 
            .state('strategyFlexibleKPI', {
                url: "/strategy/strategyFlexibleKPI",
                templateUrl: 'strategyFlexibleBowlerChart.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })*/
            .state('learning', {
                url: "/learning",
                templateUrl: 'learning.html',
                data: { pageTitle: 'DigiLEAN.tools' }
            })
            .state('learningexternal', {
                url: "/learning/:location/:id?:pageid",
                reloadOnSearch: false,
                params: {
                    location: {
                        value: "internal"
                    },
                    id: {
                        value: ""
                    },
                    pageid: {
                        value: "",
                        dynamic: true
                    }
                },
                templateUrl: 'learningViewer.html'
            })
            .state('apiclients', {
                url: "/admin/apiclients",
                component: 'apiClients',
            })
            .state('integrations', {
                url: "/admin/integrations",
                component: 'integrationsAdmin'
            })
            .state('adminconnector', {
                url: "/admin/connector/:id",
                component: 'connectorAdmin',
            })
            .state('adminjob', {
                url: "/admin/job/:id",
                component: 'jobAdmin',
            })
            .state('adminJobPart', {
                url: "/admin/jobpart/:id",
                component: 'jobpartAdmin',
            })
            .state('connectorMonitor', {
                url: "/connector/:id",
                component: 'connectorMonitor',
            })
            .state('monitoringAdmin', {
                url: "/admin/monitor",
                component: 'monitoringAll',
            })
            .state('jobLogs', {
                url: "/joblogs/:id",
                component: 'jobLogs'
            })
            .state('languagelabels', {
                url: "/admin/language",
                templateUrl: 'languageLabelAdmin.html'
            })
            .state('userdebug', {
                url: "/userdebug",
                component: 'userComponent',
            })

        // PERFORMANCE configs!
        $compileProvider.debugInfoEnabled(false);
        
        $httpProvider.useApplyAsync(true);
        //

        if (!$httpProvider.defaults.headers.get) {
            $httpProvider.defaults.headers.get = {};
        }

        $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
        $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
        $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
        $httpProvider.interceptors.push('authInterceptorService');

        $httpProvider.defaults.useXDomain = true;
        delete $httpProvider.defaults.headers.common["X-Requested-With"];
        
    }]);

DigiLean.run(['$rootScope', '$state', function ($rootScope, $state) {
    $rootScope.$state = $state;
}]);

// Highcharts
Highcharts.setOptions({
    global: {
        useUTC: false
    }
});