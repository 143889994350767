import { hasDefaultAccess } from "@common/components/dashboard/designer/common/userAccessService"


angular.module("DigiLean")
    .directive('threeCOwnerCell', ['$filter',
        function ($filter) {
            return {
                templateUrl: "threeCOwnerCell.html",
                restrict: "E",
                scope: {
                    'ownerUserId': '<',
                    'ownerChangedHandler': '&',
                    'users': '<',
                    'userAccess' : '<?'
                },
                link: function (scope, elem, attrs) {

                    scope.clearUser = {
                        userId: ""
                    }
                    scope.$watch('ownerUserId', function (ownerUserId) {
                        if (!ownerUserId) return;
                        setOwner(ownerUserId);
                    });
                    scope.$watch('users', function (users) {
                        if (!scope.ownerUserId) return;
                        setOwner(scope.ownerUserId);
                    });
                    var setOwner = function(ownerUserId) {
                        if (!scope.users) return;
                        var owner = $filter('filter')(scope.users, { userId: ownerUserId }, true);
                        if (owner.length > 0) {
                            scope.selectedOwner = owner[0];
                        } else {
                            scope.selectedOwner = null;
                        }
                        
                    }

                    scope.canEdit = true;

                    scope.$watch('userAccess', function (userAccess) {
                        scope.canEdit = hasDefaultAccess(userAccess);
                    });
  
                    scope.cssClass = "";
                    scope.ownerChanged = function (owner) {
                        setOwner(owner.userId);
                        scope.cssClass = "animated flash";
                        setTimeout(() => {
                            scope.cssClass = "";
                        },1000);
                        scope.ownerChangedHandler({ owner: owner });
                    }
                    
                }
            }
        }]);