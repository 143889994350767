﻿import toaster from "@common/components/toast"

var DigiLean = angular.module('DigiLean');  
DigiLean.controller('repeattaskController', ['$scope', '$uibModalInstance', '$translate', 'boardTaskService', 'task',
    function ($scope, $uibModalInstance, $translate, boardTaskService, task) {
        $scope.selectedBoard = {};
        $scope.boards = [];

        $scope.repeatOptions = [
            {
                repeatType: "weekly",
                name: $translate.instant('BOARD_WEEKLY')
            },
            {
                repeatType: "everyOtherWeek",
                name: $translate.instant('BOARD_EVERY_2_WEEKS')
            },
            {
                repeatType: "monthly",
                name: $translate.instant('BOARD_MONTHLY')
            },
            {
                repeatType: "yearly",
                name: $translate.instant('COMMON_YEARLY')
            }/*,
            {
                repeatType: "dayOfMonth",
                name: $translate.instant('BOARD_DAY_OF_MONTH')
            }*/
        ];



        $scope.isProcessing = false;
        $scope.selectOption = function (option) {
            option.isSelected = !option.isSelected;
        }
        $scope.selectedRepeatOption = $scope.repeatOptions[0];
        // Default 6 months forward in time
        $scope.dueDate = moment(task.boardDate).add(6, 'months').toDate();
        

        $scope.task = task;
        $scope.ok = function () {
            $scope.selectedRepeatOption.dueDate = $scope.dueDate;
            $scope.isProcessing = true;
            boardTaskService().repeatTask($scope.task.id, $scope.selectedRepeatOption).then(function (result) {
                $scope.isProcessing = false;
                toaster.success("Task repeated succesfully");
                $uibModalInstance.close(result);
            });
        }
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel')
        }

        $scope.taskDueDateChange = function($event) {
            const date = $event.originalEvent.detail
            $scope.dueDate = date
        }
    }])