// DigiLEAN files that are shared between web and teams
// CSS

// main files
import '@common/css/colors.css';
import '@common/css/theme.css';
import '@common/css/global.css'
import '@common/css/style.css'
import '@common/css/spinkit.css'

// language
import "@common/services/language/DigiLeanAngularLang"

// services
import '@common/shared/activityService';
import '@common/shared/excelService';

import '@common/shared/mathService';
import '@common/shared/targetService';
import '@common/shared/layoutService';
import '@common/shared/modal/modalService';
import '@common/shared/navigationService';
import '@common/shared/modulesService';
import '@common/shared/pubSubService';
import '@common/shared/smartcellService';

import '@common/shared/settingsService';
import '@common/components/A3/a3Service';
import '@common/components/admin/area/areaService';
import '@common/components/admin/contactlist/contactlistService';
import '@common/shared/folderService';
import '@common/shared/notificationService';
import '@common/shared/favoriteService';
import '@common/components/dashboard/designer/appstore/smarttable/smarttableService';
import '@common/components/board/boardTaskService';

// data
import '@common/components/data/dataService';
import '@common/components/data/dataListService';
import '@common/components/data/dataSourceService';
import '@common/components/data/dataFilterService';
import '@common/components/data/timeframeSelector/timeframeSelector';
import "@common/components/data/timeframeSelector/timeframeSelector.css"
import '@common/components/data/dataseriesselector/dataseriesselectorModalController';
import '@common/components/data/dataseriesselector/dataseriesselectorStepsController';
import '@common/components/data/dataseriesselector/dataseriesselectorStepsController';
import '@common/components/data/dataseriesselector/a3selectors/a3categoryselector/a3categoryselector';
import '@common/components/data/dataseriesselector/strategygoalSelector/strategygoalSelector';
import '@common/components/data/definitions/datasourcesingle/dataSourceSingleSelector';
import '@common/components/data/definitions/datasourcegroup/dataSourceGroupSelector';
import '@common/components/data/definitions/datasourcestackedgroup/dataSourceStackedGroupSelector';
import '@common/components/data/definitions/datasourceTarget/datasourceTarget';
import '@common/components/data/latestValue/latestDataValue';
import '@common/components/data/importExportService';
import '@common/components/data/excelImport/excelImporterController';
import '@common/components/data/excelImport/excelImportValues';
import '@common/components/data/unitLabel/unitLabel';
import '@common/components/data/dataseriesselector/dataSourceFilter';
import '@common/components/data/dataseriesselector/assetselector/assetselectorcontroller';
import '@common/components/data/dataseriesselector/areaselector/areaselector';
import '@common/components/data/dataseriesselector/projectSelector/projectSelector';
import '@common/components/data/dataseriesselector/consequenceSelector/consequenceSelector';
import '@common/components/data/dataseriesselector/unitSelector/unitSelector';
import '@common/components/data/dataseriesselector/businessunitSelector/businessunitSelector';
import '@common/components/data/dataseriesselector/unitSelector/unitSelector';
import '@common/components/data/dataseriesselector/userselector/userselector';
import '@common/components/data/dataseriesselector/boardselector/boardselector';
import '@common/components/data/dataseriesselector/datalistselector/datalistselector';
import '@common/components/data/dataseriesselector/boolselector/boolselectorcontroller';
import '@common/components/data/dataseriesselector/improvementselectors/prioritystatusselector/prioritystatusselector';
import '@common/components/data/dataseriesselector/improvementselectors/improvementcategoryselector/improvementcategoryselector';
import '@common/components/data/dataseriesselector/a3selectors/a3templateselector/a3templateselector';
import '@common/components/data/dataseriesselector/deviationselectors/problemcategoryselector/problemcategoryselector';
import '@common/components/data/dataseriesselector/deviationselectors/deviationseverityselector/deviationseverityselector';
import '@common/components/data/dataseriesselector/deviationselectors/deviationtypeselector/deviationtypeselector';
import '@common/components/data/dataseriesselector/deviationselectors/problemreasonselector/problemreasonselector';
import '@common/components/data/dataseriesselector/boardselectors/boardstatusselector/boardstatusselector';
import '@common/components/data/dataseriesselector/numberselector/numberselectorcontroller';
import '@common/components/data/dataseriesselector/stringselector/stringselectorcontroller';
import '@common/components/data/dataseriesselector/dataseries-selector.css';
import "@common/components/data/datavaluesList/datavaluesListModalController"
import "@common/components/data/dataSourceSearchFilter"
import '@common/components/data/dataseriesselector/tasktagselector/tasktagselector';
import '@common/components/data/timeframeSelector/timeframeSelectorModal';

import '@common/shared/layout/clock/countdown';
import '@common/shared/layout/ibox/iboxExpandButtonController';
import '@common/shared/layout/assetSelector/assetSelectorDropdown';
import '@common/shared/layout/assetSelector/assetSelectorModalController';
import '@common/components/admin/assets/assets.css';
import '@common/shared/layout/projectSelector/projectSelectorDropdown';
import '@common/shared/layout/userSelector/userSelectorDropdown';
import '@common/shared/layout/boardTaskSelector/boardTaskSelectorDropdown';
import '@common/shared/modal/fullscreen/fullscreenWidgetController';
import '@common/shared/modal/fullscreen/fullscreenWidget.css';
import '@common/shared/filters/colorFilter';
import '@common/shared/filters/deviationSeverityTranslationFilter';
import '@common/shared/filters/improvementPriorityTranslationFilter';
import '@common/shared/filters/unsafe';

// components
import '@common/shared/dates/datePicker'
import '@common/shared/dates/datePickerSlot'
import "@common/components/customer/customerLookup"

// vue
import { defineCustomElement } from "vue"

// directives
import '@common/components/document/dropzone/dropzone'
import '@common/components/document/fileinfo/fileinfo';
import '@common/components/document/imagefileinfo/imagefileinfo';
import '@common/components/document/videofileinfo/videofileinfo';
import '@common/components/board/boardresolver/boardResolverController';
import '@common/shared/errorHandling/errorHandling'
import '@common/shared/ngEnter/ngEnter'
import "@common/shared/auth/modulesDirective"
import "@common/shared/auth/subscriptionDirective"
import '@common/shared/auth/rolesDirective';

// custom elements

import "@common/components/digilean/DigiLeanButton"
import "@common/components/digilean/DigiLeanLogo"
import "@common/components/digilean/DigiLeanInput"
import "@common/components/digilean/DigileanJsonEditor"
import "@common/components/digilean/DigileanDialog"
import "@common/components/icons/DigileanChecked"
import "@common/components/icons/DigileanFailed"
import "@common/components/icons/DigileanWorking"
import "@common/components/icons/DigiLeanOkWithErrors"

import DateTimeViewer from "@common/shared/dates/DateTimeViewer.vue"
const DateTimeViewerCe = defineCustomElement(DateTimeViewer)
customElements.define('datetime-viewer', DateTimeViewerCe)

import DateViewer from "@common/shared/dates/DateViewer.vue"
const DateViewerCe = defineCustomElement(DateViewer)
customElements.define('date-viewer', DateViewerCe)

import "@common/components/translation/translateLabel"

import UserDebug from "@common/components/users/UserDebug.vue"
customElements.define('user-debug', defineCustomElement(UserDebug))

import UserTokenViewer from "@common/components/users/UserTokenViewer.vue"
customElements.define('user-token-viewer', defineCustomElement(UserTokenViewer))

import "@common/components/appConfigDebug"

import "@common/components/admin/apiclients/apiScopesDialog"
import "@common/components/icons/fontawesome"


import SpinnerIcon from "@common/components/icons/spinnerIcon.vue"
customElements.define('spinner-icon', defineCustomElement(SpinnerIcon))

import PdfViewer from "@common/components/viewers/pdfViewer.vue"
customElements.define('pdf-viewer', defineCustomElement(PdfViewer))
import PdfDisplayerApp from "@common/components/dashboard/designer/appstore/pdfDisplayer/pdfDisplayerApp.vue"
customElements.define('pdf-displayer', defineCustomElement(PdfDisplayerApp))

import businessUnitMembers from "@common/components/admin/assets/businessUnitMembers.vue"
customElements.define('business-unit-members', defineCustomElement(businessUnitMembers))

import "@common/components/dashboard/designer/appstore/pdfDisplayer/pdfDisplayerAppNg"
import "@common/components/dashboard/designer/appstore/pdfDisplayer/pdfDisplayerApp.css"

import FileUploader from "@common/components/document/fileUploader.vue"
customElements.define('file-uploader', defineCustomElement(FileUploader))

import businessUnit from "@common/components/translation/businessUnit.vue"
customElements.define('business-unit', defineCustomElement(businessUnit))

import "@common/components/digilean/digiLeanPopup"
import "@common/components/toast/toastMaster"

// Strategy
import '@common/components/strategy/strategyService';

// dashboard 
import '@common/components/dashboard/dashboard.css';
import '@common/components/dashboard/designer/appstore/3cTable/threeCTableApp';
import '@common/components/dashboard/designer/appstore/3cTable/threeCTable';
import '@common/components/dashboard/designer/appstore/3cTable/threeCTable.css';
import '@common/components/dashboard/designer/appstore/3cTable/threeCService';
import '@common/components/dashboard/designer/appstore/3cTable/cells/threeCDateCell';
import '@common/components/dashboard/designer/appstore/3cTable/cells/threeCOwnerCell';
import '@common/components/dashboard/designer/appstore/3cTable/cells/threeCStatusCell';
import '@common/components/dashboard/designer/appstore/3cTable/cells/threeCTextCell';
import '@common/components/dashboard/designer/appstore/smartCommon/commonCellSelectionController';
import '@common/components/dashboard/designer/appstore/smartCommon/smartProgressCell/smartProgressCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartPersonCell/smartPersonCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartDateCell/smartDateCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartTextCell/smartCommonTextCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartTargetCell/smartTargetCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartLinkCell/smartLinkCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartRowNoCell/smartRowNoCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartRiskCell/smartRiskCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartRatingCell/smartRatingCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartCheckCell/smartCheckCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartNumberCellAutomatic/smartCommonNumberCellAutomatic';
import '@common/components/dashboard/designer/appstore/smartCommon/smartNumberCellAggregate/smartCommonNumberCellAggregate';
import '@common/components/dashboard/designer/appstore/smartCommon/smartNumberCell/smartCommonNumberCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartColumnWidthConfig/smartColumnWidthConfig';
import '@common/components/dashboard/designer/appstore/smartCommon/smartIconCell/smartIconCell';
import '@common/components/dashboard/designer/appstore/smartCommon/smartIconCell/predefinedIcons';
import '@common/components/dashboard/designer/appstore/smarttable/templates/smarttableNameCell/smarttableNameCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/customerCell/smarttableCustomerCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/textCell/smarttableTextCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/linkCell/smarttableLinkCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/numberCell/smarttableNumberCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/numberCellAutomatic/smarttableNumberCellAutomatic';
import '@common/components/dashboard/designer/appstore/smarttable/templates/numberCellAggregate/smarttableNumberCellAggregate';
import '@common/components/dashboard/designer/appstore/smarttable/templates/smarttableTargetCell/smarttableTargetCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/personCell/smarttablePersonCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/dateCell/smarttableDateCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/startDateCell/smarttableStartDateCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/endDateCell/smarttableEndDateCell';
import '@common/components/dashboard/designer/appstore/smarttable/templates/ownerCell/smarttableOwnerCell';
import "@common/components/dashboard/smarttable/templates/progressCell/smarttableProgressCell"
import "@common/components/dashboard/smarttable/templates/progressCell/smarttableProgressbar"
import "@common/components/dashboard/greencross/datasourceDailyKPITarget"
import '@common/components/dashboard/designer/appstore/smartActionList/smartActionListApp';
import '@common/components/dashboard/designer/appstore/smartActionList/smartActionListService';
import '@common/components/dashboard/designer/appstore/smartActionList/taskActions/taskActions';
import '@common/components/dashboard/designer/appstore/smartActionList/taskActions/taskActions.css';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/linkCell/smartactionlistLinkCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/smartactionlistStatusCell/smartactionlistStatusCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/smartActionListTitleCell/smartActionListTitleCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/smartActionListTaskdescriptionCell/smartActionListTaskdescriptionCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/responsibleCell/smartactionlistResponsibleCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/dueDateCell/smartActionListDueDateCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/startDateCell/smartactionlistStartDateCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/textCell/smartactionlistTextCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/dateCell/smartactionlistDateCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/lastModifiedDatecell/smartactionlistLastModifiedDateCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/smartactionlistTargetCell/smartactionlistTargetCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/personCell/smartactionlistPersonCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/progressCell/smartactionlistProgressCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/progressCellSubTasks/smartactionlistProgressCellSubTasks';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/numberCell/smartactionlistNumberCell';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/numberCellAggregate/smartactionlistNumberCellAggregate';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/numberCellAutomatic/smartactionlistNumberCellAutomatic';
import '@common/components/dashboard/designer/appstore/smartActionList/templates/subTasksCell/subTasksCell';
import '@common/components/dashboard/designer/appstore/deviationCategorySummary/deviationCategorySummary';
import '@common/components/dashboard/designer/appstore/actionList/actionListApp';
import '@common/components/dashboard/designer/appstore/actionList/actionListApp.css';
import '@common/components/dashboard/barchart/barchartcontroller';
import '@common/components/dashboard/designer/appstore/comparechart/comparechart';
import '@common/components/dashboard/designer/appstore/debug/debugApp';
import '@common/components/dashboard/designer/appstore/a3viewer/a3Viewer';
import '@common/components/dashboard/designer/appstore/a3viewer/a3ViewerApp';
import '@common/components/dashboard/designer/appstore/a3viewer/a3viewer.css';
import '@common/components/dashboard/designer/appstore/calendar/personalcalendar';
import '@common/components/dashboard/designer/appstore/calendar/teamcalendar';
import '@common/components/dashboard/designer/appstore/daycounter/daycounter';
import '@common/components/dashboard/designer/appstore/greencross/kpiLetterBase';
import '@common/components/dashboard/designer/appstore/imagedisplayer/imagedisplayer';
import '@common/components/dashboard/designer/appstore/messagedisplayer/messagedisplayer';
import '@common/components/dashboard/designer/appstore/quickIncidentRegistration/quickIncidentRegistrationApp';
import '@common/components/dashboard/designer/appstore/quickIncidentRegistration/quickIncidentRegistrationConfigurationController';
import '@common/shared/messages/messageAttachment';

// board
import '@common/components/board/template/newboardtemplateController';
import '@common/components/board/assetMessage/assetMessage';
import '@common/components/board/newBoard/newBoardFromTemplateController';
import '@common/components/board/postits/postitHub';
import '@common/components/board/postits/postitPredefinedTags';
import '@common/components/board/postits/timeboardPostits';
import '@common/components/board/boardDesigner/boardDesignerController';
import '@common/components/board/boardresolver/boardDesignerResolverController';
import '@common/components/board/boardDesigner/boardCategoryDetailsController';
import '@common/components/board/boardTask/boardTaskController';
import '@common/components/board/boardTask/newBoardTaskController';
import '@common/components/board/boardTask/subtask/subtaskStatus';
import '@common/components/board/taskattachments/taskattachments';
import '@common/components/board/taskattachments/taskattachment';
import '@common/components/board/productionboard/productionBoard';
import '@common/components/board/productionboard/productionBoardApp';
import '@common/components/board/productionboard/productionBoardRowConfigurationController';
import '@common/components/board/productionboard/productionBoardMandatoryFieldsController';
import '@common/components/board/productionboard/productionBoard.css';
import '@common/components/board/yearlyboard/yearlyboard';
import '@common/components/board/yearlyboard/yearlyboardDesigner';
import '@common/components/board/yearlyboard/yearlyboardRenderService';
import '@common/components/board/yearlyboard/yearlyboard.css';
import '@common/components/board/yearlyboard/monthlyRenderService';
import '@common/components/board/yearlyboard/weeklyRenderService';
import '@common/components/board/yearlyboard/boardRowProvider';
import '@common/components/board/yearlyboard/actionListRowProvider';
import '@common/components/board/yearlyboard/tasks/yearlyTask';
import '@common/components/board/yearlyboard/tasks/yearlyTask.css';
import '@common/components/board/yearlyboard/tasks/newYearlyTaskController';
import '@common/components/board/boardLayout';
import '@common/components/board/selector/threeCBoardSelector';
import '@common/components/board/selector/memberBoardSelector';
import '@common/components/board/selector/memberBoardSelector.css';
import '@common/components/board/dynamicBoard';
import '@common/components/board/smartKanban/smartKanban';
import '@common/components/board/smartKanban/smartKanbanCard';
import '@common/components/board/smartKanban/smartKanban.css';
import '@common/components/board/smartKanban/columnProviders/statusColumnProvider';
import '@common/components/board/smartKanban/columnProviders/responsibleColumnProvider';
import '@common/components/board/smartKanban/columnProviders/predefinedLabelColumnProvider';
import '@common/components/board/cloneInfo/cloneInfoController';
import '@common/components/board/parenttaskInfo/parenttaskInfoController';
import '@common/components/board/clonetask/clonedeleteController';
import '@common/components/board/clonetask/clonetaskController';
import '@common/components/board/movetask/movetaskController';
import '@common/components/board/newBoard/boardtypes/weeklyBoard/weeklyBoardPreview';
import '@common/components/board/newBoard/boardtypes/projectBoard/projectBoardPreview';
import '@common/components/board/newBoard/boardtypes/smartactionlist/smartActionListPreview';
import '@common/components/board/newBoard/boardtypes/yearlyBoard/yearlyBoardPreview';
import '@common/components/board/moveOrCloneSelector/moveOrCloneSelector';
import '@common/components/board/repeatInfo/repeatInfoController';
import '@common/components/board/repeattask/repeattaskController';
import '@common/components/board/repeattask/repeatdeleteController';
import '@common/components/board/tasktoactionlist/tasktoactionlistController';
import '@common/components/board/boardController';
import '@common/components/board/taskDrawingService';
import '@common/components/board/boardDrawService';

import '@common/components/board/connectedusers/connectedUsers';
import '@common/components/board/personal/personalboardController';
import '@common/components/board/personal/personalboardFilter';
import '@common/components/board/personal/personalTaskCard';
import '@common/components/board/board.css';
import '@common/components/board/boardDesigner/boardDesigner.css';
import '@common/components/board/personal/personalboard.css';
import '@common/components/board/postits/postit.css';

import '@common/components/tasks/subtasks/moveSubTaskController';
import '@common/components/tasks/personal/3Ctask';

// tasks
import '@common/components/tasks/personaltaskListController';
import '@common/components/tasks/taskList/taskListModalController';
import '@common/components/tasks/taskList/projectTaskList';
import '@common/components/tasks/taskstatus/personalTaskStatus';
import '@common/components/tasks/subtasks/subTaskList';
import '@common/components/tasks/subtasks/subTaskList.css';
import '@common/components/tasks/subtasks/taskUserSelector';
import '@common/components/tasks/subtasks/taskUserSelector.css';
import '@common/components/tasks/subtasks/taskBoardSelector';
import '@common/components/tasks/subtasks/taskBoardSelector.css';

import '@common/components/tasks/subtasks/newSubTaskList';
import '@common/components/tasks/taskstatus/animationTaskStatus';
import '@common/components/tasks/taskSearch/taskList';
import '@common/components/tasks/taskSearch/taskListFilter';
import '@common/components/tasks/taskSearch/taskListResult';
import '@common/components/tasks/taskSearch/taskListResult.css';
import '@common/components/tasks/team/teamspaceController';
import '@common/components/tasks/team/teamKanban';
import '@common/components/tasks/team/teamKanbanCard';
import '@common/shared/tasks/taskStatus';
import '@common/shared/tasks/taskStatus.css';

// admin
import '@common/components/admin/boards/boardSettingsController';
import '@common/components/admin/language/languageLabelAdmin';
import '@common/components/admin/language/languageAdminService';
import '@common/components/admin/data/datasourceValues';
import '@common/components/admin/contactlist/newContactPersonModalController';
import '@common/components/admin/organization/billing/organizationBillingAdmin';
import '@common/components/admin/organization/billing/businessunitAccount';
import '@common/components/admin/organization/billing/customerAccountModalController';
import '@common/components/admin/assets/assetParticipantsController';
import '@common/components/admin/admin.css';
import '@common/components/admin/organization/customerAccountService';
import '@common/components/admin/organization/messagecenter/newmessage/newMessageModalController';

// flexiboard
import '@common/components/flexiboard/flexiboardsController';
import '@common/components/flexiboard/flexiboardController';
import '@common/components/flexiboard/flexiboardCards/defaultBoardCard';
import '@common/components/flexiboard/flexiboardCards/weeklyBoardCard';
import '@common/components/flexiboard/flexiboardCards/yearlyBoardCard';
import '@common/components/flexiboard/flexiboardCards/dashboardCard';
import '@common/components/flexiboard/flexiboardCards/smartActionlistCard';
import '@common/components/flexiboard/flexiboardCards/personalKanbanCard';
import '@common/components/flexiboard/flexiboardCards/performanceBoardCard';
import '@common/components/flexiboard/flexiboardCards/taskSearchCard';
import '@common/components/flexiboard/flexiboardCards/mySpaceCard';
import '@common/components/flexiboard/flexiboardCards/teamSpaceCard';
import '@common/components/flexiboard/flexiboardCards/customerKSCard';
import '@common/components/flexiboard/newFlexiboard/newFlexiboardController';
import '@common/components/flexiboard/newFlexiboard/newFlexiboardConfigurationController';
import '@common/components/flexiboard/flexiboardtemplates/emptyFlexiboardPreview/emptyFlexiboardPreview';
import '@common/components/flexiboard/flexiboard.css';
import '@common/components/flexiboard/flexiboardCards/flexiboardCards.css';
import '@common/components/flexiboard/flexiboardPage/flexiboardPageContainer';

import '@common/components/dashboard/designer/widget/widgetController';
import '@common/components/dashboard/designer/widget/widget.css';
import '@common/components/dashboard/delete/widgetDeleteConfirmation'

import '@common/components/dashboard/designer/appstore/webviewer/webviewer';
import '@common/components/dashboard/designer/appstore/webviewer/webviewer.css';

import '@common/components/dashboard/designer/appstore/appstoreService';
import '@common/components/dashboard/designer/appstore/appstoreController';

// project
import '@common/components/project/projectService';
import '@common/components/project/projectboard/projectBoardService';
import '@common/components/project/projectboard/projectboard';
import '@common/components/project/projectboard/projectboard.css';
import '@common/components/project/projectportfolio/projectportfolioTimeview';
import '@common/components/project/projectIncidentSummary/projectIncidentSummary';
import '@common/components/project/projectImprovementSummary/projectImprovementSummary';
import '@common/components/project/links/projectLinks';
import '@common/components/project/milestones/milestones';
import '@common/components/project/projectParticipants/projectParticipants';
import '@common/components/project/projectboard/projectCellSelectionController';
import '@common/components/project/projectboard/targetVisualizationSelector/targetProjectVisualizationController';
import '@common/components/project/projectboard/targetVisualizationSelector/targetVisualizationSelectorController';
import '@common/components/project/projectboard/templates/projectNameCell/projectNameCell';
import '@common/components/project/projectboard/templates/projectDescriptionCell/projectDescriptionCell';
import '@common/components/project/projectboard/templates/projectStatusCell/projectStatusCell';
import '@common/components/project/projectboard/templates/projectLinkToHubCell/projectLinkToHubCell';
import '@common/components/project/projectboard/templates/projectNumberCell/projectNumberCell';
import '@common/components/project/projectboard/templates/customerCell/projectCustomerCell';
import '@common/components/project/projectboard/templates/contactPersonCell/projectContactPersonCell';
import '@common/components/project/projectboard/templates/textCell/projectTextCell';
import '@common/components/project/projectboard/templates/textCell/predefinedLabels';
import '@common/components/project/projectboard/templates/numberCell/numberCell';
import '@common/components/project/projectboard/templates/numberCellAutomatic/numberCellAutomatic';
import '@common/components/project/projectboard/templates/numberCellAggregate/numberCellAggregate';
import '@common/components/project/projectboard/templates/projectTargetCell/projectTargetCell';
import '@common/components/project/projectboard/templates/targetFromAppCell/targetFromAppCell';
import '@common/components/project/projectboard/templates/textFromAppCell/textFromAppCell';
import '@common/components/project/projectboard/templates/personCell/projectPersonCell';
import '@common/components/project/projectboard/templates/dateCell/projectDateCell';
import '@common/components/project/projectboard/templates/startDateCell/projectStartDateCell';
import '@common/components/project/projectboard/templates/endDateCell/projectEndDateCell';
import '@common/components/project/projectboard/templates/ownerCell/ownerCell';
import '@common/components/project/projectboard/templates/projectParticipantsCell/projectParticipantsCell';
import '@common/components/project/projectboard/templates/projectParticipantsCell/projectParticipantModalController';
import '@common/components/project/projectboard/templates/milestoneCell/projectMilestoneCell';
import '@common/components/project/projectboard/templates/milestoneCell/projectNextMilestoneCell';
import '@common/components/project/projectboard/templates/projectTasksCell/projectTasksCell';
import '@common/components/project/projectboard/templates/progressTasksCell/projectProgressTasksCell';
import "@common/components/project/projectboard/templates/progressCell/progressbar"
import "@common/components/project/projectboard/templates/progressCell/projectProgressCell"
import '@common/components/project/projectGrid/projectGridController';
import '@common/components/project/projectGrid/projectDetailsController';
import '@common/components/project/projectGrid/projectMilestoneSummary/projectMilestoneSummary';
import '@common/components/project/projectGrid/projectMilestone/projectMilestoneListModalController';
import '@common/components/project/projectGrid/projectOnTime/projectOnTime';
import '@common/components/project/projectResourceLinks/projectResourceLinks';
import '@common/components/project/projectboard/projectselector/projectSelectorController';
import '@common/components/project/projectboard/projectselector/projectNewController';
import '@common/components/project/projectboard/cellcloneselector/cellCloneSelectorController';
import '@common/components/project/projectportfolio/projectPortfolioController';
import '@common/components/project/projectportfolio/newProjectPortfolioConfigurationController';
import '@common/components/project/customerstatusController';
import '@common/components/project/messages/projectMessages';
import '@common/components/project/messages/projectMessageService';
import '@common/components/project/selectedProjectController';
import '@common/components/project/projectTask/newtasktoprojecttasklistController';
import '@common/components/project/project.css';

// IMPROVEMENT
import '@common/components/improvement/improvement.css';
import '@common/components/improvement/suggestionService';
import '@common/components/improvement/mymessages/myMessagesController';
import '@common/components/improvement/priorityMatrix/priorityMatrix';
import '@common/components/improvement/priorityMatrix/priorityMatrixApp';
import '@common/components/improvement/suggestionList/suggestionListModalController';
import '@common/components/improvement/improvementActionList/improvementActionList';
import '@common/components/improvement/improvementFilter/improvementFilter';
import '@common/components/improvement/improvementPriorityFilter/improvementPriorityFilter';
import '@common/components/improvement/leadtimeImprovement/leadtimeImprovementController';
import '@common/components/improvement/initiativeRealizationPerGroup/initiativeRealizationPerGroupController';
import '@common/components/improvement/initiativeRealizationPerGroup/initiativeTimeRealizationPerGroupController';
import '@common/components/improvement/topImprovementInitiatives/topImprovementInitiativesController';
import '@common/components/improvement/topImprovementInitiatives/topTimeImprovementInitiativesController';
import '@common/components/improvement/monthlystatusImprovement/monthlystatusImprovementController';
import '@common/components/improvement/rangeImprovement/rangeImprovementController';
import '@common/components/improvement/roiperassetImprovement/roiperassetImprovementController';
import '@common/components/improvement/widgetImprovementStatus/widgetImprovementStatus';
import '@common/components/improvement/likes/likesController';
import '@common/components/improvement/newSuggestionModalController';
import '@common/components/improvement/ongoingimprovement/ongoingImprovementApp';
import '@common/components/improvement/improvementstatusController';
import '@common/components/improvement/fullImprovementModalController';

import '@common/components/improvement/improvementList/improvementList';
import '@common/components/improvement/improvementList/improvementListFilter';
import '@common/components/improvement/improvementList/improvementListResult';

import '@common/components/improvement/ongoingimprovement/ongoingImprovement';
import '@common/components/improvement/ongoingimprovement/improvementCommonService';
import '@common/components/improvement/ongoingimprovement/improvementColumn';
import '@common/components/improvement/ongoingimprovement/ongoingImprovement.css';

import '@common/components/users/userProvider';

// DEVIATION / incident
import '@common/components/deviation/deviationList/deviationListModalController';
import '@common/components/deviation/deviationFilter/deviationFilter';
import '@common/components/deviation/status/deviationStatusController';
import '@common/components/deviation/evaluation/deviationEvaluationController';
import '@common/components/deviation/status/leadtimeDeviationController';
import '@common/components/deviation/status/monthlystatusDeviationController';
import '@common/components/deviation/status/monthlyStatusDeviationArea';
import '@common/components/deviation/status/monthlyStatusDeviationSeverity';
import '@common/components/deviation/evaluation/deviationCauseSummary/deviationCauseSummary';
import '@common/components/deviation/evaluation/deviationConsequenceSummary/deviationConsequenceSummary';
import '@common/components/deviation/evaluation/deviationEvaluationList/deviationEvaluationList';

import '@common/components/dashboard/designer/appstore/deviationactions/deviationActionListResult';
import '@common/components/deviation/deviationActionList/deviationActionList';
import '@common/components/deviation/deviationActionList/deviationActionList.css';

// shared
import '@common/shared/debounce';
import '@common/shared/fileService';
import '@common/shared/paging/pagerService';
import '@common/shared/paging/pager';
import '@common/shared/paging/pager.css';
import '@common/shared/paging/pagerElement';

import "@common/components/dashboard/designer/common/appHeaderTitle/appHeaderTitle"
import "@common/components/dashboard/designer/common/flexiboard-apps.css"
import "@common/components/theme/themeSelector"
import "@common/components/monitoring/heartbeatIndicator"


import "@common/components/helpSearch/helpSearch"
import "@common/components/helpSearch/helpSearch.css"