var DigiLean = angular.module('DigiLean');
DigiLean.directive("greencrossDate", ['$filter', 'qualityService', 'navigationService','$uibModal',
        function ($filter, qualityService, navigationService, $uibModal) {
            return {
                templateUrl: 'greencross-date.html',
                restrict: 'E',
                scope: {
                    'editable': '=',
                    'areaId': '=',
                    'projectId': '<',
                    'greencrossid': '=',
                    'dateOfMonth': '=',
                    'month': '=',
                    'year': '=',
                    'value': '=',
                    'assetId': '=',
                    'deviationTypeId': '=',
                },
                link: function (scope, elem, attrs) {

                    scope.subscribe("UserAuthenticatedAndReady", function(profile)
                    {
                        scope.hasDeviation = navigationService().hasModule("DEVIATION");   
                    });
                    
                    scope.setStatusValue = function (newValue) {
                        scope.value = newValue;
                        var date = new Date(Date.UTC(scope.year, scope.month, scope.dateOfMonth, 12, 0, 0, 0));

                        var status = {
                            status: scope.value,
                            date: date,
                            areaId: scope.areaId,
                            projectId: scope.projectId,
                            visualizationType: "GREENCROSS"
                        };
                        qualityService().updateDateStatus(status).then(function (savedStatus) {
                            //TODO: must add popup which force the user to specify which deviation type to be used when register incidents from green cross
                            /*if(scope.hasDeviation == null) {scope.hasDeviation = navigationService().hasModule("DEVIATION");}
                            if(scope.hasDeviation && newValue > 1) {
                                addDeviation(newValue);
                            }*/
                        });

                    }

                    scope.getRegistrations = function () {
                        var date = new Date(Date.UTC(scope.year, scope.month, scope.dateOfMonth));
                        var startDate = moment(date).startOf('day');
                        var endDate = moment(date).endOf('day');

                        var timePeriod = {
                            from: startDate.toDate().toJSON(),
                            to: endDate.toDate().toJSON()
                        };

                        if(scope.dataSource){
                            var datasourceoptions = {
                                dataSource: scope.dataSource,
                                filters: scope.filters,
                                timePeriod: timePeriod
                            }
                            showDatasourceValues(datasourceoptions);
                        } else if(scope.deviationTypeId){
                            var options = {
                                assetId: scope.assetId,
                                deviationTypeId: scope.deviationTypeId,
                                timePeriod: timePeriod
                            }
                            showDeviations(options);
                        } else {
                            

                            var modalInstance = $uibModal.open({ backdrop: 'static',
                                animation: true,
                                templateUrl: 'greencrossStatusForm.html', 
                                controller: 'greencrossStatusModalController',
                                resolve: {
                                    timePeriod: function () {
                                        return timePeriod;
                                    }
                                }         
                            });
                        }

                    }

                    function showDatasourceValues(options) {

                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            templateUrl: 'datavaluesListForm.html',
                            controller: 'datavaluesListModalController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                options: function () {
                                    return  options;
                                }
                            }
                        });
                    }
                    
                    function showDeviations (deviationOptions) {
                        var currentfilter = [{
                            sourceColumn: "AssetId",
                            operator: "InList",
                            items: [deviationOptions.assetId]
                        },
                        {
                            sourceColumn: "DeviationTypeId",
                            operator: "InList",
                            items: [deviationOptions.deviationTypeId]
        
                        }];
                        var order = [{
                            sourceColumn: "Status",
                            SortDirection: "ASC"
                        }, {
                            sourceColumn: "Severity",
                            SortDirection: "DESC"
                        }];
                        var options = {
                            filters: currentfilter,
                            sortOrders: order,
                            timePeriodColumn: 'IncidentDate',
                            timePeriod: deviationOptions.timePeriod
                        };
        
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            templateUrl: 'deviationListForm.html',
                            controller: 'deviationListModalController',
                            windowClass: 'fullDeviation-modal-window',
                            resolve: {
                                options: function () {
                                    return options;
                                }
                            }
                        });
                    };
                    

                    function addDeviation(statusValue) {
                        var severity = DigiLean.hms.getDeviationSeverity(statusValue);
                        // Make sure task responsbile dropdown is closed if open.
                        var settings = {
                            areaId: scope.areaId,
                            severity: severity
                        }
                        var modalInstance = $uibModal.open({ backdrop: 'static',
                            animation: true,
                            templateUrl: 'newDeviation.html',
                            controller: 'newDeviationController',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {
                                settings: function () {
                                    return settings;
                                }
                            }
                        });
                    }

                }

            }
        }]);
