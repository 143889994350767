/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IncidentSeverity {
    None = 0,
    Low = 1,
    Medium = 2,
    High = 3,
}
