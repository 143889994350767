import { UserAccessLevel } from "@api";

export function hasDefaultAccess(userAccessLevel: UserAccessLevel) {
    if(!userAccessLevel)
    {
        return true;
    }
    else if(userAccessLevel.isAdmin || userAccessLevel.assetRole == "Member" || userAccessLevel.assetRole == "Owner" ) {
        return true
    }
    else {
        return false
    }
}

