import * as customerService from "@common/services/customers/customerService"
import * as timeService from "@common/services/timeService"
import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .controller("strategyCardPopup", ['$scope', '$uibModalInstance', '$uibModal','$filter','strategyService','boardTaskService','actionlistService', 'goalId',
        function ($scope, $uibModalInstance, $uibModal,$filter,strategyService,boardTaskService,actionlistService, goalId) {

            $scope.goalId = goalId;
            $scope.allUsers = [];
            $scope.items = [];

            var timeperiodAll = timeService.getTimePeriod('all');

            strategyService().getTasksForStrategyGoals([{id:goalId}], timeperiodAll).then(function (tasks) {
                $scope.items = tasks;
                $scope.items.forEach(function (item) {
                    item.boardDate = moment(item.boardDate).toDate();
                });
                $scope.items.sort(function (a, b) {
                    if (b.status == 'blank' && a.status == 'OK') {
                        return 1;
                      }
                      if (a.status == 'blank' && b.status == 'OK') {
                        return -1;
                      }
                    
                      // status is equal
                      return 0;
                });
            });

            customerService.getAllUsers().then(function (users) {
                $scope.allUsers = users;
            });

            var initTask = function (task) {
                task.animate = function (animation, timeOut) {
                    if (!timeOut) {
                        timeOut = 1000;
                    }
                    task.animateClass = "animated " + animation;
                    setTimeout(function () {
                        task.animateClass = "";
                    }, timeOut);
                }
                task.animate("slideInDown", 3000);
            };
            $scope.subscribe('NewTask', function (task) {
                if (!task) return;
                if (task.goals){
                   // for(var g=0; g<task.goals.length; g++){
                        var relevantGoal = task.goals.filter(g => g.strategyGoalId == $scope.goalId);
                        if (relevantGoal.length > 0) {
                            if(task.boardDate){
                                task.boardDate = moment(task.boardDate).toDate();
                            }
                            $scope.items.unshift(task);
                        }
                    //}
                }
            });
            $scope.subscribe('TaskDeleted', function (task) {
                if (!task) return;
                setTimeout(function () {
                    var exists = $filter('filter')($scope.items, { id: task });
                    if (exists.length > 0) { 
                        var index = $scope.items.indexOf(exists[0]);
                            initTask(exists[0]); // init task so we can animate
                            // animate removal
                            exists[0].animate("fadeOut", 2000);
                            $scope.items.splice(index, 1);
                    }    
                }, 1500);
            });

            $scope.createTask = function () {
                var status = "blank";
                var options = {
                    users: $scope.allUsers, 
                    showDueDate: true           
                }

                var newDate = moment().startOf('day'); 
                var task = {
                    id: 0,
                    boardId: null,
                    title: "",
                    text: "",
                    status: status,
                    tags: "",
                    boardDate: moment(newDate).toDate(),
                    goals: [{Id:0,
                        BoardTaskId: 0,
                        StrategyGoalId:$scope.goalId}]
                }

                var modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newBoardTask.html',
                    controller: 'newBoardTaskController',
                    resolve: {
                        task: function () {
                            return task;
                        },
                        options: function () {
                            return options;
                        }
                    }
                });

                modalInstance.result.then(function (result) {
                });                      
            }
            $scope.openTask = function (activeTab, subtask) {
                if (!activeTab) activeTab = "Info";
                var options = {
                    canChangeBoard: false
                };

                boardTaskService().get(subtask.id).then(function (task) {
                    $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        templateUrl: 'boardTask.html',
                        controller: 'boardTaskController',
                        resolve: {
                            task: function () {
                                return task;
                            },
                            activeTab: function () {
                                return activeTab;
                            },
                            options: function () {
                                return options;
                            }
                        }
                    });
                })

            }
            $scope.saveItemDescription = function (itemId, desc) {
                var taskDescription = {
                    description: desc
                };
                actionlistService().updateItemDescription(itemId, taskDescription).then(function () {
                    $translate('DEVIATION_ACTION_ITEM_SAVED').then(function (msg) {
                        toaster.success(msg);
                    });
                    $scope.publish("SendTaskDescriptionUpdated", taskDescription);
                });
            }

            $scope.deleteItem = function (item) {
                       
                actionlistService().deleteActionItem(item.id).then(function () {
                    /*var index = $scope.items.indexOf(item);
                    if (index > -1) {
                        item.cssState = "animated slideOutLeft"; // Add animation
                        setTimeout(function () {
                            $scope.items.splice(index, 1);
                        }, 300);     
                    }*/
                });
            };

            $scope.changeActionDate = function (item, $event) {
                const date = $event.originalEvent.detail
                item.boardDate = date
                if (item.boardDate) { // Valid date format
                    item.error = false;
                    var actionDate = {
                        taskId: item.id,
                        actionDate: item.boardDate
                    };
                    actionlistService().updateItemActionDate(actionDate).then(function () {
                        $translate('DEVIATION_ACTION_ITEM_SAVED').then(function (msg) {
                            toaster.success(msg);
                        });
                        // publish event on change
                        scope.publish("SendTaskDateUpdated", actionDate);
                    });
                } else {
                    $translate('COMMON_NOT_VALID_DATE').then(function (trans) {
                        item.error = trans;
                    });
                    
                }
            }

            $scope.userChangeHandler = function (userinfo) {
                if (userinfo) {
                    var user = userinfo.user;
                    var item = userinfo.model;
                    item.user = user;
                    // 
                    actionlistService().assignActionListItemUser(item.id, user).then(function () {    
                    }); 
                }

                
            }
            $scope.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
