/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DataSourceElementType {
    Area = 'area',
    Asset = 'asset',
    Board = 'board',
    Bool = 'bool',
    Description = 'description',
    List = 'list',
    Number = 'number',
    Project = 'project',
    Text = 'text',
    User = 'user',
}
