﻿import * as timeService from "@common/services/timeService"
import * as boardService from "@common/services/boardService"
import toaster from "@common/components/toast"

var DigiLean = angular.module('DigiLean');
DigiLean.directive('dynamicBoard', ['$translate', 'boardTaskService', '$uibModal', 'navigationService', 'boardDrawService', 'suggestionService', '$state', 'deviationService', 'taskDrawingService', 'boardLayoutService', 'fileService', 'Lightbox', 'debounce',
    function ($translate, boardTaskService, $uibModal, navigationService, boardDrawService, suggestionService, $state, deviationService, taskDrawingService, boardLayoutService, fileService, Lightbox, debounce) {
        return {
            restrict: 'A',
            scope: {
                'model': '=',
                'week': '=',
                'archiveoptions': '=',
                'options': '<'
            },

            /* link */
            link: function (scope, elem, attrs) {
                scope.isWeeklyBoard = false;
                scope.showArchive = false;
                var isKamishibai = false;
                var boardTableContainer = $(elem).parent();
                scope.hasDeviationAccess = false;
                var postIts = [];

                scope.hasDeviationAccess = navigationService().hasModule("DEVIATION");

                // Set wrapper overflow to support dropdowns not being cut off
                setTimeout(function () {
                    $("#wrapper").css("overflow-y", "visible");
                })
                scope.$on('$destroy', function () {
                    $("#wrapper").css("overflow-y", "hidden");
                    // Remove wrapper css
                });

                scope.$watch('model', function () {
                    if (scope.model == null) return;
                    scope.title = "loading";
                    scope.isViewer = navigationService().isViewer(scope.model.board.assetId);
                    if (scope.model.board.settings) {
                        scope.settings = JSON.parse(scope.model.board.settings);
                        if (!scope.settings) {
                            scope.settings = {};
                        }
                        if (scope.settings.isKamishibai) {
                            isKamishibai = scope.settings.isKamishibai;
                        }
                    }
                    if (scope.model.board.boardType == "Weekly") {
                        scope.isWeeklyBoard = true;
                    } else {
                        scope.isWeeklyBoard = false;
                    }
                    var options = {
                        board: scope.model
                    };
                    boardDrawService.drawBoard($(elem), options);

                    adjustActionListHeight();



                    boardService.canEdit(scope.model.board.id).then(function (canEdit) {
                        scope.isViewer = !canEdit;
                        if (canEdit) {
                            // After board is drawn and DOM is updated, we can add behaviour to our board
                            setupTasks();
                            setupTaskModifications();
                            addSortable();
                            setupExpanderAndCategoryResponsible();
                            setupStatus();
                            boardService.getUserListForBoard(scope.model.board.id).then(function (data) {
                                users = data;
                                users = _.sortBy(users, function (user) { return user.firstName; })
                                if (!scope.isViewer) {
                                    taskDrawingService().addUserList(users, updateResponsible);
                                }
                            });
                        }
                    })
                    subscribeToEvents();
                    addTooltipBehaviour();
                });
                scope.$watch('archiveoptions', function () {
                    if (!scope.model) return;
                    if (!scope.archiveoptions) return;
                    if (scope.isWeeklyBoard) return;
                    if (scope.archiveoptions && scope.archiveoptions.showArchive) {

                        var timePeriod = timeService.getTimePeriod(scope.archiveoptions.timePeriod.timeframe);

                        boardService.archivedTasksInPeriod(scope.model.board.id, timePeriod).then(function (tasks) {
                            boardDrawService.redrawTasks(boardTableContainer, tasks, false, isKamishibai);
                            loadImages(tasks);
                            updateSubTaskStatus(tasks);
                            reapplyTaskBehaviours();
                            //toggleArchiveOptions
                            $(boardTableContainer).find(".move-to-archive-task").hide();
                            $(boardTableContainer).find(".move-from-archive-task").show();
                        });
                    } else {
                        boardService.getTasks(scope.model.board.id).then(function (tasks) {
                            boardDrawService.redrawTasks(boardTableContainer, tasks, false, isKamishibai);
                            loadImages(tasks);
                            updateSubTaskStatus(tasks);
                            reapplyTaskBehaviours();
                            $(boardTableContainer).find(".move-to-archive-task").show();
                            $(boardTableContainer).find(".move-from-archive-task").hide();
                        });
                    }

                });
                scope.subscribe("BoardSettingUpdated", function (boardsettinginfo) {
                    if (boardsettinginfo.boardId === scope.model.board.id) {
                        scope.settings = JSON.parse(boardsettinginfo.settings);

                        if (scope.settings && scope.settings.PostIts) {
                            postIts = scope.settings.PostIts;
                        }
                    }
                });
                scope.$watch('week', function () {
                    if (scope.week == null) return;
                    var weekDays = angular.element(".weekdayContainer");
                    var daysContainers = angular.element(".category-day-container");
                    angular.element("#boardTable .today").removeClass("today");
                    for (var index = 0; index < weekDays.length; ++index) {
                        var weekDayElement = $(weekDays[index]);
                        var dayContainerElement = $(daysContainers[index]);
                        var day = weekDayElement.data("weekday");

                        var cells;
                        var categoryId = weekDayElement.closest("th").data("categoryid");
                        if (categoryId) {
                            cells = $(boardTableContainer).find("td[data-categoryid='" + categoryId + "']");
                        } else {
                            var rowId = weekDayElement.closest("td").data("rowid");
                            cells = $(boardTableContainer).find("td[data-rowid='" + rowId + "']");
                        }
                        // Update category
                        var date = timeService.getDateInWeek(day, scope.week.weekDays).toJSON();
                        weekDayElement.attr('data-date', date);
                        cells.attr("data-date", date);
                        if (timeService.isToday(date)) {
                            cells.addClass("today");
                        }

                        //var formattedDate = moment(date).format("DD.MM.YYYY"); // Set date as text
                        var formattedDate = moment(date).format("MMM YYYY"); // Set date as text
                        weekDayElement.html(formattedDate);
                        var singleDay = moment(date).format("DD");
                        singleDay = singleDay.replace(/^0+/, ''); // Remove leaading zero
                        dayContainerElement.html(singleDay);
                    }

                    // week has changed, so load tasks and assign dates
                    var period = {
                        from: scope.week.startDate.toJSON(),
                        to: scope.week.endDate.toJSON()
                    };
                    boardService.tasksInPeriod(scope.model.board.id, period).then(function (tasks) {
                        boardDrawService.redrawTasks(boardTableContainer, tasks, true, isKamishibai);
                        loadImages(tasks);
                        updateSubTaskStatus(tasks);
                        adjustActionListHeight();
                        importActionTasks();
                        reapplyTaskBehaviours();
                        scope.publish("BoardWeekLoaded", scope.week);
                    });
                });


                // "Directives" variables
                var cellContainer;
                var taskToBeImported = null;
                var users = [];

                function subscribeToEvents() {
                    // When a directive, we need to access publish/subscribe on the $rootscope.
                    scope.subscribe('NewTask', function (task) {
                        removeAnimations();
                        addTaskToBoard(task);
                        loadImage(task.id, task.attachmentCount);
                        reapplyTaskBehaviours();
                    });
                    scope.subscribe('ActionItemMovedToTask', function (task) {
                        if (task.boardId === scope.model.board.id) {
                            removeAnimations();
                            loadImages([task]);
                            reapplyTaskBehaviours();
                        } else {
                            removeTaskFromBoard(task.id);
                        }
                    });
                    scope.subscribe('TaskUpdated', function (task) {
                        removeAnimations();
                        updateExistingTask(task);
                        loadImage(task.id, task.attachmentCount);
                        reapplyTaskBehaviours();
                    });
                    scope.subscribe("TaskTitleChanged", function (data) {
                        if (data.title) {
                            removeAnimations();
                            var task = $(boardTableContainer).find("div .portlet[data-id='" + data.taskId + "']").first();
                            $(task).find(".taskheader").replaceWith(data.title);
                            reapplyTaskBehaviours();
                        }
                    });
                    scope.subscribe('TaskCommentAdded', function (comment) {
                        removeAnimations();
                        updateCommentCount(comment);
                        reapplyTaskBehaviours();
                    });
                    scope.subscribe('TaskCommentDeleted', function (comment) {
                        removeAnimations();
                        updateCommentCount(comment);
                        reapplyTaskBehaviours();
                    });
                    scope.subscribe('TaskAttachmentCountChanged', function (attachment) {
                        removeAnimations();
                        updateAttachmentCount(attachment);
                        loadImage(attachment.taskId, attachment.attachmentCount);
                        reapplyTaskBehaviours();
                    });

                    scope.subscribe('TaskStatusUpdated', function (status) {
                        removeAnimations();
                        updateTaskStatus(status);
                        reapplyTaskBehaviours();
                    });
                    scope.subscribe('TaskDeleted', function (id) {
                        removeTaskFromBoard(id);
                    });
                    scope.subscribe('TaskArchived', function (id) {
                        removeTaskFromBoard(id);
                    });
                    scope.subscribe('TaskMovedFromArchive', function (task) {
                        addTaskToBoard(task);
                    });
                    scope.subscribe('TaskChangedBoard', function (change) {
                        if (change.toBoardId === null || change.toBoardId === 0) {
                            removeTaskFromBoard(change.taskId);
                        }
                    });

                    scope.subscribe('TaskMoved', function (moveSettings) {
                        removeAnimations();
                        console.log("TaskMoved recieved:");
                        console.log(JSON.stringify(moveSettings));
                        moveTaskOnBoard(moveSettings);
                        boardDrawService.recalculateZindex();
                    });
                    scope.subscribe('TaskDateMoved', function (moveSettings) {
                        removeAnimations();
                        console.log("TaskDateMoved recieved:");
                        console.log(JSON.stringify(moveSettings));
                        moveTaskDateOnBoard(moveSettings);
                        boardDrawService.recalculateZindex();
                    });
                    scope.subscribe('TaskAssigned', function (taskAssigned) {
                        taskAssignedToUser(taskAssigned);
                    });
                    scope.subscribe('TaskMovedToActionList', function (task) {
                        removeTaskFromBoard(task.id);
                    });
                    scope.subscribe('TasksDeleted', function (taskIds) {
                        removeTasksFromBoard(taskIds);
                    });
                    scope.subscribe('TaskCloned', function (task) {
                        removeAnimations();
                        addTaskToBoard(task);
                        loadImage(task.id, task.attachmentCount);
                        reapplyTaskBehaviours();
                    });
                    scope.subscribe('BoardCategoryAssigned', function (data) {
                        assignUserToCategory(data);
                    });
                }

                function loadImage(taskid, count) {
                    if (count > 0) {
                        fileService().getFilesForTask(taskid).then(function (fileResults) {
                            // Draw image based on result url
                            taskDrawingService().updateImage(taskid, fileResults)
                            // Attachments
                            taskDrawingService().updateAttachments(scope, taskid, fileResults)

                        });
                    }
                }

                function adjustActionListHeight() {
                    var height = $(boardTableContainer).find("#boardTable").height();
                    $(boardTableContainer).find("#actionList").css("min-height", height);
                };

                // Load images for tasks
                function loadImages(tasks) {
                    angular.forEach(tasks, function (task, key) {
                        if (task.attachmentCount > 0) {
                            fileService().getFilesForTask(task.id).then(function (fileResults) {
                                // Draw image based on result url
                                taskDrawingService().updateImage(task.id, fileResults)
                                taskDrawingService().updateAttachments(scope, task.id, fileResults)

                            });
                        }
                        addTaskToBoard(task);
                    });
                }

                // Load sub task status
                function updateSubTaskStatus(tasks) {
                    angular.forEach(tasks, function (task, key) {
                        taskDrawingService().updateSubTask(scope, task);
                    });
                }

                function importActionTasks() {
                    if (taskToBeImported !== null && taskToBeImported.boardId === scope.model.board.id &&
                        taskToBeImported.weekNumber === scope.week.weekNumber) {
                        angular.forEach(taskToBeImported.tasks, function (task, key) {
                            addTaskToBoard(task);
                        });
                        taskToBeImported = null;
                    }
                }

                function setupToggleDescription() {
                    // Toggle description
                    $(boardTableContainer).find(".descriptiontextcontainer").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var isVisible = $(portlet).find(".description").is(":visible");
                        var moreButton = $(this).find(".more-button");
                        var displayText = "";
                        if (isVisible) {
                            $(portlet).find(".description").hide();
                            displayText = $(moreButton).attr("data-more");
                        } else {
                            $(portlet).find(".description").show();
                            displayText = $(moreButton).attr("data-less");
                        }
                        $(moreButton).text(displayText);

                    });
                }

                function setupTaskOpen() {
                    // Edit task via date-container
                    $(boardTableContainer).find(".date-container").off();
                    $(boardTableContainer).find(".date-container").click(function (e) {
                        cellContainer = $(this).closest("td");
                        var activeTask = $(this).closest("div .portlet");
                        var DOMtask = taskDrawingService().getTaskFromDOM(activeTask, scope.model.board.id);
                        editTask(DOMtask.id);
                    });

                    // Edit task
                    var tasks = $(boardTableContainer).find(".taskheader");
                    $(boardTableContainer).find(".taskheader").off();
                    $(boardTableContainer).find(".taskheader").click(function (e) {
                        cellContainer = $(this).closest("td");
                        var activeTask = $(this).closest("div .portlet");
                        var DOMtask = taskDrawingService().getTaskFromDOM(activeTask, scope.model.board.id);
                        editTask(DOMtask.id);

                    });
                    $(boardTableContainer).find(".description").off();
                    $(boardTableContainer).find(".description").click(function (e) {
                        cellContainer = $(this).closest("td");
                        var activeTask = $(this).closest("div .portlet");
                        var DOMtask = taskDrawingService().getTaskFromDOM(activeTask, scope.model.board.id);
                        editTask(DOMtask.id);

                    });
                }

                function removeAnimations() {
                    // remove animation afterwards
                    setTimeout(function () {
                        $(boardTableContainer).find("div .portlet").removeClass("animated");
                    }, 1000);
                };


                function updateTaskStatus(status) {
                    taskDrawingService().updateStatus(scope.model.board.id, status.taskId, status.status, isKamishibai);
                }

                function resetDropdowns() {
                    $(boardTableContainer).find('#boardTable .dropdown-toggle').off();
                    $(boardTableContainer).find('#boardTable .dropdown-toggle').click(function () {
                        if ($(this).closest(".dropdown").hasClass("open")) {
                            $(this).closest(".dropdown").removeClass("open");
                        } else {
                            $(this).closest(".dropdown").addClass("open");
                        }
                    });
                }

                function postItDroppedEvent(event, ui) {
                    var draggable = ui.draggable[0];
                    var color = $(draggable).data("color");
                    newTask(this, color);
                }

                function setupTasks() {
                    // Drop of post-it to cell
                    $(boardTableContainer).find(".board-table-cell").droppable({
                        drop: postItDroppedEvent,
                        accept: '.post-it',
                        hoverClass: 'hovered'
                    });
                    // Drop of user to task
                    $(boardTableContainer).find(".portlet").droppable({
                        drop: userDroppedEvent,
                        accept: '.activeuser',
                        hoverClass: 'hovered',
                    });
                    // Drop of user to category
                    $(boardTableContainer).find(".boardcategory").droppable({
                        drop: userDroppedToColumnHeaderEvent,
                        accept: '.activeuser',
                        hoverClass: 'hovered'
                    });
                    $(boardTableContainer).find(".rowcategory-user-container").droppable({
                        drop: userDroppedToRowHeaderEvent,
                        accept: '.activeuser',
                        hoverClass: 'hovered'
                    });
                    resetDropdowns();

                    $(boardTableContainer).find(".descriptiontextcontainer").off();

                    $(boardTableContainer).find(".commentscontainer").off();
                    $(boardTableContainer).find(".attachmentcontainer").off();
                    $(boardTableContainer).find(".actioncontainer .clone-task").off();

                    $(boardTableContainer).find(".actioncontainer .repeat-task").off();
                    $(boardTableContainer).find(".actioncontainer .copy-task").off();
                    $(boardTableContainer).find(".actioncontainer .delete-task").off();
                    $(boardTableContainer).find(".actioncontainer .move-to-board-task").off();
                    $(boardTableContainer).find(".actioncontainer .move-to-actionlist-task").off(); //TODO: må verifiseres 
                    $(boardTableContainer).find(".actioncontainer .move-to-archive-task").off();
                    $(boardTableContainer).find(".actioncontainer .move-from-archive-task").off();
                    $(boardTableContainer).find(".info-container .clone-icon").off();
                    $(boardTableContainer).find(".info-container .parent-icon").off();
                    $(boardTableContainer).find(".info-container .improvement-icon").off();
                    $(boardTableContainer).find(".info-container .deviation-icon").off();

                    $(boardTableContainer).find(".image-container").off();


                    // Improvement info
                    $(boardTableContainer).find(".info-container .improvement-icon").click(function () {
                        var improvementsuggestionid = $(this).data("improvementsuggestionid");
                        suggestionService().get(improvementsuggestionid).then(function (suggestion) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                templateUrl: 'fullImprovementInfoForm.html',
                                controller: 'fullImprovementModalController',
                                windowClass: 'fullSuggestion-modal-window',
                                resolve: {
                                    suggestion: function () {
                                        return suggestion;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });
                    });


                    $(boardTableContainer).find(".image-container").click(function (e, ui) {
                        var url = $(e.target).attr("src");
                        var images = [
                            {
                                'url': url,
                                'thumbUrl': url
                            }
                        ];
                        Lightbox.openModal(images, 0);
                    });
                    // Deviation info
                    $(boardTableContainer).find(".info-container .deviation-icon").click(function () {
                        var deviationid = $(this).data("deviationid");
                        deviationService().get(deviationid).then(function (deviation) {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                templateUrl: 'fullDeviation.html',
                                controller: 'fullDeviationController',
                                windowClass: 'full-screen',
                                resolve: {
                                    deviation: function () {
                                        return deviation;
                                    },
                                    activeTab: function () {
                                        return "Info";
                                    }
                                }
                            });
                        });
                    });

                    // Redirect to A3
                    $(boardTableContainer).find(".info-container .a3-icon").click(function () {
                        var a3id = $(this).data("a3id");
                        $state.go('a3wizard', { id: a3id }, { reload: false });

                    });


                    // Clone info
                    $(boardTableContainer).find(".info-container .clone-icon").click(function () {
                        var siblingId = $(this).data("siblingid");
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        var taskTitle = $(portlet).find(".taskheader").html();
                        var task = {
                            id: taskId,
                            title: taskTitle,
                            siblingId: siblingId
                        };
                        // Make sure task responsbile dropdown is closed if open.
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'cloneInfo.html',
                            controller: 'cloneInfoController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });
                    });

                    // Repeat info
                    $(boardTableContainer).find(".info-container .repeat-icon").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var repeatParentId = portlet.data("repeatparentid");
                        var taskId = portlet.data("id");
                        var taskTitle = $(portlet).find(".taskheader").html();
                        var task = {
                            id: taskId,
                            title: taskTitle,
                            repeatParentId: repeatParentId
                        };

                        $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'repeatInfo.html',
                            controller: 'repeatInfoController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });
                    });

                    // parent task info
                    $(boardTableContainer).find(".info-container .parent-icon").click(function () {
                        var parentId = $(this).data("parentid");
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        var taskTitle = $(portlet).find(".taskheader").html();
                        var task = {
                            id: taskId,
                            title: taskTitle,
                            parentId: parentId
                        };
                        // Make sure task responsbile dropdown is closed if open.
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'parenttaskInfo.html',
                            controller: 'parenttaskInfoController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });
                    });

                    // Clone task
                    $(boardTableContainer).find(".actioncontainer .clone-task").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        var taskTitle = $(portlet).find(".taskheader").html();
                        var task = {
                            id: taskId,
                            title: taskTitle
                        };
                        // Make sure task responsbile dropdown is closed if open.
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'clonetask.html',
                            controller: 'clonetaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });
                        var container = $(boardTableContainer).find(".actioncontainer .dropdown");
                        container.removeClass("open");
                    });

                    setupToggleDescription();

                    // Comments
                    $(boardTableContainer).find(".commentscontainer").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var boardTask = taskDrawingService().getTaskFromDOM(portlet, scope.model.board.id);
                        editTask(boardTask.id, "Comments");
                    });
                    // Attachments
                    $(boardTableContainer).find(".attachmentcontainer").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var boardTask = taskDrawingService().getTaskFromDOM(portlet, scope.model.board.id);
                        editTask(boardTask.id, "Attachments");
                    });

                    // Copy task
                    $(boardTableContainer).find(".actioncontainer .copy-task").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        boardTaskService().copyTask(taskId).then(function (task) {
                        });
                        var container = $(boardTableContainer).find(".actioncontainer .dropdown");
                        container.removeClass("open");
                    });
                    // Repeat task
                    $(boardTableContainer).find(".actioncontainer .repeat-task").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        var taskTitle = $(portlet).find(".taskheader").html();
                        var task = {
                            id: taskId,
                            title: taskTitle
                        };
                        // Make sure task responsbile dropdown is closed if open.
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'repeattask.html',
                            controller: 'repeattaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });

                        modalInstance.result.then(function (result) {
                            // publish update
                            scope.publish('SendTaskRepeated', result);
                        });
                        var container = $(boardTableContainer).find(".actioncontainer .dropdown");
                        container.removeClass("open");
                    });

                    // Move task to another board
                    $(boardTableContainer).find(".actioncontainer .move-to-board-task").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        var taskTitle = $(portlet).find(".taskheader").html();
                        var task = {
                            id: taskId,
                            title: taskTitle
                        };
                        // Make sure task responsbile dropdown is closed if open.
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'movetask.html',
                            controller: 'movetaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                }
                            }
                        });

                        /*
                        modalInstance.result.then(function (result) {
                            // publish update
                            scope.publish('SendTaskRepeated', result);
                        });*/
                        var container = $(boardTableContainer).find(".actioncontainer .dropdown");
                        container.removeClass("open");
                    });

                    // Move task to a given actionlist
                    $(boardTableContainer).find(".actioncontainer .move-to-actionlist-task").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        boardTaskService().get(taskId).then(function (task) {
                            // Make sure task responsbile dropdown is closed if open.
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'tasktoactionlist.html',
                                controller: 'tasktoactionlistController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    }
                                    , targetBoardId: function () {
                                        return task.boardId;
                                    }
                                }
                            });
                            var container = $(boardTableContainer).find(".actioncontainer .dropdown");
                            container.removeClass("open");
                        });
                    });

                    // Move task to a archive
                    $(boardTableContainer).find(".actioncontainer .move-to-archive-task").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        boardTaskService().moveTaskToArchive(taskId).then(function (result) {
                            var container = $(boardTableContainer).find(".actioncontainer .dropdown");
                            container.removeClass("open");
                            removeTaskFromBoard(taskId);
                        });
                    });
                    // Move task to a archive
                    $(boardTableContainer).find(".actioncontainer .move-from-archive-task").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        boardTaskService().moveTaskFromArchive(taskId).then(function (result) {
                            var container = $(boardTableContainer).find(".actioncontainer .dropdown");
                            container.removeClass("open");
                            removeTaskFromBoard(taskId);
                        });
                    });


                    // Add deviation task
                    $(boardTableContainer).find(".actioncontainer .add-deviation").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var task = taskDrawingService().getTaskFromDOM(portlet);
                        addDeviation(task);
                        var container = $(boardTableContainer).find(".actioncontainer .dropdown");
                        container.removeClass("open");
                    });
                    // Deelte task
                    $(boardTableContainer).find(".actioncontainer .delete-task").click(function () {
                        var portlet = $(this).closest(".portlet");
                        var taskId = portlet.data("id");
                        var repeatParentId = portlet.data("repeatparentid");
                        var siblingId = portlet.data("siblingid");

                        if (!repeatParentId && !siblingId ||
                            repeatParentId == "undefined" && siblingId == "undefined") { //the previous line was not enough 
                            boardTaskService().deleteTask(taskId).then(function () {
                            });
                            var container = $(boardTableContainer).find(".actioncontainer .dropdown");
                            container.removeClass("open");
                            return;
                        }

                        if (repeatParentId && repeatParentId != "undefined") {
                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'repeatdelete.html',
                                controller: 'repeatdeleteController',
                                windowClass: 'newdeviation-modal-window',
                                resolve: {
                                    taskId: function () {
                                        return taskId;
                                    },
                                    repeatParentId: function () {
                                        return repeatParentId;
                                    }
                                }
                            });
                        }

                        if (siblingId && siblingId != "undefined") {

                            var modalInstance = $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'clonedelete.html',
                                controller: 'clonedeleteController',
                                windowClass: 'newdeviation-modal-window',
                                resolve: {
                                    taskId: function () {
                                        return taskId;
                                    },
                                    siblingId: function () {
                                        return siblingId;
                                    }
                                }
                            });
                        }

                    });
                }

                function addDeviation(task) {
                    // Make sure task responsbile dropdown is closed if open.
                    var settings = {
                        task: task,
                        assetId: scope.model.board.assetId
                    }
                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        templateUrl: 'newDeviation.html',
                        controller: 'newDeviationController',
                        windowClass: 'newdeviation-modal-window',
                        resolve: {
                            settings: function () {
                                return settings;
                            }
                        }
                    });

                }

                function updateResponsible(data) {
                    boardTaskService().assignUser(data).then(function () {
                        taskAssignedToUser(data.user);
                        //scope.publish("SendTaskAssigned", data.user);
                    });
                }

                function taskAssignedToUser(taskAssigned) {
                    var task = $(boardTableContainer).find("div .portlet[data-id='" + taskAssigned.taskId + "']").first();
                    $(task).attr("data-responsibleuserid", taskAssigned.userId);
                    $(task).attr("data-responsibleuser", taskAssigned.userName);
                    $(task).attr("data-displayname", taskAssigned.userDisplayName);
                    var user = "<span class='responsibleusername animated bounce'>" + taskAssigned.userDisplayName + "</span>";
                    $(task).find(".responsibleusername").replaceWith(user);
                    // Make sure task responsbile dropdown is closed if open.
                    var container = $(task).find(".responsiblecontainer .dropdown");
                    container.removeClass("open");
                    /*setTimeout(function () {
                        $(task).find(".responsibleusername").removeClass("animated");
                    }, 1000);*/
                }

                function assignUserToCategory(categoryAssigned) {
                    var category = $(boardTableContainer).find("th[data-categoryid='" + categoryAssigned.categoryId + "']").first();
                    // If the category is not a column, we must find the row
                    if (category.length === 0) {
                        category = $(boardTableContainer).find("td[data-rowid='" + categoryAssigned.categoryId + "']").first();
                    }
                    var responsibleContainer = $(category).find(".responsible-for-category").first();
                    taskDrawingService().updateResponsibleForCategory(responsibleContainer, categoryAssigned);
                    setupCategoryResponsible();
                    // activate dropdown
                    return;

                    //var task = $(boardTableContainer).find("div .portlet[data-id='" + taskAssigned.taskId + "']").first();


                }

                function userDroppedEvent(event, ui) {
                    var draggable = ui.draggable[0];
                    var username = $(draggable).data("user");
                    var userId = $(draggable).data("userid");
                    var userDisplayName = $(draggable).data("displayname");
                    var task = event.target;
                    var responsible = $(task).attr("data-responsibleuser");
                    var responsibleUserId = $(task).attr("data-responsibleuserid");

                    var id = $(task).data("id");

                    var options = {
                        taskId: id,
                        user: {
                            taskId: id,
                            userName: username,
                            userId: userId,
                            userDisplayName: userDisplayName,
                            previousUserName: responsible,
                            previousUserId: responsibleUserId
                        }
                    }
                    updateResponsible(options);
                }

                function userDroppedToColumnHeaderEvent(event, ui) {
                    var draggable = ui.draggable[0];
                    var username = $(draggable).data("user");
                    var userId = $(draggable).data("userid");
                    var userDisplayName = $(draggable).data("displayname");
                    var task = $(event.target).closest("th");
                    var id = $(task).data("categoryid");

                    var responsible = {
                        boardId: scope.model.id,
                        categoryId: id,
                        userName: username,
                        userId: userId,
                        userDisplayName: userDisplayName,
                    }

                    boardService.assignResponsibleForCategory(responsible).then(function () {
                        scope.publish("SendBoardCategoryAssigned", responsible);
                    });
                }

                function userDroppedToRowHeaderEvent(event, ui) {
                    var draggable = ui.draggable[0];
                    var username = $(draggable).data("user");
                    var userId = $(draggable).data("userid");
                    var userDisplayName = $(draggable).data("displayname");
                    var task = $(event.target).closest("td");
                    var id = $(task).data("rowid");

                    var responsible = {
                        boardId: scope.model.id,
                        categoryId: id,
                        userName: username,
                        userId: userId,
                        userDisplayName: userDisplayName
                    }

                    boardService.assignResponsibleForCategory(responsible).then(function () {
                        scope.publish("SendBoardCategoryAssigned", responsible);
                    });
                }

                function reapplyTaskBehaviours() {
                    // Make sure all event handlers are disconnected so we don't trigger multple messages
                    $(boardTableContainer).find("#myTask .btn-primary").off();
                    $(boardTableContainer).find("#myTask .btn-danger").off();
                    $(boardTableContainer).find("#boardContainer .status").off();
                    $(boardTableContainer).find(".info-container .clone-icon").off();
                    $(boardTableContainer).find(".info-container .parent-icon").off();
                    //var isViewer = navigationService().isViewer(scope.model.board.assetId);
                    //if (isViewer) return;

                    if (scope.isViewer) {
                        // Active only read only-features
                        setupToggleDescription();
                        setupTaskOpen();
                        return;
                    }
                    // then add behaviour
                    setupTasks();
                    setupStatus();
                    setupTaskModifications();
                    addSortable();
                    if (users && users.length > 0) {
                        taskDrawingService().addUserList(users, updateResponsible);
                    }
                    addTooltipBehaviour();
                    boardDrawService.recalculateZindex();


                    var tags = $(boardTableContainer).find("#taskTags");
                    if (tags.length) {
                        tags.tagit({
                            // availableTags: sampleTags,
                            // This will make Tag-it submit a single form value, as a comma-delimited field.
                            singleField: true,
                            singleFieldNode: $('#tagsSelected'),

                            autocomplete: {
                                delay: 0,
                                minLength: 2,
                                source: ['foo', 'bar']
                            }

                        });
                    }
                }

                function addTooltipBehaviour() {
                    $('[data-toggle="tooltip"]').tooltip();
                }

                function setupCategoryResponsible() {
                    $('.responsible-for-category .hide-button').off();
                    $('.responsible-for-category .hide-button').click(function (e) {
                        var id = $(this).data("categoryid");
                        var responsible = {
                            boardId: scope.model.board.id,
                            categoryId: id,
                            userName: "",
                            userId: "",
                            userDisplayName: "",
                        }

                        boardService.assignResponsibleForCategory(responsible).then(function () {
                            scope.publish("SendBoardCategoryAssigned", responsible);
                        });
                    });
                }




                function setupExpanderAndCategoryResponsible() {
                    $(boardTableContainer).find(".board .rotate-row-headers").off();
                    $(boardTableContainer).find(".board .rotate-row-headers").on("click", function () {
                        var nextDirection = "horizontal";
                        var currentDirection = $(this).data("direction");
                        var rowsDirections = $(boardTableContainer).find("td div ." + currentDirection);
                        if (!scope.settings) scope.settings = {};
                        if (currentDirection === "horizontal") {
                            nextDirection = "vertical";
                            scope.settings.ShowHorizontalRowHeaders = false;
                        } else {
                            scope.settings.ShowHorizontalRowHeaders = true;
                        }
                        $(this).data("direction", nextDirection);
                        $(rowsDirections).switchClass(currentDirection, nextDirection, 50);

                        // Update settings
                        boardService.updateSettings(scope.model.board.id, scope.settings).then(function () {
                            $translate('BOARD_UPDATED_ROWHEADER_DIRECTION').then(function (msg) {
                                toaster.success(msg);
                            });
                        });
                    });

                    setupCategoryResponsible();
                    $(boardTableContainer).find("[data-column]").off();
                    $(boardTableContainer).find("[data-column]").on("click", function () {
                        var button = $(this),                   // the element that was clicked
                            header = button.closest('th'),
                            table = header.closest("table"); // the table in which the cell resides
                        var categoryId = header.data('categoryid');
                        var selector = "tbody tr td[data-categoryid='" + categoryId + "']"; // selector for all body cells in the column
                        var column = table.find(selector).add(header); // all cells in the column

                        // toggle the "hidden" class on all the column cells 
                        column.toggle();
                        var buttonType = button.data("column");
                        var hiddenCol;
                        if (buttonType === "column-hide") {
                            hiddenCol = header.prev();
                        } else {
                            //find hidden column to the right
                            hiddenCol = header.next();
                        }
                        // toggle the "hidden" class on all the column cells
                        hiddenCol.toggle(0);
                        taskDrawingService().readjustTaskWidth();
                    });
                }


                var statusChangeDebounced = debounce(commitStatusChanged, 500, false);
                function setupStatus() {
                    $(boardTableContainer).find(".status").off();
                    $(boardTableContainer).find(".status").click(function (e) {
                        e.preventDefault();

                        var dataStatus = $(this).attr('data-status');
                        var statusText = taskDrawingService().getNextStatus(dataStatus, isKamishibai).status;
                        var container = $(this).closest(".portlet");
                        //var tasktitle = $(container).find(".tasktitle").html();
                        var tasktitle = $(container).find(".taskheader").html();
                        var id = container.data("id");
                        var task = {
                            taskId: id,
                            status: statusText,
                            title: tasktitle
                        }
                        //removed this since the subscribe event also calls this method 
                        updateTaskStatus(task); // Show visual indicator 
                        statusChangeDebounced(task); // debounce the commit untill 1 second gone
                    });
                }

                function commitStatusChanged(task) {
                    boardTaskService().updateStatus(task.taskId, task).then(function (result) {

                        if (task.status === "unacceptable" && scope.hasDeviationAccess) {
                        }
                    });
                }


                function addSortable() {
                    $(boardTableContainer).find(".boardcell div").click(function (e) {
                        e.stopPropagation();
                    });

                    $(boardTableContainer).find(".boardcell").sortable({
                        connectWith: ".boardcell, #droparchive, .actionlist, .actionlist-container",
                        // handle: ".portlet-header",
                        handle: ".tasktitle",
                        cancel: ".portlet-toggle",
                        placeholder: "portlet-placeholder ui-corner-all",
                        scroll: true,
                        scrollSpeed: 100,
                        scrollSensitivity: 10,
                        helper: function (event, element) {
                            return element.clone().appendTo("body");
                        },
                        //remove: taskMoved,
                        update: taskReordered,
                        start: function (event, ui) {
                        },
                        stop: function (event, ui) {
                        }
                    });
                }

                function setupTaskModifications() {
                    // New item
                    var boardId = $(boardTableContainer).find("#boardTable").data("boardid");
                    var activeCell;
                    $(boardTableContainer).find("#myTask").on('hidden.bs.modal', function () {
                        $(activeCell).removeClass("activeCell");
                    });
                    // Add task
                    $(boardTableContainer).find(".board-table-cell").off();
                    $(boardTableContainer).find(".board-table-cell").click(function (e) {
                        //e.preventDefault();
                        newTask(this);
                    });

                    setupTaskOpen();

                }

                $('#myTask').on('shown.bs.modal', function () {
                    setTimeout(function () {
                        $('#taskDescriptionId').focus();
                    }, 10);
                });

                function editTask(id, activeTab) {
                    //e.preventDefault();
                    if (!activeTab) activeTab = "Info";
                    if (scope.settings && scope.settings.PostIts) {
                        postIts = scope.settings.PostIts;
                    } else {
                        postIts = taskDrawingService().getPostIts();
                    }
                    boardTaskService().get(id).then(function (task) {
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'boardTask.html',
                            controller: 'boardTaskController',
                            resolve: {
                                task: function () {
                                    return task;
                                },
                                activeTab: function () {
                                    return activeTab;
                                },
                                options: function () {
                                    var options = {
                                        archiveAvailable: false,
                                        postIts: postIts,
                                        users: users,
                                    }
                                    return options;
                                }
                            }
                        });
                    });
                }

                function newTask(cell, targetColor) {
                    var activeCell = cell;
                    $(activeCell).addClass("activeCell");
                    cellContainer = $(cell);
                    var rowId = cellContainer.data("rowid");
                    var categoryid = cellContainer.data("categoryid");
                    var date = cellContainer.attr("data-date");
                    // If board is weekly board and category is not a date, we will assign friday so the task belongs to correct week
                    if (scope.isWeeklyBoard && !date) {
                        date = scope.week.weekDays[4].date.toJSON();
                    }
                    var task = {
                        id: 0,
                        boardId: scope.model.board.id,
                        title: "",
                        text: "",
                        rowCategoryId: rowId,
                        boardDate: date,
                        columnCategoryId: categoryid,
                        status: 'blank',
                        tags: "",
                        color: targetColor
                    }
                    var responsible = boardLayoutService().getResponsibleForCategory(task);
                    task.responsibleUserId = responsible.responsibleUserId;
                    task.responsibleDisplayName = responsible.responsibleDisplayName;
                    task.responsibleUser = responsible.responsibleUser;
                    if (scope.settings && scope.settings.PostIts) {
                        postIts = scope.settings.PostIts;
                    } else {
                        postIts = taskDrawingService().getPostIts();
                    }
                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        templateUrl: 'newBoardTask.html',
                        controller: 'newBoardTaskController',
                        resolve: {
                            task: function () {
                                return task;
                            },
                            options: function () {
                                return {
                                    showDueDate: !scope.isWeeklyBoard,
                                    users: users,
                                    postIts: postIts
                                }
                            }
                        }
                    });

                    modalInstance.result.then(function (result) {
                        // publish update
                        $(activeCell).removeClass("activeCell");
                    }, function () {
                        $(activeCell).removeClass("activeCell");
                    });

                }

                function actionItemAddedToBoard(event, ui) {
                    var id = $(ui.item).data("id");
                    var to = event.target;
                    var toContainer = $(to).closest("td");
                    var toRowId = toContainer.data("rowid");
                    var toColumnId = toContainer.data("categoryid");
                    var date = toContainer.attr("data-date");
                    if (scope.isWeeklyBoard && !date) {
                        date = scope.week.weekDays[0].date.toJSON();
                    }

                    // Check if task is smart-actionlist.
                    var actionItemToTask = {
                        id: id,
                        boardId: scope.model.board.id,
                        boardDate: date,
                        rowCategoryId: toRowId,
                        columnCategoryId: toColumnId
                    };


                    var boardSourceType = $(ui.item).attr("data-board-source-type");
                    if (boardSourceType && boardSourceType == "SmartActionList") {
                        // Check if user wants to move or clone
                        var modalInstance = $uibModal.open({
                            backdrop: 'static',
                            animation: true,
                            templateUrl: 'moveOrCloneSelector.html',
                            controller: 'moveOrCloneSelector',
                            windowClass: 'newdeviation-modal-window',
                            resolve: {

                            }
                        });
                        modalInstance.result.then(function (result) {
                            $(ui.item).remove();
                            if (result == "move") {
                                boardTaskService().moveActionItemToTask(actionItemToTask).then(function (task) {
                                    scope.publish("SendActionItemMovedToTask", task);
                                });
                            }
                            if (result == "clone") {
                                var task = {
                                    columnCategoryId: toColumnId,
                                    rowCategoryId: toRowId
                                }
                                var responsible = boardLayoutService().getResponsibleForCategory(task);
                                var timeNow = new Date();
                                var timezone = timeNow.getTimezoneOffset() / 60 * (-1);
                                var cloneOptions = {
                                    boardId: scope.model.board.id,
                                    boardDate: date,
                                    timeZoneOffset: timezone,
                                    columnCategoryId: toColumnId,
                                    rowCategoryId: toRowId,
                                    responsibleUser: responsible.responsibleUser,
                                    responsibleDisplayName: responsible.responsibleDisplayName,
                                    responsibleUserId: responsible.responsibleUserId
                                }
                                boardTaskService().cloneTask(id, cloneOptions).then(function (clone) {

                                });
                            }
                        }, function () {
                            ui.sender.sortable("cancel");
                        });
                    } else {
                        boardTaskService().moveActionItemToTask(actionItemToTask).then(function (task) {
                            $(ui.item).remove();
                            scope.publish("SendActionItemMovedToTask", task);
                        });
                    }




                }

                function taskReordered(event, ui) {
                    var taskBeingMoved = ui.item;
                    var isActionItem = $(taskBeingMoved).attr("data-is-action-item");
                    if (isActionItem && isActionItem === "true") {
                        actionItemAddedToBoard(event, ui);
                        return;
                    }

                    var toContainer = $(taskBeingMoved).closest(".boardcell");
                    if (toContainer[0] != event.target) {
                        return;
                    }

                    var id = $(taskBeingMoved).data("id");
                    var responsibleUserId = $(taskBeingMoved).attr("data-responsibleuserid");
                    var responsibleUser = $(taskBeingMoved).attr("data-responsibleuser");
                    var toContainer = $(taskBeingMoved).closest("td");
                    var toRowId = toContainer.data("rowid");
                    var toColumnId = toContainer.data("categoryid");
                    if (!toRowId || !toColumnId) {
                        return;
                    }

                    var cell = $(toContainer).find(".boardcell");
                    var date = toContainer.attr("data-date");
                    // If board is weekly board and category is not a date, we will assign friday so the task belongs to correct week
                    if (scope.isWeeklyBoard && !date) {
                        date = scope.week.weekDays[4].date.toJSON();
                    }
                    var index = cell.children().index(ui.item);
                    var taskOrders = [];
                    $.each(cell.children(), function (key, val) {
                        var taskId = $(val).data("id");
                        taskOrders[key] = { taskId: taskId, index: key };
                    });

                    var from = event.target;
                    var fromContainer = $(from).closest("td");
                    var fromRowId = fromContainer.data("rowid");
                    var fromColumnId = fromContainer.data("categoryid");

                    var taskMoved = {
                        taskId: id,
                        fromRowId: fromRowId,
                        fromColumnId: fromColumnId,
                        toIndex: index,
                        toRowId: toRowId,
                        toColumnId: toColumnId,
                        boardDate: date,
                        taskOrders: taskOrders,
                        responsibleUserId: responsibleUserId,
                        responsibleUser: responsibleUser,
                    };
                    console.log("Before publish move:");
                    console.log(JSON.stringify(taskMoved));
                    if (!scope.isWeeklyBoard) { // If not weeklyboard, we should fetch the current data so the task does not update with null value.
                        boardTaskService().get(id).then(function (task) {
                            taskMoved.boardDate = task.boardDate;
                            publishMoveTask(taskMoved);
                        })
                    } else {
                        publishMoveTask(taskMoved);
                    }
                }
                function updateSubTaskDueDate(task, days) {
                    var modalInstance = $uibModal.open({
                        backdrop: 'static',
                        animation: true,
                        templateUrl: 'moveSubTask.html',
                        controller: 'moveSubTaskController',
                        resolve: {
                            parenttask: function () {
                                return task;
                            },
                            days: function () {
                                return days;
                            }
                        }
                    });

                    modalInstance.result.then(function (result) {
                    }, function () {
                    });
                }

                function getDateDifferenceInDays(startTime, endTime) {
                    var start = moment(startTime).startOf('day');
                    var end = moment(endTime).startOf('day');
                    var duration = end.diff(start, 'days', true);
                    return duration;
                }


                function publishMoveTask(taskMovedSettings) {

                    boardTaskService().get(taskMovedSettings.taskId).then(function (task) {
                        if (task.subTaskCount > 0 && task.subTaskCount > task.subTaskCompletedCount) {
                            var changedDuration = getDateDifferenceInDays(task.boardDate, taskMovedSettings.boardDate);
                            boardTaskService().moveTask(taskMovedSettings).then(function () {
                                if (changedDuration != 0 && !Number.isNaN(changedDuration)) { //duration is a valid number
                                    updateSubTaskDueDate(task, changedDuration);
                                }
                                boardDrawService.recalculateZindex();

                            });

                        } else {
                            boardTaskService().moveTask(taskMovedSettings).then(function () {
                                boardDrawService.recalculateZindex();
                            });
                        }
                    })
                }

                function moveTaskDateOnBoard(moveSettings) {

                    if (!scope.isWeeklyBoard) {
                        var taskBeingMoved = $(boardTableContainer).find("td .portlet[data-id='" + moveSettings.taskId + "']");
                        if (taskBeingMoved.length > 0) {
                            boardTaskService().get(moveSettings.taskId).then(function (task) {
                                updateExistingTask(task);
                            });
                        }
                        return;
                    }
                    // Weekly Board
                    // check that the date being moved to are in current week.
                    // Check boardDate, if not in current active week, we need to remove it from board
                    if (timeService.isDateInWeek(moveSettings.boardDate, scope.week) === false) {
                        removeTaskFromBoard(moveSettings.taskId);
                        return;
                    } else { // if task moved does not exist on board, we need to add it.
                        var taskBeingMoved = $(boardTableContainer).find("td .portlet[data-id='" + moveSettings.taskId + "']");
                        if (taskBeingMoved.length === 0) {
                            boardTaskService().get(moveSettings.taskId).then(function (task) {
                                if (!task.isActionItem) {
                                    addTaskToBoard(task, true);
                                }
                            });
                        }
                    }

                    var taskBeingMoved = $(boardTableContainer).find("td .portlet[data-id='" + moveSettings.taskId + "']");
                    $(taskBeingMoved).addClass("animated bounce");

                    var toContainer = $(boardTableContainer).find("table tr td[data-categoryid='" + moveSettings.toColumnId + "'][data-rowid='" + moveSettings.toRowId + "']").first();
                    var cell = toContainer.find(".boardcell").first();

                    if (cell.children().length === 0) {
                        cell.append(taskBeingMoved);
                        return;
                    }
                    var isPartOfCellAlready = cell.children(".portlet[data-id='" + moveSettings.taskId + "']");
                    if (isPartOfCellAlready.length == 1) { // Then do nothing
                        // The task is already part of board
                        return;
                    }

                    var insertAfterIndex = moveSettings.toIndex - 1; // Assume the task is added to cell
                    if (insertAfterIndex < 0) {
                        cell.prepend(taskBeingMoved);
                    } else {
                        taskBeingMoved.insertAfter(cell.children()[insertAfterIndex]);
                    }

                }

                function moveTaskOnBoard(moveSettings) {

                    // check that the date being moved to are in current week.
                    if (scope.isWeeklyBoard) {
                        // Check boardDate, if not in current active week, we need to remove it from board
                        if (timeService.isDateInWeek(moveSettings.boardDate, scope.week) === false) {
                            removeTaskFromBoard(moveSettings.taskId);
                            return;
                        } else { // if task moved does not exist on board, we need to add it.
                            var taskBeingMoved = $(boardTableContainer).find("td .portlet[data-id='" + moveSettings.taskId + "']");
                            if (taskBeingMoved.length === 0) {
                                boardTaskService().get(moveSettings.taskId).then(function (task) {
                                    addTaskToBoard(task);
                                });
                            }
                        }

                    }

                    var taskBeingMoved = $(boardTableContainer).find("td .portlet[data-id='" + moveSettings.taskId + "']");
                    $(taskBeingMoved).addClass("animated bounce");

                    var toContainer = $(boardTableContainer).find("table tr td[data-categoryid='" + moveSettings.toColumnId + "'][data-rowid='" + moveSettings.toRowId + "']").first();
                    if (toContainer.length === 0) {
                        toContainer = $(boardTableContainer).find("table tr td[data-categoryid='" + moveSettings.toRowId + "'][data-rowid='" + moveSettings.toColumnId + "']").first();
                    }
                    var cell = toContainer.find(".boardcell").first();

                    if (cell.children().length === 0) {
                        cell.append(taskBeingMoved);
                        return;
                    }
                    if (moveSettings.toIndex === 0) {
                        cell.prepend(taskBeingMoved);
                        return;
                    }
                    var isPartOfCellAlready = cell.children(".portlet[data-id='" + moveSettings.taskId + "']");
                    var insertAfterIndex = moveSettings.toIndex - 1; // Assume the task is added to cell
                    if (isPartOfCellAlready.length == 1) {
                        // The task is already part of board
                        var currentIndexInCell = cell.children().index(isPartOfCellAlready);
                        if (currentIndexInCell < moveSettings.toIndex) { // if part of board and moving to a greater index then we should use the target index
                            insertAfterIndex = moveSettings.toIndex;
                        }
                    }
                    if (insertAfterIndex < 0) {
                        cell.prepend(taskBeingMoved);
                    } else {
                        taskBeingMoved.insertAfter(cell.children()[insertAfterIndex]);
                    }

                }

                function removeTaskFromBoard(id) {

                    $(boardTableContainer).find("td .portlet[data-id='" + id + "']").addClass("animated zoomOutDown");
                    setTimeout(function () {
                        $(boardTableContainer).find("td .portlet[data-id='" + id + "']").remove();
                    }, 500);
                }

                function removeTasksFromBoard(taskIds) {
                    angular.forEach(taskIds, function (id, key) {
                        removeTaskFromBoard(id);
                    });

                }

                function addTaskToBoard(task, shouldReApply) {
                    if (task == null) return;
                    if (task.columnCategoryId == null) return;
                    var exists = $(boardTableContainer).find("div .portlet[data-id='" + task.id + "']");
                    if (exists.length > 0) return;
                    if (scope.isWeeklyBoard) {
                        // Check boardDate, if not in current active week, we will not add it to board
                        if (timeService.isDateInWeek(task.boardDate, scope.week) === false) return;
                    }
                    var tdContainer = $(boardTableContainer).find("table tr td[data-categoryid='" + task.columnCategoryId + "'][data-rowid='" + task.rowCategoryId + "']").first();
                    var boardCell = tdContainer.find(".boardcell");
                    var newItem = boardDrawService.drawTask(task, scope.model.access, 'bounce', scope.isWeeklyBoard, isKamishibai);
                    boardCell.append(newItem);
                    taskDrawingService().updateSubTask(scope, task);
                    if (shouldReApply) {
                        reapplyTaskBehaviours();
                    }
                }

                function updateExistingTask(task) {
                    var portlet = $(boardTableContainer).find("div .portlet[data-id='" + task.id + "']").first();
                    var changedContent = boardDrawService.drawTask(task, scope.model.access, 'bounce', scope.isWeeklyBoard, isKamishibai);
                    portlet.replaceWith(changedContent);
                    // BUG => Updating subtask causes comments container not to be updated....
                    taskDrawingService().updateSubTask(scope, task);
                }

                function updateCommentCount(comment) {
                    var portlets = $(boardTableContainer).find("div .portlet[data-id='" + comment.boardTaskId + "']");
                    for (let index = 0; index < portlets.length; index++) {
                        const portlet = portlets[index];
                        taskDrawingService().updateCommentCount(comment.taskCommentCount, portlet, "bounce", scope.isWeeklyBoard);
                    }
                    removeAnimations();
                }
                function updateAttachmentCount(attachment) {
                    var portlet = $(boardTableContainer).find("div .portlet[data-id='" + attachment.taskId + "']").first();
                    taskDrawingService().updateAttachmentCount(attachment.attachmentCount, portlet, "bounce", scope.isWeeklyBoard);
                }
            } // Close link function
        }
    }]);