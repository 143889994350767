import toaster from "@common/components/toast"

angular.module('DigiLean').controller('areaAdmin', ['$scope', '$translate', 'areaService','modalService',
    function ($scope, $translate,areaService, modalService) {
        $scope.removeItem= function (item) {
            $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_AREA_CONFIRMATION']).then(function (translations) {
                var modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + item.name + '?',
                    bodyText: translations.ADMIN_MESSAGE_DELETE_AREA_CONFIRMATION 
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    areaService().delete(item.id).then(function (data) {  
                        var index = $scope.areas.indexOf(item);
                        $scope.areas.splice(index, 1);
                        $translate('ADMIN_MESSAGE_AREA_DELETED').then(function (msg) {
                            toaster.info(msg); 
                        }, function (translationId) {
                            $scope.headline = translationId;
                        });
                    },function (err) {
                    });
                });
            });
        }

        $scope.setItemViewMode = function (item) {
            item.isEditMode = false;
        }
        $scope.setItemEditMode = function (item) {
            item.isEditMode = true;
        }

        $scope.addItem = function () {
            var item = {
                isNew: true,
                id: 0,
                name: "",
                description: ""
            }
            areaService().add(item).then(function (savedItem) {
                $translate('ADMIN_MESSAGE_AREA_ADDED').then(function (msg) {
                    toaster.success(msg);
                }, function (translationId) {
                    $scope.headline = translationId;
                });
                savedItem.isEditMode = true;
                $scope.areas.push(savedItem);
                setTimeout(function () {
                    $(".project-name").focus();
                }, 50);
            });
        }

        $scope.area = [];
        var load = function () {
            areaService().getList().then(function (areas) {
                $scope.areas = areas;
            });
        }
        load();

        $scope.saveItem = function (item) {
            areaService().update(item).then(function () {
                $translate('ADMIN_MESSAGE_AREA_MODIFIED').then(function (msg) {
                    toaster.success(msg);
                }, function (translationId) {
                    $scope.headline = translationId;
                });
            });     
        }

    }]);