import * as customerService from "@common/services/customers/customerService"
import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .directive("mailSender", ['$translate',
        function ($translate) {
            return {
                templateUrl: 'mailsender.html',
                restrict: 'E',
                scope: {
                    
                },
                link: function (scope, elem, attrs) {

                    // scope.content = "";
                    scope.title = "";
                    scope.content = "";
                    scope.sendMail = function () {
                        var mail = {
                            title: scope.title,
                            message: scope.content
                        }
                        customerService.sendMail(mail).then(function (result) {
                            scope.title = "";
                            scope.content = ""; 
                            toaster.success("Mail sent to " + result + " users");
                        });

                    };
                    scope.froalaOptions = {
                        toolbarButtons: ['bold', 'italic', 'underline', 
                            '|', 'fontFamily', 'fontSize', 'color', 'inlineStyle', 'paragraphStyle', 
                            '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 
                            '|', 'insertLink', 'insertImage', 'insertTable']
                    };
                    scope.settingsChangeHandler = function (settings) {
                        scope.content = settings.content;
                        
                    };
                }
            };
        }]);
