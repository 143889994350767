import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .controller("a3templateLayoutController", ['$scope', '$filter', '$uibModal', '$state', '$stateParams', '$translate', '$window', 'a3templateService', 'a3Service',
        function ($scope, $filter, $uibModal, $state, $stateParams, $translate, $window, a3templateService, a3Service) {

            $scope.a3templateId = parseInt($stateParams.id);
            $scope.a3info = null;
            $scope.versionNumber = parseInt($stateParams.version);
            $scope.canEdit = false;
            $scope.width = 2338;
            $scope.height = 1654;
            $scope.zoom = 0.6;
            $scope.version = {};
            $scope.isAdminMode = false;
            $scope.defaultLayout = null;


            function loadTemplate() {
                a3templateService().get($scope.a3templateId).then(function (template) {
                    $scope.template = template;
                    $scope.widgets = [];
                    var withoutProjectInfo = $scope.template.steps.filter(s => !s.isProjectInfo);
                    var layout = $scope.defaultLayout[withoutProjectInfo.length - 1];

                    $scope.a3info = getStepWidget("a3info-template", template, [0, 0, 24, 0]);
                    var info = {
                        component: 'a3info-template',
                        name: 'A3 Info',
                        model: template.id,
                        sizeY: 0
                    };

                    var widgets = [];
                    widgets.push(info);
                    var stepIndex = 0;
                    $scope.template.steps.forEach(step => {
                        var widget = {
                            component: "a3step-template",
                            model: {
                                id: step.id,
                                style: step.style,
                                name: step.name,
                                content: step.defaultContent
                            },
                            row: step.row != null ? step.row : layout[stepIndex][0],
                            col: step.col != null ? step.col : layout[stepIndex][1],
                            sizeX: step.sizeX != null ? step.sizeX : layout[stepIndex][2],
                            sizeY: step.sizeY != null ? step.sizeY : layout[stepIndex][3]
                        };

                        if (step.isProjectInfo) {
                            widget.component = "cost-widget";
                        }
                        else {
                            stepIndex++
                        }

                        widgets.push(widget);

                        selectStep(true, widget);

                    });
                    $scope.widgets = widgets;
                });
            }


            a3Service().getDefaultLayout().then(function (result) {
                $scope.defaultLayout = result;
                loadTemplate();
            });


            var w = angular.element($window);
            adjustZoom(w[0].innerWidth);
            w.bind('resize', function (event) {
                adjustZoom(event.currentTarget.window.innerWidth)
            });

            function adjustZoom(windowWidth) {

                $scope.zoom = windowWidth / ($scope.width + 700);
            }

            $scope.save = function () {
                // 
                var layout = [];
                angular.forEach($scope.widgets, function (widget) {

                    $scope.template.steps.forEach(x => {
                        if (x.id == widget.model.id) {
                            x.col = widget.col;
                            x.row = widget.row;
                            x.sizeX = widget.sizeX;
                            x.sizeY = widget.sizeY;
                            x.model = null;
                        }
                    });

                });
                a3templateService().saveLayout($scope.a3templateId, $scope.template.steps).then(function () {
                    toaster.success("Layout saved");
                });

            }

            $scope.gridsterOptions = {
                margins: [25, 25],
                columns: 24,
                resizable: {
                    enabled: true,
                    handles: ['n', 'e', 's', 'w', 'ne', 'se', 'sw', 'nw'],

                    stop: function (event, element, widget) {
                        $scope.save();
                    }
                },
                draggable: {
                    enabled: true, // whether dragging items is supported
                    // handle: 'h4.preview-header, div#a3info.drag-box, div.drag-box', // optional selector for drag handle
                    stop: function (event, $element, widget) {
                        $scope.save();
                    }
                }
            };

            function handleResizing() {
                $scope.widgets.forEach(w => {
                    var widgetEl = $("#widget" + w.id).find("#a3step");

                    var froalaEl = widgetEl.find("div.fr-view");

                    if (froalaEl.length > 0) {
                        var height = widgetEl[0].clientHeight
                        froalaEl.height(height - 115);
                    }
                });
            }

            $scope.widgets = [];

            function defaultLayout(a3) {

                var layoutPositions = $scope.version.steps.length;

                angular.forEach($scope.version.steps, function (step, key) {
                    step.selected = true;
                    var stepType = "a3step";
                    var model = step;

                    if (step.component === 'cost-widget') {
                        model = a3.a3Project;
                        stepType = 'cost-widget';
                        $scope.widgets.push(getStepWidget(stepType, step, [13, 0, 24, 3]));
                        return;
                    }

                    $scope.widgets.push(getStepWidget(stepType, model, $scope.defaultLayout[layoutPositions][key]));

                });
                $scope.a3info = getStepWidget("a3info-template", a3, [0, 0, 24, 0]);
                $scope.widgets.push($scope.a3info);

            }



            function getStepForId(stepid) {
                var step = $filter('filter')($scope.template.steps, { id: stepid })[0];
                return step;
            }

            function getStepWidget(component, model, position) {
                model.versionNumber = $scope.versionNumber;
                var widget = {
                    model: model,
                    header: model.name,
                    component: component,
                    row: position[0],
                    col: position[1],
                    sizeX: position[2],
                    sizeY: position[3],
                    a3StepId: model.id
                }
                if (component === "a3info-template" || component === "cost-widget") { // a3info and cost is not linked to step so we need to set it to null
                    widget.a3StepId = null;
                }
                return widget;
            }

            var selectStep = function (selected, widget) {
                var stepFromCollection = $scope.template.steps.filter(x =>
                    (widget.model && x.id === widget.model.id)
                    || (widget.model && widget.model.step && x.id === widget.model.step.id)
                    || x.component === widget.component)[0];
                if (stepFromCollection) {
                    stepFromCollection.selected = selected;
                }
            }

            function findMaxRow() {
                var max = 0;
                $scope.widgets.forEach(x => {
                    if (x.row > max)
                        max = x.row;
                });
                return max;
            }

            $scope.zoomIn = function () {
                $scope.zoom = $scope.zoom + 0.01;
            };
            $scope.zoomOut = function () {
                $scope.zoom = $scope.zoom - 0.01;
            };


            $scope.clickGridsterItem = function (widget, e) {

                if (widget.component === 'a3info-template') {
                    e.preventDefault();
                    e.stopPropagation();

                }
            };

            $scope.navigateToDesigner = function () {
                $state.go('a3designer', { id: $scope.a3templateId });
            }


        }]);

