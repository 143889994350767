import toaster from "@common/components/toast"

var DigiLean = angular.module('DigiLean');
DigiLean.controller('myA3sController', ['$scope', '$state', '$element', '$filter', '$uibModal', '$translate', 'a3Service', 'navigationService', 'boardTaskService', 'taskDrawingService',
    function ($scope, $state, $element, $filter, $uibModal, $translate, a3Service, navigationService, boardTaskService, taskDrawingService) {

        // Check if user has A3.
        $scope.hasA3Module = navigationService().hasModule("A3");
        $scope.subscribe('UserAuthenticatedAndReady', function (profile) {
            var hasAccess = $filter('filter')(profile.modules, { name: "A3" });
            $scope.hasA3Module = hasAccess.length > 0;
        });

        $scope.a3s = [];
        $scope.items = [];

        $scope.isLoading = true;
        a3Service().getA3forUser().then(function (a3s) {
          
            $scope.a3s = a3s;
            // add counters for pr status that can be showed on a3.
            angular.forEach($scope.a3s, function (a3) {
                calculateTaskStatuses(a3);
                a3.showActionListTasks = false;
                a3.a3 = a3;
                angular.forEach(a3.tasks, function (task) {
                    task.boardDate = moment(task.boardDate).toDate();
                });
            });
            $scope.isLoading = false;
        });


        $scope.subscribe("A3sDeleted", function (ids) {
            // remove a3 from list
            var a3ToRemove = $filter('filter')($scope.a3s, idInList('id', ids));
            if (a3ToRemove.length > 0) {
                angular.forEach(a3ToRemove, function (a3) {
                    var index = $scope.a3s.indexOf(a3);
                    $scope.a3s.splice(index, 1);
                });
                $scope.$apply();
            }
        });

        $scope.subscribe('TaskStatusUpdated', function (status) {
            angular.forEach($scope.a3s, function (a3) {
                var task = $filter('filter')(a3.tasks, { id: status.taskId });
                if (task.length > 0) {
                    task[0].status = status.status;
                    $scope.$apply(function () {
                        calculateTaskStatuses(a3);
                    });
                }
            });
        });

        function calculateTaskStatuses(a3) {
            a3.tasksOpen = $filter('filter')(a3.tasks, { status: "blank" }).length;
            a3.tasksCompleted = $filter('filter')(a3.tasks, { status: "OK" }).length;
            a3.tasksInComplete = $filter('filter')(a3.tasks, { status: "unacceptable" }).length;
        }

        $scope.createNew = function () {

            var modalInstance = $uibModal.open({ backdrop: 'static',
                animation: true,
                templateUrl: 'newA3Form.html',
                controller: 'newA3ModalController',
                resolve: {
                    a3: function () {
                        return {
                            id: 0,
                            title: "",
                            assetId: ""
                        }
                    }

                }
            });

  /*          // When user has created new a3
            modalInstance.result.then(function (a3) {
                a3Service().addA3(a3).then(function (savedA3) {
                    $state.go('a3wizard', { id: savedA3.id }, { reload: false });
                    $scope.publish("SendA3Created", savedA3);
                });
            }, function () {
                //$log.info('Modal dismissed at: ' + new Date());
            });
*/
        };

        $scope.showActionListTasks = function (a3) {
            a3.showActionListTasks = !a3.showActionListTasks;

            // get user for a3
            a3Service().getUserlistForA3(a3.id).then(function (users) {
                a3.users = users;
            });
        };

        // Datepicker setup
        $scope.formats = ['dd.MM.yyyy', 'dd-MMMM-yyyy', 'yyyy/MM/dd', 'yyyy-MM-dd', 'shortDate']
        $scope.format = $scope.formats[0];
        $scope.altInputFormats = ['M!/d!/yyyy'];

        $scope.opened = [];
        $scope.open = function (index) {
            $scope.opened[index] = true;
        };

        $scope.changeActionDate = function (item, $event) {
            const date = $event.originalEvent.detail
            item.boardDate = date
            if (item.boardDate) { // Valid date format
                item.error = false;
                var actionDate = {
                    taskId: item.id,
                    actionDate: item.boardDate
                };
                a3Service().updateItemActionDate(actionDate).then(function () {
                    $translate('A3_ACTION_ITEM_SAVED').then(function (msg) {
                        toaster.success(msg);
                    });
                    // publish event on change
                    $scope.publish("SendTaskDateUpdated", actionDate);
                });
            } else {
                $translate('COMMON_NOT_VALID_DATE').then(function (trans) {
                    item.error = trans;
                });

            }
        };

        $scope.updateResponsible = function (user, item, a3) {
            item.responsibleDisplayName = user.displayName;
            var taskAssigned =
                {
                    taskId: item.id,
                    a3Id: a3.a3id,
                    userId: user.id,
                    userName: user.fullName,
                    userDisplayName: user.displayName,
                    previousUserId: item.responsibleUserId,
                    previousUserName: item.responsibleUser
                };
            var options = {
                taskId: item.id,
                user: taskAssigned
            };
            boardTaskService().assignUser(options).then(function () {
               
            });
        };

        $scope.changeStatus = function (item) {
            item.status = taskDrawingService().getNextStatus(item.status).status;
            var status = {
                taskId: item.id,
                status: item.status
            };
            boardTaskService().updateStatus(item.id, status).then(function () {
                
            });
        };

        $scope.newPieChart = {
            data: [0, 0, 0, 0], 
            options: {
                radius: 18,
                fill: ["#f3f3f4"],
            }
        };
        $scope.planPieChart = {
            data: [1, 1, 1, 1], 
            options: {
                radius: 18,
                //fill: ["#434348", "#f3f3f4", "#f3f3f4", "#f3f3f4"],

                fill: ["#5cb85c", "#f3f3f4", "#f3f3f4","#f3f3f4"],
            }
        };
        $scope.doPieChart = {
            data: [1, 1, 1, 1], 
            options: {
                radius: 18,
                fill: ["#5cb85c", "#5cb85c", "#f3f3f4","#f3f3f4"],
            }
        };
        $scope.checkPieChart = {
            data: [1, 1, 1, 1], 
            options: {
                radius: 18,
                fill: ["#5cb85c", "#5cb85c", "#5cb85c","#f3f3f4"],
            }
        };
        $scope.actPieChart = {
            data: [1, 1, 1, 1], 
            options: {
                radius: 18,
                fill: ["#5cb85c", "#5cb85c", "#5cb85c", "#5cb85c"]
            }
        };


    }]);