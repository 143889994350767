// # common web client files

// External libraries *************************

// jquery
import * as jq from 'jquery';
window.$ = window.jQuery = jq;
import 'jquery-ui-dist/jquery-ui';
import 'jquery-ui-touch-punch/jquery.ui.touch-punch';

// angular
import 'angular';
import "@uirouter/angularjs"
import 'angular-animate';
import 'angular-aria';
import 'angular-bootstrap-lightbox';
import 'angular-bootstrap-toggle/dist/angular-bootstrap-toggle.min.js';
import 'angularjs-dragula';
import 'angular-dynamic-locale';
import 'angular-loading-bar';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-ui-bootstrap';
import 'angular-ui-sortable';

import 'angular-material';
import '../node_modules/angular-material/angular-material.min.css';

import '@common/thirdparty/ng-table/ng-table-digilean.min';  // Do not upgrade for now, there is webpack problem with tr in filter templates.
import '@common/thirdparty/ng-table/ng-table.min.css';


import "@common/thirdparty/angular-gridster/angular-gridster"
import "@common/thirdparty/angular-gridster/angular-gridster.css"


import '../node_modules/ng-sortable/dist/ng-sortable.min.css';

import 'ng-sortable';
import '../node_modules/ng-sortable/dist/ng-sortable.style.css';
import '@common/css/ng-sortable-style.css';

import '@common/thirdparty/peity/angular-peity';

// bootstrap
import '@common/thirdparty/bootstrap/js/bootstrap.min';
import '@common/thirdparty/bootstrap-colorpicker/bootstrap-colorpicker-module.min';
import '@common/thirdparty/bootstrap/css/bootstrap.css';
import '@common/thirdparty/bootstrap-colorpicker/colorpicker.min.css';
import '../node_modules/angular-bootstrap-toggle/dist/angular-bootstrap-toggle.min.css';

// highcharts
import 'highcharts/highstock';
import Highcharts from 'highcharts';
import pareto from 'highcharts/modules/pareto';
pareto( Highcharts );
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);
import 'highcharts-regression';
import '@common/thirdparty/highcharts/parallel-coordinates.js'; //needed together with pareto
import '@common/thirdparty/highcharts/grouped-categories.js';
import highchartsMore from '@common/thirdparty/highcharts/highcharts-more';
highchartsMore(Highcharts)
import '@common/css/highcharts-theming.css'

// froala editor
import 'froala-editor/js/plugins/align.min';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/plugins/font_size.min';
import 'froala-editor/js/plugins/colors.min';
import 'froala-editor/js/plugins/draggable.min';
import 'froala-editor/js/plugins/emoticons.min';
// import 'froala-editor/js/plugins/fullscreen.min';
import 'froala-editor/js/plugins/image.min';
import 'froala-editor/js/plugins/link.min';
import 'froala-editor/js/plugins/lists.min';
import 'froala-editor/js/plugins/quick_insert.min';
import 'froala-editor/js/plugins/quote.min';
import 'froala-editor/js/plugins/special_characters.min';
import 'froala-editor/js/plugins/table.min';
import 'froala-editor/js/plugins/video.min';
import 'froala-editor/js/plugins/word_paste.min';
import 'froala-editor/js/plugins/paragraph_format.min';
import 'froala-editor/js/plugins/code_view.min';

import '../node_modules/froala-editor/css/froala_editor.css';
import '../node_modules/froala-editor/css/froala_editor.pkgd.css';
import '../node_modules/froala-editor/css/froala_style.css';
import '../node_modules/froala-editor/css/plugins/colors.css';
import '../node_modules/froala-editor/css/plugins/table.min.css';
import '../node_modules/froala-editor/css/plugins/draggable.css';
import '../node_modules/froala-editor/css/plugins/emoticons.css';
// import '../node_modules/froala-editor/css/plugins/fullscreen.css';
import '../node_modules/froala-editor/css/plugins/image.css';
import '../node_modules/froala-editor/css/plugins/video.css';
import '../node_modules/froala-editor/css/plugins/line_breaker.css';
import '../node_modules/froala-editor/css/plugins/special_characters.css';
import '../node_modules/froala-editor/css/themes/gray.min.css';
import '../node_modules/froala-editor/css/themes/dark.min.css';

// Plugins
import '@common/thirdparty/tagit/tag-it.min';
import '@common/thirdparty/fullcalendar/fullcalendar.min';
import '@common/thirdparty/fullcalendar/locale-all';
import '@common/thirdparty/peity/jquery.peity.min';
import '@common/thirdparty/countUp/countUp.min';
import '@common/thirdparty/ngImgCrop/ng-img-crop';

import "lottie-web"

import "sweetalert"

// CSS
import '../node_modules/animate.css/animate.compat.css';
import "dropzone/dist/dropzone.css";
import '@common/thirdparty/fullcalendar/fullcalendar.css';
import '../node_modules/angularjs-dragula/dist/dragula.min.css';
// import '@common/thirdparty/tagit/tag-it.min';
import '@common/thirdparty/jquery.tagit.css';
import '@common/thirdparty/ngImgCrop/ng-img-crop.css';
import '../node_modules/tributejs/dist/tribute.css';

//import '@common/thirdparty/font-awesome/css/all.css'; //loading this as static file