import * as customerService from "@common/services/customers/customerService"
import * as profileService from "@common/services/user/profileService"
import * as boardService from "@common/services/boardService"
import * as profileImageService from "@common/services/user/profileImageService"
import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .directive("deviationActionList", ['$translate', '$uibModal', 'actionlistService', 'taskDrawingService', 'boardTaskService',
        function ($translate, $uibModal, actionlistService, taskDrawingService, boardTaskService) {
            return {
                templateUrl: 'deviationActionList.html',
                restrict: 'E',
                scope: {
                    'deviationid': '=',
                    'users': '<',
                    'canEdit': '='
                },
                link: function (scope, elem, attrs) {
                    scope.users = [];
                    scope.allusers = [];
                    scope.items = [];
                    scope.boards = [];
                    scope.showMoreButton = true;

                    scope.boardoptions = {
                        mandatory: false
                    }

                    scope.$watch("deviationid", function () {
                        if (!scope.deviationid) return;

                        // Get actionlist
                        actionlistService().getActionListForDeviation(scope.deviationid).then(function (items) {
                            if (items.length !== 0) {
                                scope.items = items;
                                scope.items.forEach(function (item) {
                                    setBoardDate(item, item.boardDate);
                                });
                            }
                            setResponsible();
                        });
                        boardService.getAllBoardNames().then(function (data) {
                            scope.boards = data;
                        });
                    });

                    function setBoardDate(item, date) {
                        if (date) {
                            item.boardDate = moment(date).toDate();
                        } else {
                            item.boardDate = null;
                        }

                    }

                    scope.$watch("users", function () {
                        if (!scope.users)
                            return;
                        setResponsible();
                    });

                    scope.loadMoreUsers = async function () {
                        if (scope.allusers.length > 0) {
                            scope.users = scope.allusers;
                            scope.showMoreButton = false;
                        } else {
                            scope.allusers = await profileImageService.getAllUsersIncludeProfileImage()
                            scope.users = scope.allusers
                            scope.showMoreButton = false;
                        }
                    }

                    async function setResponsibleFromAllUsers(task) {
                        if (!task.responsibleUserId)
                            return

                        if (scope.allusers.length == 0) {
                            scope.allusers = await profileImageService.getAllUsersIncludeProfileImage()
                        }
                        const user = scope.allusers.find(u => u.id == task.responsibleUserId)
                        if (user)
                            task.user = user
                    }
                    function setResponsible() {
                        if (scope.items) {
                            scope.items.forEach(function (item) {
                                setResponsibleFromAllUsers(item)
                                
                                setBoardDate(item, item.boardDate);
                                item.datePicker = {
                                    opened: false
                                }
                            });
                        }
                    }

                    scope.openTask = function (activeTab, subtask) {
                        if (!activeTab) activeTab = "Info";
                        var options = {
                            canChangeBoard: false
                        };

                        boardTaskService().get(subtask.id).then(function (task) {
                            $uibModal.open({
                                backdrop: 'static',
                                animation: true,
                                templateUrl: 'boardTask.html',
                                controller: 'boardTaskController',
                                resolve: {
                                    task: function () {
                                        return task;
                                    },
                                    activeTab: function () {
                                        return activeTab;
                                    },
                                    options: function () {
                                        return options;
                                    }
                                }
                            });
                        })

                    }

                    scope.setActive = function (task) {
                        task.active = true;
                        setTimeout(function () {
                            $(elem).find(".project-name").focus();
                        }, 50);
                    }

                    scope.add = function () {
                        var item = {
                            title: "",
                            boardDate: moment().utc().toDate(),
                            user: null
                        }
                        actionlistService().createDevationActionItem(scope.deviationid, item).then(function (actionItem) {
                            if (actionItem.boardDate) {
                                actionItem.boardDate = moment(actionItem.boardDate).toDate();
                            }
                            actionItem.datePicker = {
                                opened: false
                            }
                            scope.items.unshift(actionItem);
                            scope.setActive(actionItem);
                            scope.publish("SendCreateActionListItem", actionItem);
                        });
                    }

                    // Also need to subscribe to events where tasks are changed by other users

                    scope.delete = function (item) {
                        actionlistService().deleteActionItem(item.id).then(function () {
                        });
                    };

                    scope.saveItemDescription = function (task) {
                        task.active = false;
                        var taskDescription = {
                            taskId: task.id,
                            description: task.title
                        };
                        actionlistService().updateItemDescription(task.id, taskDescription).then(function () {
                            $translate('DEVIATION_ACTION_ITEM_SAVED').then(function (msg) {
                                toaster.success(msg);
                            });
                            scope.publish("SendTaskDescriptionUpdated", taskDescription);
                        });
                    }

                    scope.changeActionDate = function (item, event) {
                        const date = event.originalEvent.detail
                        item.boardDate = date
                        var actionDate = {
                            taskId: item.id,
                            actionDate: item.boardDate
                        };
                        if (item.boardId) {
                            boardTaskService().updateDate(item.id, item.boardDate).then(function (taskMoved) {
                                //Subtask will not be updated if any...
                                $translate('DEVIATION_ACTION_ITEM_SAVED').then(function (msg) {
                                    toaster.success(msg);
                                });
                                // publish event on change
                                scope.publish("SendTaskDateUpdated", actionDate);
                            });
                        } else {

                            actionlistService().updateItemActionDate(actionDate).then(function () {
                                $translate('DEVIATION_ACTION_ITEM_SAVED').then(function (msg) {
                                    toaster.success(msg);
                                });
                                // publish event on change
                                scope.publish("SendTaskDateUpdated", actionDate);
                            });
                        }
                    }

                    scope.userChangeHandler = function (userinfo) {
                        if (userinfo) {
                            var user = userinfo.user;
                            var item = userinfo.model;
                            item.user = user;
                            // 
                            actionlistService().assignActionListItemUser(item.id, user).then(function () {
                            });
                        }
                    }


                    scope.changeStatus = function (item) {
                        if (!scope.canEdit) return;
                        item.status = taskDrawingService().getNextStatus(item.status).status;
                        var status = {
                            taskId: item.id,
                            status: item.status
                        };
                        actionlistService().updateStatus(status).then(function () {

                        });
                    }

                    scope.clearSelectedUser = function (task) {
                        var emptyUser = {
                            userId: null,
                            fullName: "",
                            displayName: ""
                        }
                        scope.selectUser(task, emptyUser);
                    };
                    scope.selectUser = function (task, user) {
                        if (!scope.canEdit) return;
                        task.user = user;
                        task.ResponsibleUserId
                        var userDetails = {
                            userId: user.userId,
                            userName: user.fullName,
                            userDisplayName: user.displayName,
                            previousUserId: task.responsibleUserId,
                            previousUserName: task.responsibleUser
                        };
                        // save
                        var options = {
                            taskId: task.id,
                            user: userDetails
                        }
                        boardTaskService().assignUser(options);
                        task.responsibleUserId = user.userId;
                        task.responsibleDisplayName = user.displayName;
                        task.responsibleUser = user.fullName;
                    };

                    scope.autoGrow = function (element) {
                        element.style.height = "5px";
                        element.style.height = (element.scrollHeight) + "px";
                    }

                    setTimeout(function () {
                        // Applied globally on all textareas with the "autoExpand" class
                        $(document)
                            .one('focus.autoExpand', 'textarea.autoExpand', function () {
                                var savedValue = this.value;
                                this.value = '';
                                this.baseScrollHeight = this.scrollHeight;
                                this.value = savedValue;
                            })
                            .on('input.autoExpand', 'textarea.autoExpand', function () {
                                var minRows = this.getAttribute('data-min-rows') | 0, rows;
                                this.rows = minRows;
                                rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / 17);
                                this.rows = minRows + rows;
                            });
                    }, 1500);

                    scope.subscribe('TaskDeleted', function (id) {
                        const task = scope.items.find(i => i.id == id)
                        if (task) {
                            var index = scope.items.indexOf(task);
                            if (index > -1) {
                                item.cssState = "animated slideOutLeft"; // Add animation
                                setTimeout(function () {
                                    scope.items.splice(index, 1);
                                }, 300);
                            }
                        }
                    });
                    scope.subscribe('TaskStatusUpdated', function (status) {
                        const task = scope.items.find(i => i.id == status.taskId)
                        if (task) {
                            task.status = status.status;
                        }
                    });
                    scope.subscribe('TaskCommentAdded', function (comment) {
                        const task = scope.items.find(i => i.id == comment.boardTaskId)
                        if (task) {
                            task.commentCount = comment.taskCommentCount;
                        }
                    });
                    scope.subscribe('TaskUpdated', function (updatedTask) {
                        const task = scope.items.find(i => i.id == updatedTask.id)
                        if (task) {
                            task.status = updatedTask.status;
                            task.title = updatedTask.title;
                            task.text = updatedTask.text;
                            task.description = updatedTask.description;
                            task.attachmentCount = updatedTask.attachmentCount;
                            task.commentCount = updatedTask.commentCount;
                            setBoardDate(task, updatedTask.boardDate);
                            task.responsibleUserId = updatedTask.responsibleUserId;
                            setResponsible();
                        }
                    });
                }
            }
        }]);
