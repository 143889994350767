import angular from "angular"
import * as connectorAdminService from "@common/services/admin/connectorAdminService"
import toaster from "@common/components/toast"
import type { Connector } from "@api"
import { NgComponent } from "@common/model/angularModel"

const DigiLean = angular.module('DigiLean')
DigiLean.component('integrationsAdmin', {
    templateUrl: "integrationsAdmin.html",
    controller: ['$scope', '$uibModal', '$translate', 'modalService',
        function(this: NgComponent, $scope, $uibModal, $translate, modalService) {
            let $ctrl = this
            
            $ctrl.connectors = []

            $ctrl.isKaizenAdmin = false
            
            $ctrl.$onInit = function() {
                loaddata()
            }

            //$ctrl.$onDestroy = function() {
                //uaSub.unsubscribe()
            //}
            async function loaddata() {
                const list = await connectorAdminService.getList()
                $ctrl.connectors = list
            }

            $ctrl.addConnector = async function () {
                const modalInstance = $uibModal.open({ backdrop: 'static',
                    animation: true,
                    templateUrl: 'newConnector.html',
                    controller: 'newConnectorController',
                    resolve: {}
                })
                
                await modalInstance.result.catch(err => {
                    if (err !== "cancel")
                        console.error(err)
                })
            }
            $ctrl.delete = async function (connector: Connector) {
                const translations = await $translate(['COMMON_CANCEL', 'COMMON_DELETE', 'ADMIN_MESSAGE_DELETE_CONNECTOR_CONFIRMATION'])
                const modalOptions = {
                    closeButtonText: translations.COMMON_CANCEL,
                    actionButtonText: translations.COMMON_DELETE,
                    headerText: translations.COMMON_DELETE + ' ' + connector.name + '?',
                    bodyText: translations.ADMIN_MESSAGE_DELETE_CONNECTOR_CONFIRMATION.replace('?', ' ') + connector.name + '?'
                }

                await modalService.showModal({}, modalOptions).catch(err => {
                    if (err !== "cancel")
                        console.error(err)
                })
                const deletedConnector = await connectorAdminService.deleteConnector(connector.id)
                $scope.publish("SendConnectorDeleted", deletedConnector)

                const msg = await $translate('ADMIN_MESSAGE_CONNECTOR_DELETED')
                toaster.info(msg)
            }

            $ctrl.startConnector = function (connector: Connector) {
                $scope.publish("SendStartConnector", {id: connector.id, customerId: connector.customerId})
            }

            $ctrl.stopConnector = function (connector: Connector) {
                $scope.publish("SendStopConnector", {id: connector.id, customerId: connector.customerId})
            }


            // events //
            $scope.subscribe("ConnectorCreated", async function (connector: Connector) {
                loaddata()
                const msg = await $translate('ADMIN_DATA_CONNECTOR_CREATED')
                toaster.info(msg + ": " + connector.name)
            })
            $scope.subscribe("ConnectorUpdated", async function (connector: Connector) {
                loaddata()
                const msg = await $translate('ADMIN_DATA_CONNECTOR_UPDATED')
                toaster.info(msg + ": " + connector.name)
            })
            $scope.subscribe("ConnectorDeleted", async function (connector: Connector) {
                loaddata()
                const msg = await $translate('ADMIN_DATA_CONNECTOR_DELETED')
                toaster.info(msg + ": " + connector.name)
            })
        }
    ]
})
