import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .directive("smarttableCustomerCell", ['$filter', 'debounce','projectService','modalService','authService',
        function ($filter, debounce, projectService,modalService, authService) {
            return {
                templateUrl: 'smarttableCustomerCell.html',
                restrict: 'E',
                scope: {
                    'model': '=',
                    'editable': '=',
                    'cellChangeHandler': '&',
                },
                link: function (scope, elem, attrs) {
                    scope.customers = null;
                    scope.customer = null;scope.q = null;
                    
                    scope.isAdmin = authService.hasRole("Admin") || authService.hasRole("ProjectAdmin");
                
                    scope.$watch('model', function (model) {
                        var value = scope.model.cell.value;
                        if (value) {
                            setValue(value);
                        }
                        model.getSortValue = function() {
                            if (scope.customer) {
                                return scope.customer.name;
                            }
                            return "";
                        }
                    });

                    
                    scope.subscribe("ProjectCustomerCreated", function (newCustomer) {
                        projectService().getProjectCustomers().then(function (customers) {
                            scope.customers = customers;    
                        });
                    });

                    scope.subscribe("SmartTableCellUpdated", function (cell) {
                        if (cell.smartTableRowId == scope.model.cell.smartTableRowId && cell.smartTableColumnId == scope.model.cell.smartTableColumnId) {
                            if (cell.value) {
                                setValue(cell.value);
                            }
                        }
                    });

                    var setValue = function (value) {
                        scope.customer = value.customer;
                    } 


                    projectService().getProjectCustomers().then(function (customers) {
                        scope.customers = customers;   
                    });

                    scope.newCustomer = function (newname) {
                        $translate(['COMMON_CANCEL', 'COMMON_SUBMIT', 'ADMIN_MESSAGE_NEW_CUSTOMER']).then(function (translations) {
                            var modalOptions = {
                                closeButtonText: translations.COMMON_CANCEL,
                                actionButtonText: translations.COMMON_SUBMIT,
                                headerText: translations.COMMON_SUBMIT + ' ' + newname + '?',
                                bodyText: translations.ADMIN_MESSAGE_NEW_CUSTOMER
                            };
                
                            modalService.showModal({}, modalOptions).then(function (result) {
                                var registration = {
                                    id: 0,
                                    name: newname
                                };
                                projectService().createNewCustomer(registration).then(function (customer) {
                                    $translate('ADMIN_MESSAGE_CUSTOMER_CREATED').then(function (msg) {
                                        toaster.success(msg);
                                        projectService().getProjectCustomers().then(function (customers) {
                                            scope.customers = customers;
                                            var matching = $filter('filter')(scope.customers, { id: customer.id }, true);
                                            if (matching.length > 0) {
                                                scope.selectCustomer(matching[0]);
                                                scope.q = null;
                                            }
                                        });
                                    }, function (translationId) {
                                        $scope.headline = translationId;
                                    });
                                });
                            });
                
                        }); 
                    };
                    scope.saveChanges = function() {
                        var value = {
                            customer: scope.customer
                        }
                        scope.model.cell.value = value;
                        scope.cellChangeHandler({cell: scope.model.cell})
                    }

                    // Debounce function so that save is called to often.
                    scope.commitChangesDebounced = debounce(scope.saveChanges, 1000, false);
                    scope.selectCustomer = function (customer) {
                        scope.customer = customer;
                        scope.saveChanges(); 
                    };

                    /*
                    function setProjectCustomer(){
                        if (scope.projectInfo && scope.projectInfo.projectCustomerId && scope.customers) {
                            var matching = $filter('filter')(scope.customers, { id: scope.projectInfo.projectCustomerId }, true);
                            if (matching.length > 0) {
                                scope.customer = matching[0];
                            }    
                        }
                    
                    } */

                }
            }
        }]);
