import * as customerService from "@common/services/customers/customerService"
import { translateLabelInstant } from "@common/services/language/DigiLeanLang"
import * as dashboardService from "@common/services/dashboardService"
import * as layoutService from "./flexiboardLayoutService"
import * as displayService from "@common/services/displayService"
import * as employeeService from "@common/services/employeeService"
import toaster from "@common/components/toast"

angular
    .module('DigiLean')
    .controller("flexiboardController", ['$scope', '$element', '$state', '$stateParams', '$uibModal', 'authService', 'modalService', 'debounce', 'appstoreService', 'isTeamsMode',
        function ($scope, $element, $state, $stateParams, $uibModal, authService, modalService, debounce, appstoreService, isTeamsMode) {
            $scope.id = parseInt($stateParams.id);
            $scope.quickAccess = [];
            $scope.popularApps = appstoreService().getPopularApps();
            $scope.isInPresentationMode = false;
            $scope.isInTeams = false;
            if (isTeamsMode) {
                $scope.isInTeams = true;
            }


            $scope.currentCustomerId = authService.getCurrentUser().customerId; //TOBE REMOVED when feature is ready
            $scope.screenSizesConfiguration = layoutService.getScreenSizesConfiguration()
            $scope.ratioOptions = layoutService.getLayoutOptions("landscape")
            // Zoom
            $scope.zoom = 1;
            $scope.showZoom = true;
            var saveSettingsDebounced = debounce(function () {
                saveSettings();
            }, 2000, false);
            $scope.showZoomOption = function () {
                if ($scope.isAdmin || $scope.isBoardDesigner) {
                    saveSettingsDebounced();
                }
            }
            $scope.zoomChangedHandler = function (zoom) {
                $scope.zoom = zoom;
                if ($scope.isAdminMode) {
                    saveSettingsDebounced();
                }
            }

            // page service - will come via event when loaded (might be handled better)
            let pageService
            $scope.$on("grid-stack-loaded", ($event, ps) => {
                pageService = ps
                pageService.setCurrentPage($scope.currentPage)
                calcLayoutFactor()
            })

            // Teams specific
            $scope.isConfigurableTab = false
            const params = new URLSearchParams(window.location.search)
            if (params.has("context")) {
                const context = params.get("context")
                $scope.isConfigurableTab = context == "configTab"
            }


            // Load board
            async function getDashboard() {
                const dashboard = await dashboardService.get($scope.id)
                $scope.userAccess = dashboard.userAccess
                loadDashboard(dashboard)
                checkFavorite()
            }
            getDashboard();

            const onWindowResize = debounce(() => {
                console.log("window resize debounced")
                calcLayoutFactor()
            }, 500, false)

            window.addEventListener("resize", onWindowResize)
            // Cleanup
            $scope.$on('$destroy', function () {
                removeBackgroundPosition()
                // remove position, will descrease index in stylesheet so we need to subtract one
                if (backgroundImageIndex) {
                    backgroundImageIndex -= 1
                }
                removeBackground()
                window.removeEventListener("resize", onWindowResize)

                document.removeEventListener("fullscreenchange", fullscreenchanged);
            })

            /////////////////////////
            // Theme and Layout
            /////////////////////////

            var backgroundPostitionIndex = null;
            var createBackgroundStyle = function () {
                var styleSheet = document.styleSheets[0];
                backgroundPostitionIndex = styleSheet.insertRule("#page-wrapper:before { position: absolute }", styleSheet.cssRules.length);
            }
            createBackgroundStyle();

            $scope.resetLayout = function () {
                $scope.selectedTheme = $scope.themes[0];
                $scope.opacity = 100;
                setOpacity($scope.opacity);
                $scope.backgroundImageUrl = "";
                removeBackground();
                saveSettings();
            }

            $scope.opacity = 100;
            var setOpacity = function (opacity) {
                var cssOpacity = "0." + opacity;
                if (opacity == 100) {
                    cssOpacity = 1;
                }
                if (opacity < 10) {
                    cssOpacity = "0.0" + opacity;
                }
                var styleSheet = document.styleSheets[0];
                var index = styleSheet.insertRule("#page-wrapper:before { opacity: " + cssOpacity + "}", styleSheet.cssRules.length);
            }
            $scope.setOpacity = function (opacity) {
                $scope.opacity = opacity;
                setOpacity(opacity);
                saveSettings();
            }

            // Theme
            $scope.themes = layoutService.getThemes()

            $scope.setTheme = function (theme) {
                $scope.selectedTheme = theme;
                saveSettings();
            }

            // Background
            var backgroundImageIndex = null;
            $scope.customBackgroundUrl = "https://";
            $scope.customBackgrounds = [];
            $scope.backgrounds = layoutService.getBackgrounds()

            $scope.selectBackground = function (background) {
                $scope.setBackgroundUrl(background.url);
            }
            $scope.addCustomBackgroundUrl = function (url) {
                $scope.customBackgrounds.push(url);
                $scope.backgroundImageUrl = url;
                setBackground(url);
                saveSettings();
                $scope.customBackgroundUrl = "";
            }
            $scope.removeCustomBackgroundUrl = function (url) {
                var index = $scope.customBackgrounds.indexOf(url);
                $scope.customBackgrounds.splice(index, 1);
                saveSettings();
            }
            $scope.setCustomBackgroundUrl = function (url) {
                $scope.backgroundImageUrl = url;
                setBackground(url);
                saveSettings();
            }
            $scope.setBackgroundUrl = function (url) {
                $scope.backgroundImageUrl = url;
                setBackground(url);
                saveSettings();
            }

            var setBackground = function (url) {
                removeBackground();
                var styleSheet = document.styleSheets[0];
                var backgroundStyle = "position: fixed; top: 0, left: 0, width: 100vm; 100vh; image-orientation: from-image; background-repeat: no-repeat; background-position: left; background-size: contain; z-index: 0;";
                backgroundImageIndex = styleSheet.insertRule("#page-wrapper:before { background-image: url(" + url + "); " + backgroundStyle + "}", styleSheet.cssRules.length);
            }
            var removeBackgroundPosition = function () {
                if (backgroundPostitionIndex) {
                    document.styleSheets[0].deleteRule(backgroundPostitionIndex);
                    backgroundPostitionIndex = null;
                }
            }
            var removeBackground = function () {
                if (backgroundImageIndex) {
                    document.styleSheets[0].deleteRule(backgroundImageIndex);
                    backgroundImageIndex = null;
                }
            }
            /////////////////////////////
            // End theme and Layout
            /////////////////////////////

            function saveSettings() {                // settings
                if (!$scope.currentDashboard) return;
                var settings = {
                    theme: $scope.selectedTheme.type,
                    zoom: $scope.zoom,
                    showZoom: $scope.showZoom,
                    background: {
                        url: $scope.backgroundImageUrl,
                        opacity: $scope.opacity
                    },
                    customBackgrounds: $scope.customBackgrounds,
                    layoutMode: $scope.layoutMode,
                }

                $scope.currentDashboard.settings = JSON.stringify(settings);
                dashboardService.updateSettings($scope.currentDashboard.id, $scope.currentDashboard.settings).then(function (saved) {
                });
            }
            $scope.saveSettings = saveSettings

           

            $scope.isAdmin = authService.hasRole("Admin")
            $scope.isBoardDesigner = authService.hasRole("BoardDesigner")
            $scope.subscribe('UserAuthenticatedAndReady', function (profile) {
                $scope.isAdmin = authService.hasRole("Admin")
                $scope.isBoardDesigner = authService.hasRole("BoardDesigner")
            })

            customerService.getCurrent().then(function (customer) {
                $scope.logoUrl = customer.logoUrl;
            });

            $scope.updateTitle = function () {
                dashboardService.updateTitle($scope.currentDashboard.id, $scope.currentDashboard.title)
            };

            $scope.addToFavorites = function (currentboard) {
                currentboard.isStar = true;
                var board = {
                    id: currentboard.id,
                    name: currentboard.title,
                    boardType: "Dashboard"
                };
                $scope.quickAccess.push(board);
                saveQuickAccess();
            }
            $scope.removeFromFavorites = function (currentboard) {
                currentboard.isStar = false
                var isFavorite = $scope.quickAccess.find(q => q.id == currentboard.id)
                if (isFavorite) {
                    const currentIndex = $scope.quickAccess.indexOf(isFavorite)
                    $scope.quickAccess.splice(currentIndex, 1)
                    saveQuickAccess()
                }
            }
            function saveQuickAccess() {
                employeeService.updateUserPreference("FlexiboardFavoriteBoards", $scope.quickAccess).then(function (settings) {
                    $scope.publish("FlexiboardFavoriteBoardsChanged", $scope.quickAccess);
                });
            }

            $scope.hasUpdatedLayout = false;
            $scope.refreshDashboard = function () {
                $scope.hasUpdatedLayout = false;
                getDashboard();
            }

            $scope.subscribe('DashboardUpdated', function (dashboard) {
                if ($scope.currentDashboard.id === dashboard.id) {
                    $scope.hasUpdatedLayout = true;
                }
            });
            $scope.subscribe("DashboardSettingsUpdated", (dashboardSettings) => {
                if ($scope.currentDashboard.id != dashboardSettings.dashboardId)
                    return

                if (dashboardSettings.settings && typeof dashboardSettings.settings == "string") {
                    const updatedSettings = JSON.parse(dashboardSettings.settings)
                    if (updatedSettings.layoutMode.option !== $scope.layoutMode.option)
                        $scope.hasUpdatedLayout = true
                }
            })

            $scope.subscribe('DashboardPageAdded', function (page) {
                if ($scope.currentDashboard.id === page.dashboardId) {
                    $scope.currentDashboard.pages.push(page);
                    toaster.success(translateLabelInstant("ADMIN_LEARNING_ADD_PAGE"));
                    $scope.currentPage = page;
                    loadDashboard($scope.currentDashboard, $scope.currentPage);
                    $scope.$apply();
                }
            });
            $scope.subscribe('DashboardPageDeleted', function (page) {
                if ($scope.currentDashboard.id === page.dashboardId) {
                    const filtered = $scope.currentDashboard.pages.filter(p => p.id !== page.id);
                    $scope.currentDashboard.pages = filtered;
                    toaster.success(translateLabelInstant("COMMON_DELETED"));
                    loadDashboard($scope.currentDashboard, $scope.currentPage);
                    $scope.$apply();
                }
            });
            /* DashboardWidgetAdded should only be sent to others, gridstack adds directly */
            /* We changed the logic to send to self as well, since one user can have two windows
               So we delay the event since the event more often comes before the response from backend
               */
            $scope.subscribe('DashboardWidgetAdded', function (widget) {
                if ($scope.currentPage.id === widget.dashboardPageId) {
                    $scope.hasUpdatedLayout = true;
                }
                else {
                    const page = $scope.currentDashboard.pages.find(p => p.id === widget.dashboardPageId);
                    if (page)
                        page.widgets.push(widget);
                }
            })
            /* TODO: Check if this is relevant in gridstack, who should handle this? */
            $scope.subscribe('DashboardWidgetRemoved', function (widget) {
                if ($scope.currentPage.id === widget.dashboardPageId) {
                    $scope.hasUpdatedLayout = true;
                }
                else {
                    let pageToRemoveFrom = $scope.currentDashboard.pages.find(p => p.id === widget.dashboardPageId);
                    if (pageToRemoveFrom) {
                        const filtered = pageToRemoveFrom.widgets.filter(w => w.id !== widget.id);
                        pageToRemoveFrom.widgets = filtered;
                    }
                }
            })
            // this event fires for all inluding current user
            $scope.subscribe("DashboardWidgetSettingsChanged", (componentSettings) => {
                $scope.currentDashboard.pages.forEach((page) => {
                    if (!page.widgets || !Array.isArray(page.widgets) || page.widgets.length == 0)
                        return

                    const widget = page.widgets.find(w => w.id == componentSettings.id)
                    if (!widget)
                        return

                    if ($scope.currentPage.id === widget.dashboardPageId) {
                        pageService.updateWidgetSettings(componentSettings)
                    }
                    else
                        widget.settings = JSON.parse(componentSettings.settings)
                })
            })

            /* New event for updated widgets (component, location or size) from others */
            $scope.subscribe("DashboardWidgetChanged", (updatedWidget) => {
                if ($scope.currentPage.id === updatedWidget.dashboardPageId) {
                    $scope.hasUpdatedLayout = true;
                    // pageService.updateWidgetFromOtherUser(widget) // risk of being source of ping pong
                } else {
                    // update widgets for other pages
                    const page = $scope.currentDashboard.pages.find(p => p.id === updatedWidget.dashboardPageId)
                    if (page) {
                        const index = page.widgets.findIndex(w => w.id === updatedWidget.id)
                        if (index < 0) return

                        if (updatedWidget.settings && typeof updatedWidget.settings === "string")
                            updatedWidget.settings = JSON.parse(updatedWidget.settings)
                        else
                            updatedWidget.settings = null
                        page.widgets.splice(index, 1, updatedWidget)
                    }
                }
            })

            $scope.subscribe("Midnight", function(date) {
                console.log("Flexiboard:: Midnight::", date)
                if (!$scope.isAdminMode && pageService)
                    pageService.reload()
            })

            $scope.layoutMode = {
                option: "default",
                screenSize: "medium"
            }

            // default value
            function resetLayoutStatus() {
                $scope.layoutStatus = { isPossibleToChange: true }
            }
            resetLayoutStatus()
            $scope.resetLayoutStatus = resetLayoutStatus

            $scope.setLayoutOption = function (layoutOption) {
                resetLayoutStatus()
                if (layoutOption === "landscape") {
                    if (!$scope.layoutMode.maxRows)
                        $scope.layoutMode.maxRows = "28" //default
                    $scope.layoutStatus = layoutService.isPossibleToConvertToLandscape($scope.currentDashboard, $scope.layoutMode.maxRows) // will show errors for pages with too much height
                    if (!$scope.layoutStatus.isPossibleToChange) { // dont switch if not possible
                        return
                    }
                } else {
                    // when setting default mode reset default maxRows. maxRows is only relevant for landscape mode 
                    $scope.layoutMode.maxRows = "28"
                }

                $scope.layoutMode.option = layoutOption
                $scope.layoutMode = structuredClone($scope.layoutMode)
                setLayoutMode()
            }

            function layoutChanged() {
                $scope.layoutMode = structuredClone($scope.layoutMode)
                setLayoutMode()
            }

            $scope.layoutScreenChanged = (screen) => {
                layoutChanged()
            }
            $scope.layoutRatioChanged = (maxRows) => {
                console.log("maxRows", maxRows)
                // need to validate here
                $scope.layoutStatus = layoutService.isPossibleToConvertToLandscape($scope.currentDashboard, $scope.layoutMode.maxRows) // will show errors for pages with too much height
                if (!$scope.layoutStatus.isPossibleToChange) { // reset to the highest possible maxRows from our options
                    $scope.layoutMode.maxRows = "28"
                    return
                }
                console.log("layoutMode.maxRows", $scope.layoutMode.maxRows)
                layoutChanged()
            }
            $scope.layoutFactor = 1

            function calcLayoutFactor() {
                console.log("calcLayoutFactor")
                if ($scope.layoutMode.option == "default") { // no scaling
                    $scope.layoutFactor = 1
                    return
                }

                const currentSize = getGridViewSize()
                $scope.currentSize = currentSize // debug info
                if (!$scope.layoutMode.screenSize)
                    $scope.layoutMode.screenSize = "medium" // default value

                if (!$scope.layoutMode.maxRows) {
                    $scope.layoutMode.maxRows = "28" // default - string will cause the selector to select it as well, as long as we use weak comparison ==
                }

                const previousFactor = $scope.layoutFactor
                const factor = layoutService.calculateLayoutFactor($scope.layoutMode.screenSize, currentSize)
                $scope.layoutFactor = factor

                if (factor !== previousFactor && $scope.currentPage && $scope.currentPage.widgets && $scope.currentPage.widgets.length > 0) {
                    console.log("factor changed, send widget-resized")
                    $scope.$broadcast("widget-resized", { allwidgets: true })
                }
            }

            let gridViewElement
            function getGridViewSize() {
                let gridViewSize = {
                    width: 0,
                    height: 0
                }
                getGridViewElement()
                if (gridViewElement) {
                    gridViewSize.width = gridViewElement.offsetWidth
                    gridViewSize.height = gridViewElement.offsetHeight
                }
                return gridViewSize
            }

            function getGridViewElement() {
                gridViewElement = $element[0].querySelector("#dashboard-page")
            }

            function setLayoutMode() {
                console.log("setLayoutMode", $scope.layoutMode)
                calcLayoutFactor()
                saveSettingsDebounced()
            }

            $scope.isClosed = true;
            $scope.isAdminMode = false;
            $scope.isDebugMode = false;

            $scope.currentIndex = null;

            $scope.adminMode = function () {
                $scope.isAdminMode = true;
                $scope.isClosed = false;
                if ($scope.isInPresentationMode) {
                    $scope.togglePresentationView();
                }

                // Adjust height of widgets
                addSortableOnPageList();
            };

            if ($stateParams.mode) {
                if ($stateParams.mode == "info") {
                    setTimeout(function () {
                        startPresentationView();
                    }, 100);

                } else {
                    stopPresentationView();
                    if ($stateParams.mode == "admin") {
                        $scope.adminMode();
                    } else if ($stateParams.mode == "debug")
                        $scope.isDebugMode = true
                }
            } else {
                stopPresentationView();
            }

            $scope.selectPage = function (page) {
                // Get state for previous curretnPage => from pageservice
                //
                // currentpage =
                page.widgets.forEach(function (widget) {
                    if (widget.settings && typeof widget.settings === "string") {
                        widget.settings = JSON.parse(widget.settings)
                    }
                })
                $scope.currentPage = page
                pageService.setCurrentPage(page)
            };


            $scope.giveWidgetFocus = function (widget) {
                var index = $scope.currentPage.widgets.indexOf(widget);
                $scope.currentPage.widgets.splice(index, 1);
                $scope.currentPage.widgets.push(widget);
            };

            $scope.togglePresentationView = function () {
                $scope.isInPresentationMode = !$scope.isInPresentationMode;
                if ($scope.isInPresentationMode) {
                    startPresentationView();
                } else {
                    stopPresentationView();
                }
            };


            // Rotate page (auto rotate)
            var rotatePageTimer;
            var isRotatingPage = false;
            $scope.rotateIntervals = [
                {
                    title: translateLabelInstant("DASHBOARD_AUTO_ROTATE"),
                    interval: 30000
                }             
            ];

            $scope.startRotationInterval = function (interval) {
                $scope.selectedInterval = interval;
                if (isRotatingPage) {
                    stopRotationOfPage();   
                }
                startPresentationView();
                startRotationOfPage();
            };

            function stopRotationOfPage() {
                if (rotatePageTimer) {
                    clearInterval(rotatePageTimer);
                }
                isRotatingPage = false;
            }
            function startRotationOfPage() {
                setFocus();
                if ($scope.selectedInterval.interval == 0) return;
                rotatePageTimer = setInterval(function () {
                    if (!$scope.presentationPaused) {
                        var pages = $scope.currentDashboard.pages;
                        $scope.currentIndex = pages.indexOf($scope.currentPage);
                        $scope.currentIndex += 1;

                        $scope.selectPage(pages[Math.abs($scope.currentIndex % pages.length)]);
                    } ''
                }, $scope.selectedInterval.interval);
                isRotatingPage = true;
            }

            // Extra event to handle ESC button use of close full screen
            function fullscreenchanged(event) 
            {
                // document.fullscreenElement will be null when leaving fullscreenMode
                // https://developer.mozilla.org/en-US/docs/Web/API/Element/fullscreenchange_event
                if (!document.fullscreenElement) {
                    stopPresentationView();
                }
            }

            document.addEventListener("fullscreenchange", fullscreenchanged, false);

            function startPresentationView() {
                $scope.isInPresentationMode = true;
                $("#page-wrapper").css("margin-left", 0);
                $("#page-navigation-menu").hide();
                $("#page-topnavbar").hide();
                $("#page-footer").hide();
                displayService.showFullScreen();

                $scope.publish("PresentationViewStarted");
                setFocus();
            }

            function stopPresentationView() {
                $scope.isInPresentationMode = false;
                stopRotationOfPage();
                $("#page-wrapper").css("margin-left", '');
                $("#page-navigation-menu").show();
                $("#page-topnavbar").show();
                $("#page-footer").show();
                $scope.publish("PresentationViewStopped");
                displayService.hideFullScreen();
            }

            function setFocus() {
                //Set focus on dashboard
                var dashboard = document.querySelector("#flexiboard");
                dashboard.focus();
            }

            $scope.next = function () {
                var numberOfPages = $scope.currentDashboard.pages.length;
                $scope.currentIndex++;

                $scope.selectPage($scope.currentDashboard.pages[$scope.currentIndex % numberOfPages]);
            };

            $scope.previous = function () {
                var numberOfPages = $scope.currentDashboard.pages.length;
                $scope.currentIndex = $scope.currentIndex > 0 ? $scope.currentIndex - 1 : 0;
                $scope.selectPage($scope.currentDashboard.pages[$scope.currentIndex % numberOfPages]);
            };

            $scope.copyFlexiboard = function () {
                var options = {
                    dashboardAsTemplateId: $scope.id,
                    assetId: null,
                    operation: "copy"
                };
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'newFlexiboardConfiguration.html',
                    controller: 'newFlexiboardConfigurationController',
                    resolve: {
                        options: function () {
                            return options
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    $state.go('flexiboard', { id: result.id }, { reload: false });
                    $scope.publish("SendDashboardCreated", result);
                });
            };

            $scope.saveAsTemplate = function () {
                // Test
                var options = {
                    id: $scope.currentDashboard.id,
                    title: $scope.currentDashboard.title,
                    description: $scope.currentDashboard.description,
                    operation: "template"
                };
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'newFlexiboardTemplate.html',
                    controller: 'newFlexiboardTemplateController',
                    resolve: {
                        options: function () {
                            return options
                        }
                    }
                });
            };

            $scope.delete = function () {

                const modalOptions = {
                    closeButtonText: translateLabelInstant("COMMON_CANCEL"),
                    actionButtonText: translateLabelInstant("ADMIN_DELETED_BOARD"),
                    headerText: translateLabelInstant("COMMON_DELETE") + ' ' + $scope.currentDashboard.title + '?',
                    bodyText: translateLabelInstant("ADMIN_DELETED_BOARD_DESCRIPTION")
                };

                modalService.showModal({}, modalOptions).then(function (result) {
                    dashboardService.deleteDashboard($scope.currentDashboard.id).then(function (data) {
                        toaster.info(translateLabelInstant("ADMIN_MESSAGE_BOARD_DELETED"));
                        $state.go('flexiboards');
                    });
                });
            }

            function loadDashboard(dashboard, preferredPage) {
                //$scope.currentDashboard = null;
                $scope.currentPage = null;
                // Parse settings on dashboard
                if (dashboard.settings && typeof dashboard.settings === "string") {
                    dashboard.settings = JSON.parse(dashboard.settings);

                    if (dashboard.settings.zoom) {
                        $scope.zoom = dashboard.settings.zoom;
                    }
                    if (dashboard.settings.showZoom != undefined) {
                        $scope.showZoom = dashboard.settings.showZoom;
                    }
                    // set theme
                    if (dashboard.settings.theme) {
                        // Select theme
                        var selectedTheme = $scope.themes.find(t => t.type == dashboard.settings.theme)
                        if (selectedTheme)
                            $scope.selectedTheme = selectedTheme

                        if (dashboard.settings.background) {
                            $scope.backgroundImageUrl = dashboard.settings.background.url;
                            setBackground($scope.backgroundImageUrl);
                            $scope.opacity = dashboard.settings.background.opacity;
                            setOpacity($scope.opacity);
                        }
                        if (dashboard.settings.customBackgrounds) {
                            $scope.customBackgrounds = dashboard.settings.customBackgrounds;
                        }
                    }

                    if (dashboard.settings.layoutMode) {
                        $scope.layoutMode = dashboard.settings.layoutMode
                        calcLayoutFactor()
                    }
                }
                if (!$scope.selectedTheme) {
                    $scope.selectedTheme = $scope.themes[0];
                }

                // Parse settings on widget
                for (let i = 0; i < dashboard.pages.length; i++) {
                    let page = dashboard.pages[i];
                    for (let y = 0; y < page.widgets.length; y++) {
                        let widget = page.widgets[y];
                        if (widget.settings && typeof widget.settings === "string") {
                            widget.settings = JSON.parse(widget.settings);
                        } else {
                            // try old settings
                            if ($scope.currentDashboard && $scope.currentDashboard.pages) {
                                const oldPage = $scope.currentDashboard.pages[i];
                                if (oldPage && oldPage.widgets) {
                                    const oldWidget = oldPage.widgets[y];
                                    if (oldWidget) {
                                        widget.settings = oldWidget.settings;
                                        continue;
                                    }
                                }
                            }
                            widget.settings = null;
                        }
                    }
                    if (page.settings && typeof page.settings === "string") {
                        page.settings = JSON.parse(page.settings);
                    }
                }
                $scope.currentDashboard = dashboard
                if (preferredPage) {
                    const currentPage = $scope.currentDashboard.pages.find(p => p.id === preferredPage.id);
                    if (currentPage) {
                        $scope.currentPage = currentPage
                    } else {
                        $scope.currentPage = $scope.currentDashboard.pages[0]
                    }
                } else {
                    $scope.currentPage = $scope.currentDashboard.pages[0]
                }
                if (pageService)
                    pageService.setCurrentPage($scope.currentPage)
            }

            function checkFavorite() {
                employeeService.getUserPreference("FlexiboardFavoriteBoards").then(function (settings) {
                    if (settings && settings.value) {
                        angular.forEach(settings.value, function (board) {
                            if (board) {
                                if (board.id == $scope.id) {
                                    $scope.currentDashboard.isStar = true;
                                }
                                $scope.quickAccess.push(board);
                            }
                        });
                    }
                });
            }

            $scope.save = function () {
                $scope.commitChangesDebounced(); // Make sure we throttle, since so many events can call save.
            };

            var commitChanges = function () {
                if ($scope.currentDashboard.settings && typeof $scope.currentDashboard.settings !== "string") {
                    $scope.currentDashboard.settings = JSON.stringify($scope.currentDashboard.settings);
                }
                $scope.currentDashboard.pages.forEach(function (page) {
                    if (page.settings && typeof page.settings !== "string") {
                        page.settings = JSON.stringify(page.settings);
                    }
                    page.widgets.forEach(function (widget) {
                        if (widget.settings && typeof widget.settings !== "string") {
                            widget.settings = JSON.stringify(widget.settings);
                        }
                    });
                });
                dashboardService.saveDashboard($scope.currentDashboard).then(function (savedDashboard) {
                    if ($scope.currentDashboard.settings && typeof $scope.currentDashboard.settings == "string") {
                        $scope.currentDashboard.settings = JSON.parse($scope.currentDashboard.settings);
                    }
                    $scope.currentDashboard.pages.forEach(function (page) {
                        if (page.settings && typeof page.settings === "string") {
                            page.settings = JSON.parse(page.settings);
                        }
                    });
                });
                return true;
            };
            // Debounce function so that save is not called too often.
            $scope.commitChangesDebounced = debounce(commitChanges, 2000, false);

            $scope.viewMode = function () {
                $scope.isAdminMode = false;
                $scope.isClosed = true;

                $("#pagelist").sortable("disable"); // Turn off sortable on pages

                dashboardService.generatePreview($scope.id)
            }

            $scope.edit = function (dashboard) {
                var options = {
                    targetType: "Dashboard",
                    targetId: $scope.id
                };
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'boardSettings.html',
                    controller: 'boardSettingsController',
                    resolve: {
                        options: function () {
                            return options;
                        }
                    }
                });
                modalInstance.result.then(function (dashboard) {
                    $scope.currentDashboard.title = dashboard.title;
                }, function () {

                });

            };

            function addSortableOnPageList() {
                // add sortable
                setTimeout(function () {
                    //
                    // ^^^ this is required otherwise re-enabling sortable will not work!

                    $("#pagelist").sortable({
                        disabled: false,
                        update: pagesOrderChanged
                    });
                    $("#pagelist").disableSelection();
                }, 500);
            }

            var pagesOrderChanged = function (ui, sender) {
                var pageList = $("#pagelist li")
                angular.forEach(pageList, function (page, key) {
                    var pageId = $(page).data("pageid")
                    if (pageId) {
                        const currentPage = $scope.currentDashboard.pages.find(p => p.id == pageId)
                        currentPage.sortOrder = key
                    }
                })
                $scope.save()
            }

            $scope.addPage = function () {
                var page = {
                    dashboardId: $scope.currentDashboard.id,
                    title: translateLabelInstant("ADMIN_LEARNING_ADD_PAGE"),
                    widgets: []
                };
                dashboardService.addPage(page);
            };

            $scope.removePage = function (page) {

                if ($scope.currentDashboard.pages.length == 1) {
                    swal({
                        title: translateLabelInstant('BOARD_DELETE_PAGE_WARNING'),
                        text: "",
                        icon: "info",
                        buttons: { showuserinfo: { text: translateLabelInstant('COMMON_CLOSE'), className: "sa-digilean-button" } },
                        dangerMode: false,
                        closeOnClickOutside: true,
                        closeOnEsc: true
                    })
                } else {
                    const modalOptions = {
                        closeButtonText: translateLabelInstant("COMMON_CANCEL"),
                        actionButtonText: translateLabelInstant("BOARD_DELETE_PAGE"),
                        headerText: translateLabelInstant("COMMON_DELETE") + ' ' + page.title + '?',
                        bodyText: translateLabelInstant("BOARD_DELETE_PAGE_DESC")
                    };

                    modalService.showModal({}, modalOptions).then(function () {
                        dashboardService.deletePage(page.id);
                    })
                }
            }
            $scope.copyPage = function (page) {
                var options = {
                    sourceId: $scope.currentDashboard.id,
                    assetId: $scope.currentDashboard.assetId,
                };
                dashboardService.copyPage(page.id, options).then(function (newPage) {
                    $scope.currentDashboard.pages.push(newPage);
                    toaster.success("FLEXIBOARD_PAGE_COPIED");
                    $scope.selectPage(newPage);
                });
            }

            $scope.addAppToDashboard = function (app, appSize) {
                try {
                    // Adjust size with layoutfactor
                    const appCopy = structuredClone(app)
                    appCopy.defaultXSize = layoutService.adjustAppSize($scope.layoutMode.screenSize, app.defaultXSize);
                    appCopy.defaultYSize = layoutService.adjustAppSize($scope.layoutMode.screenSize, app.defaultYSize);
                    if(appCopy.defaultYSize > $scope.layoutMode.maxRows) {
                        appCopy.defaultYSize = parseInt($scope.layoutMode.maxRows)
                    }
                    pageService.addApp(appCopy)
                }
                catch (err) {
                    const errTranslated = translateLabelInstant(err.message)
                    toaster.error(errTranslated)
                }
            }

            $scope.tryFixLayout = async function () {
                if (!$scope.currentPage || !$scope.currentPage.widgets || $scope.currentPage.widgets.length === 0)
                    return

                const fixedWidgets = await dashboardService.tryFixLayout($scope.id, $scope.currentPage.id)
                if (fixedWidgets && fixedWidgets.length > 0) {
                    fixedWidgets.forEach((widget) => {
                        const existing = $scope.currentPage.widgets.find(w => w.uId == widget.uId)
                        if (existing) {
                            existing.row = widget.row
                            existing.col = widget.col
                        }
                    })
                    if (pageService)
                        pageService.refreshGridFromPageState()
                }

            }
            $scope.addApp = function () {
                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    templateUrl: 'appstore.html',
                    controller: 'appstoreController',
                });

                modalInstance.result.then(function (app) {
                    $scope.addAppToDashboard(app);
                });
            };

        }]);
