import * as customerService from "@common/services/customers/customerService"
import angular from "angular"
import { DigiLeanNgScope, IHasModules } from "@common/model/angularModel"
import toaster from "@common/components/toast"
import { UserInfoModel } from "@api"
import { getUserProfile } from "@common/stores/userStore"

interface IModule {
    roleDB: string
    title: string
    cssClass: string
    filteredAndInResultList: boolean
}
interface ILicenseData {
    noOfUsers: number
    userLicenses: number
}

interface IUsersControllerScope extends DigiLeanNgScope, IHasModules {
    showEditUser: boolean
    userLicenses: number
    numberOfUsers: number
    mobileLicenses: number
    numberOfMobileUsers: number
    totalValues: number
    admincount: number
    businessunitadmincount: number
    boarddesignercount: number
    dataadmincount: number
    messagewritercount: number
    improvementadmincount: number
    deviationadmincount: number
    athreeadmincount: number
    projectadmincount: number
    strategyadmincount: number
    learningadmincount: number
    isSearchWordActive: boolean

    editUser: (user: UserInfoModel) => void
    registration: UserInfoModel

    message: string
    actionTitle: string | null
    warning: string
    headline: any
    modules: IModule[]

    translationLicensesData: ILicenseData

    grantAllPermissions: (role: string) => void
    removeAllPermissions: (role: string) => void
    filterModule: (module: IModule) => void
    search: () => void
    q: any
    filter: any[]

    back: () => void
    resetFilter: () => void
    newUser: () => void

}
var DigiLean =  angular.module('DigiLean');  
DigiLean.controller('usersController', ['$scope', '$translate', 'authService', 'navigationService', 'debounce',
    function ($scope: IUsersControllerScope, $translate,authService, navigationService, debounce) {
        $scope.showEditUser = false
        $scope.filter = []
        $scope.userLicenses = 0
        $scope.numberOfUsers = 0
        $scope.mobileLicenses = 0
        $scope.numberOfMobileUsers = 0
        
        $scope.q = null
        $scope.isSearchWordActive = false

        $scope.isBusinessunitAdmin = authService.hasRole("BusinessunitAdmin");
        $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT")
        $scope.hasDeviationModule = navigationService().hasModule("DEVIATION")
        $scope.hasA3Module = navigationService().hasModule("A3")
        $scope.hasStrategy = navigationService().hasModule("STRATEGY")
        $scope.hasProject = navigationService().hasModule("PROJECT")
        $scope.hasLearningModule = navigationService().hasModule("LEARNING")
        $scope.isEnterpriseAccount = false;
        var businessUnitId = 0;
        var businessUnitFilter = null;
        $scope.isFilterBusinessUnitActive = false

            
        // if page is refreshed we need to refresh the access to modules
        $scope.subscribe("UserAuthenticatedAndReady", function (profile) {
            $scope.hasImprovementModule = navigationService().hasModule("IMPROVEMENT")
            $scope.hasDeviationModule = navigationService().hasModule("DEVIATION")
            $scope.hasA3Module = navigationService().hasModule("A3")
            $scope.hasProject = navigationService().hasModule("PROJECT")
            $scope.hasStrategy = navigationService().hasModule("STRATEGY")
            $scope.hasLearningModule = navigationService().hasModule("LEARNING")
            getAvailableModules()
        })
        
        function getAvailableModules(){
            $scope.modules = [
                {
                    roleDB: "Admin",
                    title: "ADMIN_IS_ADMIN",
                    cssClass: "fas fa-key",
                    filteredAndInResultList: false
                },{
                    roleDB: "DataAdmin",
                    title: "ADMIN_IS_DATAADMIN",
                    cssClass: "fas fa-database",
                    filteredAndInResultList: false
                }, {
                    roleDB: "BoardDesigner",
                    title: "ADMIN_IS_BOARDDESIGNER",
                    cssClass: "fas fa-desktop",
                    filteredAndInResultList: false
                }, {
                    roleDB: "MessageWriter",
                    title: "ADMIN_IS_MESSAGEWRITER",
                    cssClass: "fas fa-envelope",
                    filteredAndInResultList: false
                }
            ]
            if($scope.isEnterpriseAccount){
                var module = {
                    roleDB: "BusinessunitAdmin",
                    title: "ADMIN_IS_BUSINESSUNITADMIN",
                    cssClass: "far fa-building",
                    filteredAndInResultList: false
                }
                $scope.modules.splice(1, 0, module);
            }
            if($scope.hasImprovementModule){
                var module = {
                    roleDB: "ImprovementAdmin",
                    title: "ADMIN_IS_IMPROVEMENTADMIN",
                    cssClass: "fas fa-lightbulb",
                    filteredAndInResultList: false
                }
                $scope.modules.push(module)
            }
            if($scope.hasImprovementModule){
                var module = {
                    roleDB: "ImprovementAdmin",
                    title: "ADMIN_IS_IMPROVEMENTADMIN",
                    cssClass: "fas fa-lightbulb",
                    filteredAndInResultList: false
                }
                $scope.modules.push(module)
            }
            if($scope.hasDeviationModule){
                var module = {
                    roleDB: "DeviationAdmin",
                    title: "ADMIN_IS_DEVIATIONADMIN",
                    cssClass: "fas fa-exclamation-triangle",
                    filteredAndInResultList: false
                }
                $scope.modules.push(module)
            }
            if($scope.hasProject){
                var module = {
                    roleDB: "ProjectAdmin",
                    title: "ADMIN_IS_PROJECTADMIN",
                    cssClass: "fas fa-calendar-alt",
                    filteredAndInResultList: false
                }
                $scope.modules.push(module)
            }
            if($scope.hasA3Module){
                var module = {
                    roleDB: "A3Admin",
                    title: "ADMIN_IS_A3ADMIN",
                    cssClass: "far fa-file",
                    filteredAndInResultList: false
                }
                $scope.modules.push(module)
            }
            if($scope.hasStrategy){
                var module = {
                    roleDB: "StrategyAdmin",
                    title: "ADMIN_IS_STRATEGYADMIN",
                    cssClass: "fas fa-rocket",
                    filteredAndInResultList: false
                }
                $scope.modules.push(module)
            }
            if($scope.hasLearningModule){
                var module = {
                    roleDB: "LearningAdmin",
                    title: "ADMIN_IS_LEARNINGMATERIALEDITOR",
                    cssClass: "fas fa-graduation-cap",
                    filteredAndInResultList: false
                }
                $scope.modules.push(module)
            }
        }

        async function updateBusinessunitUserCounters(){
            if(businessUnitId > 0) {
                $scope.availableBusinessUnitLicenses = await customerService.getAvailableUserLicensesForBusinessUnit(businessUnitId)
                const active = await customerService.getActiveUserLicenses(businessUnitId)
                $scope.numberOfUsers = active
            }
        }

        function refresh(){
            getRoleStats()
            loadAccountInfo()
            updateBusinessunitUserCounters()
            filterDebounce()
        }

        $scope.canAddNewUser = function(){
            if(!$scope.isEnterpriseAccount) return true   
            if($scope.isBusinessunitAdmin)  return $scope.availableBusinessUnitLicenses > 0
            return !($scope.isEnterpriseAccount && $scope.numberOfUsers == $scope.userLicenses)
        }

        $scope.filterModule = function(module) {
            module.filteredAndInResultList = !module.filteredAndInResultList
            filterDebounce()
        }

        $scope.search = function () {
            if ($scope.q) {
                $scope.isSearchWordActive = true

            } else {
                $scope.isSearchWordActive = false
            }
            filterDebounce()
        }

        const filterDebounce = debounce(setFilter, 500, false)
        function setFilter() {

            let filter: any[] = []
            addToFilterIfActive(filter, $scope.modules, "UserRoles.RoleId", "roleDB")

            if ($scope.isSearchWordActive && $scope.q) {
                
                let itemFilter = {
                    sourceColumn: "FullName",
                    operator: "MultiContains",
                    items: [$scope.q]
                }
                filter.push(itemFilter)
            }

            if($scope.isFilterBusinessUnitActive){
                filter.push(businessUnitFilter)
            }
            $scope.filter = filter
        }

        function addToFilterIfActive(filter, list, sourceColumn, property) {
            var itemToShow = list.filter(l => l.filteredAndInResultList)
            if (itemToShow.length > 0) {

                let itemFilter = {
                    sourceColumn: sourceColumn,
                    operator: "InList",
                    items: [] as any[]
                }
                itemToShow.forEach(item => {
                    itemFilter.items.push(item[property])
                })
                filter.push(itemFilter)
            }
        }

        $scope.updateBusinessUnitFilter = function (updatedSourceColumn, filters) {
            businessUnitFilter = null;
            $scope.isFilterBusinessUnitActive = false;
            angular.forEach(filters, function (filter) {
                if (filter) {
                    $scope.isFilterBusinessUnitActive = true;
                    businessUnitFilter = filter;
                }
            });
            filterDebounce();
        };

        $scope.grantAllPermissions = function(role) {        
            customerService.grantRolePermission(role).then(function (result) {
                refresh()
                $translate('ADMIN_GRANT_PERMISSIONS_ALL').then(function (msg) {
                    toaster.success(msg)
                }, function (translationId) {
                    $scope.headline = translationId
                })
            })
        }

        $scope.removeAllPermissions = function(role) {        
            customerService.removeRolePermission(role).then(function (result) {
                refresh()
                $translate('ADMIN_REMOVE_PERMISSION_ALL').then(function (msg) {
                    toaster.success(msg)

                }, function (translationId) {
                    $scope.headline = translationId
                })
            })
        }
        
        $scope.back = function () {
            $scope.showEditUser = false
            refresh()
        }

        $scope.resetFilter = function () {
            $scope.q = null
            getAvailableModules()
            $scope.isFilterBusinessUnitActive = false
            businessUnitFilter = null
            $scope.filter = []
        }

        $scope.newUser = function () {
            $scope.warning = ""
            ///@ts-ignore
            $scope.registration = { id: null}
            $scope.showEditUser = true
        }

        $scope.translationLicensesData = {
            noOfUsers: $scope.totalValues,
            userLicenses: $scope.userLicenses
        }



        var getRoleStats = async function () {
            if($scope.isBusinessunitAdmin) return;

            const stats = await customerService.getRoleStats()
            $scope.admincount = 0
            $scope.businessunitadmincount = 0
            $scope.boarddesignercount = 0
            $scope.dataadmincount = 0
            $scope.improvementadmincount = 0
            $scope.deviationadmincount = 0
            $scope.athreeadmincount = 0
            $scope.projectadmincount = 0
            $scope.strategyadmincount = 0
            $scope.learningadmincount = 0
            $scope.messagewritercount = 0;
            if(stats) {

                for(var i=0; i<stats.length; i++){
                    if(stats[i].role === 'Admin'){
                        $scope.admincount = stats[i].roleCount!
                    } else if(stats[i].role === 'BusinessunitAdmin'){
                        $scope.businessunitadmincount = stats[i].roleCount!
                    } else if(stats[i].role === 'BoardDesigner'){
                        $scope.boarddesignercount = stats[i].roleCount!
                    } else if(stats[i].role === 'DataAdmin'){
                        $scope.dataadmincount = stats[i].roleCount!
                    } else if(stats[i].role === 'ImprovementAdmin'){
                        $scope.improvementadmincount = stats[i].roleCount!
                    } else if(stats[i].role === 'DeviationAdmin'){
                        $scope.deviationadmincount = stats[i].roleCount!
                    } else if(stats[i].role === 'A3Admin'){
                        $scope.athreeadmincount = stats[i].roleCount!
                    } else if(stats[i].role === 'ProjectAdmin'){
                        $scope.projectadmincount = stats[i].roleCount!
                    } else if(stats[i].role === 'StrategyAdmin'){
                        $scope.strategyadmincount = stats[i].roleCount!
                    } else if(stats[i].role === 'LearningAdmin'){
                        $scope.learningadmincount = stats[i].roleCount!
                    } else if(stats[i].role === 'MessageWriter'){
                        $scope.messagewritercount = stats[i].roleCount!
                    }
                }
            }
        }

        var loadAccountInfo = async function () {
            if($scope.isBusinessunitAdmin) return;
            $scope.account = await customerService.getAccount()
            $scope.userLicenses = $scope.account.userLicenses!
            $scope.mobileLicenses = $scope.account.mobileLicenses!
            $scope.isEnterpriseAccount = $scope.account.subscription == 'ENTERPRISE';
        }


        var init = async function () {
            if($scope.isBusinessunitAdmin){
                $scope.isEnterpriseAccount = true; // BusinessunitAdmin is always enterprise
                const currentUser = getUserProfile()
                businessUnitId = currentUser.businessUnitId || 0; // Set a default value of 0 if businessUnitId is undefined
                updateBusinessunitUserCounters();       
            } else { // Only admin have access to this information
                loadAccountInfo()
                const active = await customerService.getActiveUserLicenses()
                $scope.numberOfUsers = active
                
                const activemobile = await customerService.getActiveMobileLicenses()
                $scope.numberOfMobileUsers = activemobile
                $scope.totalValues = $scope.numberOfUsers + $scope.numberOfMobileUsers
                getRoleStats()
            }
            getAvailableModules()
        }
        init()

        $scope.editUser = function (user) {
            $scope.message = ""
            $translate('ADMIN_EDIT_USER').then(function (title) {
                $scope.actionTitle = title
            }, function (translationId) {
                $scope.headline = translationId
            })
 
            $scope.registration = user
            $scope.showEditUser = true
        }

    }])